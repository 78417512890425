import { Injectable } from "@angular/core";
import { AppLanguages } from "./AppClasses";
import { AppInternationalizationService } from "./services/app.internationalization.service";
import { Device } from "@capacitor/device";
import { to } from "@flutaro/package/lib/functions/AppJsHelperFunctions";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppDeviceService {
  isNativeApp: boolean = false;
  isWeb: boolean = false;
  isIos: boolean;
  isAndroid: boolean;
  // Device Features
  // Device State
  $isOnline: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // Device Settings
  deviceLanguage: AppLanguages;

  constructor(private translateService: AppInternationalizationService) {
    this.setDeviceAppLanguageCode();
  }

  async activateDeviceProvider() {
    await this.setPlatformAttributes();
    this.handleOnlineOffline();
    return true;
  }

  async setDeviceAppLanguageCode(): Promise<boolean> {
    const [deviceLanguageError, deviceLanguageCode] = await to(Device.getLanguageCode());
    if (deviceLanguageError) {
      console.error(`setDeviceAppLanguageCode, deviceLanguageError: ${deviceLanguageError}`);
      return false;
    }
    const deviceLanguage = deviceLanguageCode.value;
    console.log(`Device Language is: ${deviceLanguage}`);
    let appLanguageCode;
    switch (deviceLanguage) {
      case "de-DE":
        appLanguageCode = AppLanguages.GERMAN;
      case "en-GB":
        appLanguageCode = AppLanguages.ENGLISH;
      case "en-US":
        appLanguageCode = AppLanguages.ENGLISH;
        break;
      case "pl-PL":
        appLanguageCode = AppLanguages.POLISH;
        break;
      case "ru-RU":
        appLanguageCode = AppLanguages.RUSSIAN;
        break;
      case "ro-RO":
        appLanguageCode = AppLanguages.ROMANIAN;
        break;
      default:
        appLanguageCode = AppLanguages.ENGLISH;
        break;
    }
    this.deviceLanguage = appLanguageCode;
    this.translateService.changeAppLanguage(appLanguageCode);
    return true;
  }

  private handleOnlineOffline() {
    const vm = this;
    window.addEventListener("load", () => {
      function updateOnlineStatus(event) {
        vm.$isOnline.next(navigator.onLine);
      }

      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    });
  }

  private async setPlatformAttributes() {
    const device = await Device.getInfo();
    this.isIos = device.platform === "ios";
    this.isAndroid = device.platform === "android";
    this.isWeb = device.platform === "web";
    this.isNativeApp = !this.isWeb;
    console.log(`App Platform is iOS: ${this.isIos}.`);
    console.log(`App Platform is Android: ${this.isAndroid}.`);
    console.log(`App Platform is Web: ${this.isWeb}.`);
    console.log(`App Platform is nativeApp: ${this.isNativeApp}.`);
  }
}
