import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-group',
  templateUrl: './show-group.component.html',
  styleUrls: ['./show-group.component.scss'],
})
export class ShowGroupComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
