"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobAttributes = exports.JobStatuses = exports.SolutionNode = exports.AppStatuses = exports.JobType = exports.ProfileJobWrapper = exports.ProfileJob = exports.JobRepeating = exports.JobDestination = exports.JobDestinationBasis = exports.JobWrapper = exports.JobBasis = exports.Job = exports.CommonJob = exports.DestinationDateType = exports.DestinationType = exports.JobDefaultValues = exports.JobRepeatingMode = void 0;
const Driver_1 = require("./Driver");
const FlutaroClasses_1 = require("./FlutaroClasses");
const IElement_1 = require("./IElement");
const AppClasses_1 = require("./AppClasses");
const Synonym_1 = require("./Synonym");
const Costs_1 = require("./costs/Costs");
const VehicleConstants_1 = require("./VehicleConstants");
exports.JobRepeatingMode = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    INDIVIDUAL: 'individual',
};
exports.JobDefaultValues = {
    VEHICLE: VehicleConstants_1.VEHICLE_TYPE.TRUCK,
};
// TODO: replace me with API values for only one Type
var DestinationType;
(function (DestinationType) {
    DestinationType["PICKUP"] = "PICKUP";
    DestinationType["DELIVERY"] = "DELIVERY";
    DestinationType["SERVICE"] = "SERVICE";
})(DestinationType = exports.DestinationType || (exports.DestinationType = {}));
var DestinationDateType;
(function (DestinationDateType) {
    DestinationDateType["EARLIEST"] = "EARLIEST";
    DestinationDateType["LATEST"] = "LATEST";
    DestinationDateType["PLANNED"] = "PLANNED";
})(DestinationDateType = exports.DestinationDateType || (exports.DestinationDateType = {}));
class CommonJob {
    constructor() {
        // Vehicle and Driver requirements combined
        this.requirements = [];
        // the required equipment a vehicle has to have for this job
        this.properties = [];
        // TODO: User-Settings: let User decide default value for requiredVehicle
        this.requiredVehicle = VehicleConstants_1.VEHICLE_TYPE.TRUCK;
        this.identifier = '';
        this.status = exports.JobStatuses.NEW;
    }
}
exports.CommonJob = CommonJob;
class Job extends CommonJob {
}
exports.Job = Job;
class JobBasis extends IElement_1.IElement {
    constructor() {
        super(...arguments);
        this.driver = Driver_1.NoDriver.backendId;
        this.attributes = new JobAttributes(); // External custom Attributes of customer - addedStop === Synonym from AddedStop-Location
        this.revenue = 0; // Revenue in €
        this.totalTimeInMinutes = 0; // From "First Pickup" to "latest Delivery"
        this.totalDistance = 0; // in Meters. From "First Pickup" to "latest Delivery"
    }
}
exports.JobBasis = JobBasis;
class JobWrapper extends JobBasis {
    constructor() {
        super(...arguments);
        this.job = new Job();
        this.destinations = [
            new JobDestination(undefined, DestinationType.PICKUP),
            new JobDestination(undefined, DestinationType.DELIVERY),
        ];
        this.overlapping = 0;
        this.driverName = Driver_1.NoDriver.name;
        this.costCalculation = new Costs_1.JobCosts();
        this.appSettings = new AppClasses_1.JobAppSettings();
        this.isVehicleSpot = false;
        this.isLocked = false;
        this.toString = () => `${this.job.identifier}`;
    }
}
exports.JobWrapper = JobWrapper;
class JobDestinationBasis {
    constructor() {
        this.earliestDate = new Date();
        this.latestDate = new Date();
        this.locationType = DestinationType.DELIVERY; // = DestinationTypeExternalJob
        this.notes = '';
        this.cargo = [];
        this.name = '';
        this.description = '';
        this.ingoingReferences = [];
        this.outgoingReferences = [];
    }
}
exports.JobDestinationBasis = JobDestinationBasis;
class JobDestination extends JobDestinationBasis {
    constructor(address, action) {
        super();
        this.actionTimeInMinutes = 0;
        this.position = new Synonym_1.Synonym();
        this.plannedDate = new Date();
        this.timeInMinutes = 0;
        this.distance = 0;
        this.tollDistance = 0;
        this.toString = () => this.position.toString();
        if (address)
            this.position = address;
        if (action)
            this.locationType = action;
    }
}
exports.JobDestination = JobDestination;
class JobRepeating {
    constructor() {
        this.repeatingMode = exports.JobRepeatingMode.DAILY;
        // Repeat Daily except on those Days (0 = Monday, 6 = Sunday)
        this.dailyExceptions = [];
        // Repeat weekly except on those week-dates
        this.weeklyExceptions = [];
        // If Individual is picked, the repeating days are stored here
        this.repeatingDates = [];
    }
}
exports.JobRepeating = JobRepeating;
class ProfileJob extends CommonJob {
    constructor() {
        super(...arguments);
        this.earliestStartDate = new FlutaroClasses_1.ProfileDate();
        this.earliestEndDate = new FlutaroClasses_1.ProfileDate();
    }
}
exports.ProfileJob = ProfileJob;
class ProfileJobWrapper {
    constructor() {
        this.job = new ProfileJob();
        this.driver = '-1';
        this.revenue = 0; // Revenue in €
    }
}
exports.ProfileJobWrapper = ProfileJobWrapper;
exports.JobType = 'JobWrapper';
exports.AppStatuses = {
    UPDATED: 'updated',
    DELETED: 'deleted',
};
class SolutionNode {
}
exports.SolutionNode = SolutionNode;
exports.JobStatuses = {
    NEW: 1,
    PLANNED: 2,
    SENT: 3,
    RECEIVED: 4,
    ACCEPTED: 5,
    DECLINED: 6,
    STARTED: 7,
    DONE: 8,
    APP_UPDATED: 9,
    APP_DELETED: 10,
    ABORTED: 16,
    OUTSOURCED: 32,
};
// TODO: delete me and replace
class JobAttributes {
}
exports.JobAttributes = JobAttributes;
