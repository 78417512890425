import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from "@angular/core";
import { IonItemSliding, ModalController, NavController } from "@ionic/angular";
import { JobService } from "../../pages/jobs/job.service";
import { JobStatuses, JobWrapper } from "@flutaro/package/lib/model/Job";
import { ChatService } from "../../services/chat.service";
import { JobPage } from "../../pages/jobs/job.page";
import { JobDeclinePage } from "../../pages/job-decline/job-decline.page";
import { Router } from "@angular/router";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { TranslateService } from "@ngx-translate/core";
import { ChatJobRequestType } from "@flutaro/package/lib/model/ChatClasses";

@Component({
  selector: "jobs-items",
  templateUrl: "./job-item.component.html",
  styleUrls: ["./job-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobItemComponent {
  @Input() jobsList: JobWrapper[];
  @Input() isDashboard: boolean;
  @Input() currentStartedJob: JobWrapper;
  @Input() isOnline: boolean;
  @Input() userProfile: FbStoreUserProfile;
  @Output() acceptedOrderChange: EventEmitter<JobWrapper> =
    new EventEmitter<JobWrapper>();
  jobStatus = JobStatuses;

  constructor(
    public modalCtrl: ModalController,
    public jobProvider: JobService,
    public navCtrl: NavController,
    private chatProvider: ChatService,
    public router: Router,
    public translate: TranslateService
  ) {
  }

  acceptOrder(jobWrapper: JobWrapper, slidingItem: IonItemSliding) {
    slidingItem.close();
    this.acceptedOrderChange.emit(jobWrapper);
  }

  sendMessageRequestToDriverChannel(job, slidingItem: IonItemSliding) {
    const userChannel = this.chatProvider.getUserChannel();
    if (!userChannel) {
      return;
    }

    slidingItem.close();
    this.chatProvider.channel = userChannel;
    this.chatProvider.userProfile = this.userProfile;
    this.chatProvider.type = ChatJobRequestType;
    this.chatProvider.job = job;
    this.router.navigate(["/contact/page-chat"]);
  }

  async openJobModal(jobWrapper: JobWrapper) {
    let modal = await this.modalCtrl.create({
      component: JobPage,
      componentProps: {
        jobWrapper: jobWrapper,
      },
    });
    modal.present();
  }

  declineOrder(jobWrapper: JobWrapper, slidingItem: IonItemSliding) {
    this.showDeclineReasonModal(jobWrapper, slidingItem);
  }

  startJob(jobWrapper: JobWrapper) {
    jobWrapper.job.status = JobStatuses.STARTED;
    this.jobProvider.updateJobStatus(jobWrapper);
    this.jobProvider.calculateJobDivisions();
  }

  endJob(jobWrapper: JobWrapper) {
    jobWrapper.job.status = JobStatuses.DONE;
    this.jobProvider.updateJobStatus(jobWrapper);
  }

  async showDeclineReasonModal(job: JobWrapper, slidingItem: IonItemSliding) {
    let profileModal = await this.modalCtrl.create({
      component: JobDeclinePage,
      componentProps: {
        jobId: job.job.identifier,
      },
    });
    profileModal.onDidDismiss().then((data) => {
      slidingItem.close();
      if (!data.data) {
        return;
      }
      job.appSettings.declineReason = data.data;
      job.job.status = JobStatuses.DECLINED;
      this.jobProvider.updateJobStatus(job);
    });
    profileModal.present();
  }
}
