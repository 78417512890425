"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractorType = exports.ContractorRate = exports.Contractor = void 0;
const IElement_1 = require("./IElement");
const AppRoutingClasses_1 = require("./AppRoutingClasses");
class Contractor extends IElement_1.IdentifiableElement {
    constructor() {
        super(...arguments);
        this.name = '';
        this.description = '';
        this.contractorId = ''; // for external Usage with other Systems
        // List of backendIds of assigned Vehicles
        this.vehicles = [];
        this.rates = [];
        this.filterEmail = ''; // Responsible for Readonly Flutaro-Users Jobs, Driver, Vehicle etc testing
    }
}
exports.Contractor = Contractor;
class ContractorRate {
    constructor() {
        this.id = '';
        this.date = new Date();
        this.routingProfile = AppRoutingClasses_1.APP_ROUTING_PROFILES.FASTEST; // fastest, least-toll-km, ...
        this.dailyRate = 0; // fixed Rate ( '.' as Euro-Cents separator) the Contractor gets per Day (if alwaysApplyDailyRate == true even if not driving any Jobs)
        this.kmRate = 0; // this Contractors costs for each driven Km ( '.' as Euro-Cents separator)
        this.vehicleTypeWithSpecificRateFixedCosts = 0;
        this.vehicleTypeWithSpecificRate = '';
        this.alwaysApplyDailyRate = false;
        this.kmRateIncludesToll = false; // If toll-Costs should be calculated separately
        // If activated, the reduced Rate for Jobs with pickup on Saturday and delivery on Monday will be applied
        this.useReducedDailyRate = false;
        this.reducedDailyRate = 0;
        // Special Rule for calculations based on Contractor
        this.activateCustomerEmptyKmRuleCalculation = false;
        this.customerIDForCustomerEmptyKmRuleCalculation = '';
        this.customerIDsForCustomerEmptyKmRuleCalculation = [];
        this.includedEmptyKmForCustomerEmptyKmRuleCalculation = 0;
        this.costForKmForCustomerEmptyKmRuleCalculation = 0;
    }
}
exports.ContractorRate = ContractorRate;
exports.ContractorType = 'Contractor';
