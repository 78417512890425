"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformChatMessageForFirestore = exports.sortMessagesByTime = exports.transformChannelFromFirestore = exports.transformMessagesFromFirestore = void 0;
const FlutaroDataFunctions_1 = require("./FlutaroDataFunctions");
const AppJsHelperFunctions_1 = require("./AppJsHelperFunctions");
function transformMessagesFromFirestore(messages) {
    return messages.map((message) => {
        return FlutaroDataFunctions_1.transformObjectIntoChatMessageClass(FlutaroDataFunctions_1.parseFirestoreDateAttributes(message));
    });
}
exports.transformMessagesFromFirestore = transformMessagesFromFirestore;
function transformChannelFromFirestore(channel) {
    return FlutaroDataFunctions_1.parseFirestoreDateAttributes(channel);
}
exports.transformChannelFromFirestore = transformChannelFromFirestore;
/**
 * Returns messages ascending to reflect the view showing latest message bottom / as last element of messages
 * @param messages
 */
function sortMessagesByTime(messages) {
    return FlutaroDataFunctions_1.sortObjectsByDateAttribute(messages, 'sendTime', true);
}
exports.sortMessagesByTime = sortMessagesByTime;
function transformChatMessageForFirestore(chatMessage) {
    let messageObj = AppJsHelperFunctions_1.transformClassIntoPlainObject(chatMessage);
    messageObj.sendTime = chatMessage.sendTime.toISOString();
    delete messageObj.isLastUserMessage;
    return messageObj;
}
exports.transformChatMessageForFirestore = transformChatMessageForFirestore;
