import { Injectable } from "@angular/core";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { Device } from "@capacitor/device";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  analyticsEnabled: boolean = false;

  constructor(private router: Router) {
  }

  public async activateAnalyticsForUser(user: FbStoreUserProfile) {
    console.debug(`activateAnalyticsForUser starting`);
    if ((await Device.getInfo()).platform === "web") {
      console.debug(`activateAnalyticsForUser, device is web - calling FirebaseAnalytics.initializeFirebase`);
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
    await FirebaseAnalytics.setCollectionEnabled({ enabled: true });
    await FirebaseAnalytics.setUserId({ userId: user.uid });
    this.analyticsEnabled = true;
    this.logRouterViewChanges();
  }

  logRouterViewChanges() {
    this.router.events
      .pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
      .subscribe((e: RouterEvent) => {
        console.debug("route changed: ", e.url);
        this.setScreenName(e.url);
      });
  }

  logErrorCrashAnalytics(error) {
    const errorMessage =
      typeof error === "object" ? JSON.stringify(error) : error;
    FirebaseAnalytics.logEvent({ name: "error", params: errorMessage });
  }

  logEvent(event: string, paramName?: string, paramValue?: string | number) {
    this.logAnalyticsEvent(event, paramName, paramValue);
  }

  private setScreenName(screenName: string) {
    FirebaseAnalytics.setScreenName({ screenName })
      .then(() => console.debug(`View ${screen} successfully tracked!`))
      .catch((err) => console.log("Error tracking view:", err));
  }

  private async logAnalyticsEvent(
    name: string,
    paramName?: string,
    paramValue?: string | number
  ) {
    const eventParams = { name: name, params: {} };
    if (paramName) eventParams.params[`${paramName}`] = paramValue;
    FirebaseAnalytics.logEvent(eventParams);
    console.debug(
      `logAnalyticsEvent, logged event ${name} with param ${paramName} and paramValue ${paramValue}`
    );
  }
}
