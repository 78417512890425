export const firebaseConfigProduction = {
  apiKey: "AIzaSyAAqHVwl-c4q7OtO2o2nsUcpu8DN-2z0F0",
  authDomain: "fir-app-221116.firebaseapp.com",
  databaseURL: "https://fir-app-221116.firebaseio.com",
  projectId: "firebase-app-221116",
  storageBucket: "firebase-app-221116.appspot.com",
  messagingSenderId: "895878490890",
  appId: "1:895878490890:android:425a1681f79a8451",
  measurementId: "G-V998YL6SCE",
};

export const environment = {
  environment: "production",
  production: true,
  apiUrl: "https://routingappapi-production.appspot.com",
  firebaseConfig: firebaseConfigProduction,
  apiKeyFbBGApp: "r73489&%349mfglwerJwrJ&§bcx834nwetli)!mJöefnvh§?mer",
  websitePreviewURL:
    "https://europe-west3-firebase-app-221116.cloudfunctions.net/getURLPreview",
};
