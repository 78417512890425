import { Injectable } from "@angular/core";
import { locationRatingsDataRef } from "../model/FirestoreModel";
import {
  prepareLocationRatingBeforeFirebaseStorage,
  setDocRefAfterDocAddedInFirestore,
} from "../../services/FirestoreFunctions";
import { FlutaroPost, PostTypes } from "../post/post";
import { map } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { PostAddModal } from "../post/post/add/post.add.modal";
import { cloneFeedObject, flutaroCloneObject } from "../../AppFunctions";
import { ModalController } from "@ionic/angular";
import { setDatesOnPostingCreate } from "../feed/FeedFunctions";
import { basicPostFirestoreHandler } from "../post/PostFunctions";
import { FlutaroLocationRating } from "./Location";
import { UserProfileProvider } from "../../user/user.profile.provider";
import { AnalyticsService } from "../../services/Analytics/analytics.service";
import { TimelineService } from "../timeline/timeline.service";
import firebase from "firebase/compat/app";
import { BehaviorSubject } from "rxjs";
import { SOCIAL_EVENT } from "@flutaro/package/lib/model/AppAnalyticsEvents";
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: "root",
})
export class LocationService {
  locationRatings: BehaviorSubject<FlutaroLocationRating[]> =
    new BehaviorSubject([]);

  constructor(
    private fbStore: AngularFirestore,
    public modalController: ModalController,
    private userProvider: UserProfileProvider,
    private analytics: AnalyticsService,
    private timeline: TimelineService
  ) {
  }

  getLatestLocationRatings() {
    this.fbStore
      .collection(locationRatingsDataRef, (ref) =>
        ref.orderBy("lastModified", "desc").limit(20)
      )
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map(
            (docRef) => <FlutaroLocationRating>basicPostFirestoreHandler(docRef)
          )
        )
      )
      .toPromise()
      .then((feeds) => {
        this.locationRatings.next(feeds);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getLocationRatingById(ratingId: string) {
    return this.fbStore
      .collection(locationRatingsDataRef)
      .doc(ratingId)
      .get()
      .pipe(map((doc) => <FlutaroLocationRating>basicPostFirestoreHandler(doc)))
      .toPromise();
  }

  async openLocationRatingDialog(type?: string, updateData?) {
    const modal = await this.modalController.create({
      component: PostAddModal,
      componentProps: {
        data: updateData ? flutaroCloneObject(updateData) : null,
        user: this.userProvider.$userProfile.getValue(),
        type: PostTypes.RATING,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (!data) return;
      const post: FlutaroLocationRating = data["data"];
      if (!post) return;

      if (!updateData) {
        this.addLocationRating(post);
      } else {
        this.editLocationRating(post);
      }
    });

    modal.present().then((currentModal) => {
    });
  }

  addLocationRating(locationRating: FlutaroLocationRating) {
    this.analytics.logEvent(SOCIAL_EVENT.RATING_CREATED);
    const user = this.userProvider.$userProfile.getValue();
    locationRating.authorId = user.uid;
    locationRating.authorName = user.nickname;
    locationRating.authorPhoto = user.photoURL;
    setDatesOnPostingCreate(locationRating);
    this.fbStore
      .collection(locationRatingsDataRef)
      .add(prepareLocationRatingBeforeFirebaseStorage(locationRating))
      .then((docRef) => {
        setDocRefAfterDocAddedInFirestore(locationRating, docRef);
        console.log("Added Location-Rating!");
        this.addLocationRatingInternally(locationRating);
        this.timeline.addFeedInternally(<FlutaroPost>(<any>locationRating));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  editLocationRating(locationRating: FlutaroLocationRating) {
    this.analytics.logEvent(SOCIAL_EVENT.RATING_UPDATED);
    setDatesOnPostingCreate(locationRating);
    this.updateLocationRating(locationRating);
  }

  deleteLocationRating(locationRating: FlutaroLocationRating) {
    this.analytics.logEvent(SOCIAL_EVENT.RATING_DELETED);
    this.fbStore
      .doc(`${locationRatingsDataRef}/${locationRating.id}`)
      .delete()
      .then((deleted) => {
        console.log("Location-Rating deleted!!");
        this.deleteLocationRatingInternally(locationRating);
        this.timeline.deleteFeedInternally(<FlutaroPost>(<any>locationRating));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private updateLocationRating(locationRating: FlutaroLocationRating) {
    const preparedFeed =
      prepareLocationRatingBeforeFirebaseStorage(locationRating);
    let updateMap = {
      lastModified: preparedFeed.lastModified,
      content: preparedFeed.content,
      photo: preparedFeed.photo ? preparedFeed.photo : FieldValue.delete(),
      rating: preparedFeed.rating,
    };

    this.fbStore
      .doc(`${locationRatingsDataRef}/${locationRating.id}`)
      .update(updateMap)
      .then((added) => {
        this.updateLocationRatingInternally(locationRating);
        this.timeline.updateFeedInternally(<FlutaroPost>(<any>locationRating));
        console.log("Location-Rating updateFeedFromAuthor!");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private addLocationRatingInternally(locationRating: FlutaroLocationRating) {
    const dataBeforePost = this.locationRatings.getValue().slice();
    dataBeforePost.push(locationRating);
    this.locationRatings.next(dataBeforePost);
  }

  private updateLocationRatingInternally(
    locationRating: FlutaroLocationRating
  ) {
    const newRefLocationRating: FlutaroLocationRating = <FlutaroLocationRating>(
      cloneFeedObject(locationRating)
    );
    let oldFeeds = this.locationRatings.getValue().slice();
    oldFeeds = oldFeeds.filter((feed) => feed.id !== newRefLocationRating.id);
    oldFeeds.push(newRefLocationRating);
    this.locationRatings.next(oldFeeds);
  }

  private deleteLocationRatingInternally(
    locationRating: FlutaroLocationRating
  ) {
    let oldFeeds = this.locationRatings.getValue().slice();
    oldFeeds = oldFeeds.filter((feed) => feed.id !== locationRating.id);
    this.locationRatings.next(oldFeeds);
  }
}
