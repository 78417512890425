"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyTenant = exports.Tenant = exports.PlanningEntity = exports.IdentifiableElement = exports.IElement = void 0;
class IElement {
    constructor() {
        this.lastModifiedAt = new Date();
        this.createdAt = new Date(); // TODO: place into backend
        this.version = 0;
    }
}
exports.IElement = IElement;
class IdentifiableElement extends IElement {
    constructor(name) {
        super();
        this.name = ''; // TODO: refactor driver to use .name instead of .lastName and make requires
        this.toString = () => `${this.name}`;
        if (name)
            this.name = name;
    }
}
exports.IdentifiableElement = IdentifiableElement;
class PlanningEntity extends IdentifiableElement {
    constructor() {
        super(...arguments);
        this.notes = '';
    }
}
exports.PlanningEntity = PlanningEntity;
class Tenant {
    constructor(id, name) {
        this.id = '';
        this.name = '';
        Object.assign(this, { id, name });
    }
}
exports.Tenant = Tenant;
class CompanyTenant extends Tenant {
    constructor() {
        super(...arguments);
        this.users = [];
    }
}
exports.CompanyTenant = CompanyTenant;
