import { Pipe, PipeTransform } from '@angular/core';
import { Tenant } from '@flutaro/package/lib/model/IElement';

@Pipe({
  name: 'translateTenant',
})
export class TranslateTenantIdIntoNamePipe implements PipeTransform {
  transform(tenantId: string, tenants: Tenant[]): string {
    const tenantForId = tenants.find((tenant) => tenant.id === tenantId);
    if (!tenantForId) return 'Invalid tenant';
    return tenantForId.name;
  }
}
