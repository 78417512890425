import { Injectable } from "@angular/core";

export declare const google: any;

@Injectable()
export class AutoCompleteComponentService {
	data: google.maps.places.AutocompletePrediction[] = [];
	GoogleAutocomplete: google.maps.places.AutocompleteService;
	GooglePlaces: google.maps.places.PlacesService;

	constructor() {
		this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
	}

	// Invoked by Autocomplete
	getResults(keyword: string): Promise<google.maps.places.AutocompletePrediction[]> {
		if (keyword.length < 5) return new Promise((res, rej) => res([]));

		return new Promise((resolve, reject) => {
			this.GoogleAutocomplete.getPlacePredictions({
				input: keyword,
				types: ['establishment']
				//types: ['parking'] // campground, car_repair, airport, gas_station
			}, (place, status) => {
				if (status != google.maps.places.PlacesServiceStatus.OK) {
					reject(new Error(status));
				} else {
					resolve(place);
				}
			});
		});
	}
}