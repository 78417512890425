"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewMessageChange = exports.NewMessagesForCompanyAttributeChange = exports.BasicChannelChange = exports.ChatMessage = exports.FBChatChannel = exports.ChatMessageType = exports.ChatJobRequestType = void 0;
exports.ChatJobRequestType = 'JOB_REQUEST';
exports.ChatMessageType = 'MESSAGE';
class FBChatChannel {
    constructor(id, name, email, avatar, lastModified, newCompanyMessage) {
        this.id = '';
        this.avatar = '';
        this.newCompanyMessage = false;
        this.newMessageForAppUser = false;
        Object.assign(this, { id, name, email, avatar, lastModified, newCompanyMessage });
    }
}
exports.FBChatChannel = FBChatChannel;
class ChatMessage {
    constructor() {
        this.senderId = '';
        this.senderName = '';
        this.read = false;
        this.message = '';
        this.sendTime = new Date();
        this.messageType = exports.ChatMessageType; // Can be: ChatMessageType, ChatJobRequestType
        // App only - local
        this.isLastUserMessage = false;
    }
}
exports.ChatMessage = ChatMessage;
class BasicChannelChange {
    constructor() {
        this.channelId = '';
    }
}
exports.BasicChannelChange = BasicChannelChange;
class NewMessagesForCompanyAttributeChange extends BasicChannelChange {
    constructor(id, newMessagesForCompanyAttribute) {
        super();
        this.channelId = id;
        this.newMessagesForCompanyAttribute = newMessagesForCompanyAttribute;
    }
}
exports.NewMessagesForCompanyAttributeChange = NewMessagesForCompanyAttributeChange;
class NewMessageChange extends BasicChannelChange {
    constructor(id, message) {
        super();
        this.channelId = id;
        this.message = message;
    }
}
exports.NewMessageChange = NewMessageChange;
