import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { EditProfilePage } from './edit-profile.page';
import { ShareModule } from '../share.module';
import { ComponentsModule } from "../components/components.module";
import { SocialModule } from "../social/social.module";

const routes: Routes = [
	{
		path: '',
		component: EditProfilePage
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RouterModule.forChild(routes),
		ShareModule,
		ComponentsModule,
    SocialModule,
    TranslateModule
	],
	exports: [EditProfilePage],
	declarations: [EditProfilePage],
	providers: []
})
export class ProfilePageModule {
}
