"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cloneClassIntoPlainObject = exports.keys = exports.pick = exports.assign = exports.transformClassIntoPlainObject = exports.splitArrayIntoChunks = exports.generateGenericId = exports.sortBy = exports.groupByArray = exports.removeDuplicatesFromArray = exports.insertAt = exports.getValueFromStringPath = exports.flutaroWait = exports.to = void 0;
const tslib_1 = require("tslib");
/**
 * @param { Promise } promise
 * @return { Promise }
 */
function to(promise) {
    return promise
        .then((data) => [null, data])
        .catch((err) => {
        return [err]; // which is same as [err, undefined];
    });
}
exports.to = to;
async function flutaroWait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
exports.flutaroWait = flutaroWait;
function getValueFromStringPath(obj, path, defaultValue = undefined) {
    const travel = (regexp) => String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
}
exports.getValueFromStringPath = getValueFromStringPath;
function insertAt(array, index, ...elements) {
    array.splice(index, 0, ...elements);
}
exports.insertAt = insertAt;
function removeDuplicatesFromArray(array) {
    const uniqueArr = Array.from(new Set(array)); // TODO: es6 way: uniq = [...new Set(array)];
    return uniqueArr;
}
exports.removeDuplicatesFromArray = removeDuplicatesFromArray;
function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r) => r && r.key === v);
        if (el) {
            el.values.push(x);
        }
        else {
            rv.push({ key: v, values: [x] });
        }
        return rv;
    }, []);
}
exports.groupByArray = groupByArray;
const sortBy = (key, ordering) => {
    // TODO: evaluate usage / existing implementations and replacement with asc/desc implemen tation
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};
exports.sortBy = sortBy;
function generateGenericId(identifier) {
    return `${identifier}_${new Date().getTime()}`;
}
exports.generateGenericId = generateGenericId;
function splitArrayIntoChunks(input, size) {
    return input.reduce((arr, item, idx) => {
        return idx % size === 0
            ? [...arr, [item]]
            : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
    }, []);
}
exports.splitArrayIntoChunks = splitArrayIntoChunks;
function transformClassIntoPlainObject(classObject) {
    const object = tslib_1.__rest(classObject, []);
    return object;
}
exports.transformClassIntoPlainObject = transformClassIntoPlainObject;
function assign(object, otherClass) {
    // TODO: test & debug if working correctly
    const spreadResult = Object.assign({ object }, otherClass);
    return spreadResult;
}
exports.assign = assign;
function pick(object, ...pKeys) {
    // TODO: test & debug if working correctly
    const picked = Object.fromEntries(Object.entries(object)
        .filter(([key]) => pKeys.includes(key)));
    return picked;
}
exports.pick = pick;
function keys(object) {
    // TODO: test & debug if working correctly
    const objectsKeys = Object.keys(object);
    return objectsKeys;
}
exports.keys = keys;
function cloneClassIntoPlainObject(object) {
    // TODO: delete me
    return transformClassIntoPlainObject(object);
}
exports.cloneClassIntoPlainObject = cloneClassIntoPlainObject;
