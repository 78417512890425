import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import BackgroundGeolocation, {
  Config,
  PermissionRationale,
  Notification,
  LocationAuthorizationAlert
} from "@transistorsoft/capacitor-background-geolocation";
import { FLUT_PositionData, JobPairing } from "@flutaro/package/lib/model/Positiondata";

export function createDefaultBackgroundTrackingConfiguration(
  isProduction: boolean,
  backgroundPermissionRationale: PermissionRationale,
  notification: Notification,
  locationAuthorizationAlert: LocationAuthorizationAlert
): Config {
  let config: Config = {
    // Logging / Debug config
    debug: true, //  enable this hear sounds for background-geolocation life-cycle.
    logLevel: BackgroundGeolocation.LOG_LEVEL_DEBUG,
    // Geolocation config - Common (Android & iOS)
    triggerActivities: "in_vehicle",
    desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH, // Desired accuracy in meters.
    geofenceProximityRadius: 500,
    // TODO start: TEST WITH IOS to use disableElasticity
    disableElasticity: true,
    distanceFilter: 100,
    fastestLocationUpdateInterval: 4 * 60 * 1000,
    //TODO: TEST WITH IOS disableElasticity: false,
    //elasticityMultiplier: 20,
    // Both, general
    stopOnTerminate: false,
    startOnBoot: true,
    // Application config
    enableTimestampMeta: true,
    locationAuthorizationRequest: "Always", // 'Always' doesnt apply when requested first time. For iOs and Android optimal behavior, 'Always' is reuested later
    // Android
    notification: notification,
    backgroundPermissionRationale: backgroundPermissionRationale,
    foregroundService: true,
    // iOs
    disableStopDetection: true,
    pausesLocationUpdatesAutomatically: false,
    activityType: BackgroundGeolocation.ACTIVITY_TYPE_AUTOMOTIVE_NAVIGATION,
    locationAuthorizationAlert: locationAuthorizationAlert
  };
  if (isProduction) {
    config.debug = false;
    config.logLevel = BackgroundGeolocation.LOG_LEVEL_OFF;
  } else {
    config.debug = true;
    config.logLevel = BackgroundGeolocation.LOG_LEVEL_INFO;
  }
  return config;
}

export function createBackgroundTrackingConfigurationForJob(
  position: FLUT_PositionData,
  userProfile: FbStoreUserProfile,
  isProduction: boolean,
  apiKey: string,
  backgroundPermissionRationale: PermissionRationale,
  notification: Notification,
  locationAuthorizationAlert: LocationAuthorizationAlert
): Config {
  const jobConfig: Config = {
    params: {
      jobId: position.jobId,
      vehicleId: position.vehicleId,
      licensePlate: position.licensePlate,
      driverUID: userProfile.uid,
      company: userProfile.company,
      sentToCustomerAPI: false,
      jobsDeliveryPlannedDate: position.jobsDeliveryPlannedDate,
    },
    extras: {
      apiKeyFbBGApp: apiKey,
      pairing: JobPairing.ON,
    },
  };
  let defaultConfig: Config = createDefaultBackgroundTrackingConfiguration(isProduction, backgroundPermissionRationale, notification, locationAuthorizationAlert);
  defaultConfig.extras = jobConfig.extras;
  defaultConfig.params = jobConfig.params;
  if (position.customerId) defaultConfig.params["customerId"] = position.customerId;
  if (position.jobCustomerId) defaultConfig.params["jobCustomerId"] = position.jobCustomerId;
  if (position.customerPickupLocationId) defaultConfig.params["customerPickupLocationId"] = position.customerPickupLocationId;
  if (position.customerDeliveryLocationId) defaultConfig.params["customerDeliveryLocationId"] = position.customerDeliveryLocationId;
  console.debug(`createBackgroundTrackingConfigurationForJob, finished with configuration`);
  return defaultConfig;
}
