import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy } from "@angular/core";
import { JobStatuses, JobWrapper } from "@flutaro/package/lib/model/Job";

@Component({
  selector: "job-status",
  templateUrl: "./job-status.component.html",
  styleUrls: ["./job-status.component.scss", "../../jobs/job.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class JobStatusComponent {
  status = JobStatuses;
  @Input() job: JobWrapper;
}
