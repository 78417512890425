import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from "./avatar.component";
import { AvatarService } from "./avatar.service";
import { IonicModule } from "@ionic/angular";


@NgModule({
	declarations: [AvatarComponent],
	imports: [
		CommonModule,
		IonicModule
	],
	exports: [AvatarComponent],
	providers: [AvatarService]
})
export class AvatarModule {
}
