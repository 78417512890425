import { PostBasics, PostTypes } from "../post/post";

export class LocationRating implements LocationRatingInterface {
	ratingComment: string = 'Default';
	// Ratings: from 1-5
	rating: number = 0;
	quality: number = 0;
	qualityComment: string = '';
	safety: number = 0;
	safetyComment: string = '';
	hasBBQ: boolean; // Can Truckers BBQ/grill here? Allowed and possible?
	bbqComment: string;
	hasShowers: boolean;
	showerComment: string;
}

export interface LocationRatingInterface {
	ratingComment: string;
	rating: number;
	quality: number;
	qualityComment: string;
	safety: number;
	safetyComment: string;
	hasBBQ: boolean; // Can Truckers BBQ/grill here? Allowed and possible?
	bbqComment: string;
	hasShowers: boolean;
	showerComment: string;
}

export class FlutaroLocationRating extends PostBasics {
	rating: LocationRating = new LocationRating();

	constructor() {
		super();
		this.type = PostTypes.RATING;
	}
}

