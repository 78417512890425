"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = exports.Client = exports.ClientRequirements = void 0;
const IElement_1 = require("./IElement");
const Synonym_1 = require("./Synonym");
const Job_1 = require("./Job");
class ClientRequirements {
}
exports.ClientRequirements = ClientRequirements;
class Client extends IElement_1.IElement {
    constructor() {
        super(...arguments);
        this.name = '';
        this.officeEmail = '';
        this.dispatchPhone = '';
        this.contactAddress = new Synonym_1.Synonym();
        this.abcClassification = '';
        this.requirements = new ClientRequirements();
        this.profiles = [];
        this.toString = () => `${this.name}`;
    }
}
exports.Client = Client;
class Profile {
    constructor() {
        // simple, FE generated unique ID to correctly identify profiles
        this.feId = '';
        this.profileIdentifier = '';
        // Color in hexa - default is flutaro color
        this.color = '#40c1ac';
        this.jobWrapper = new Job_1.ProfileJobWrapper();
    }
}
exports.Profile = Profile;
