"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinusPositionCorrection = exports.RoutingProfilesArray = exports.RoutingProfiles = exports.JobCosts = exports.CostsBasics = void 0;
const Synonym_1 = require("../Synonym");
class CostsBasics {
    constructor() {
        // Total results
        this.totalCosts = 0;
        this.totalTollCosts = 0;
        this.totalDistance = 0;
        this.totalTime = 0;
        this.totalVehicleDynamicCosts = 0; // vehicleCostsEmptyDrive + vehicleCostsLoadedDrive
        // Distance, Time and Cost Information about Empty and Loaded Drives - used for Statistics and Events Calculations
        this.emptyDriveCosts = 0; // in Euro
        this.emptyDistance = 0; // in Meter
        this.emptyDistanceTravelTime = 0; // in Minutes
        this.emptyDriveTollCosts = 0; // in Euro
        this.loadedDriveCosts = 0; // in Euro
        this.loadedDistance = 0; // in Meter
        this.loadedDistanceTravelTime = 0; // in Minutes
        this.loadedDriveTollCosts = 0; // in Euro
        // KM-Rate specific
        this.vehicleCostsEmptyDrive = 0;
        this.vehicleCostsLoadedDrive = 0;
    }
}
exports.CostsBasics = CostsBasics;
class JobCosts extends CostsBasics {
    constructor(revenue) {
        super();
        // General Cost Information
        this.userOfCalculation = ''; // Automatically or changed by User
        this.revenue = 0; // redundant with job.revenue to store revenue information for time of calculation as history entry
        this.profit = 0;
        // Settings, automatically detected
        this.isReduced = false;
        this.vehicleFixedCosts = 0; // Costs per Job, total fixed costs divided by amount of jobs starting the same day
        this.minusPositionCorrections = [];
        // Vehicle information
        this.vehicleCostsFixedTotal = 0; // The total fixed costs of the vehicle per day
        this.vehicleKmRate = 0;
        this.emptyKmAddress = new Synonym_1.Synonym(); // Home, destination of last order or custom
        // User Settings, manual
        this.isCustomEmptyKmAddress = false; // user choosen emptyKmAddress
        this.addedFixCosts = 0;
        // Special Cases
        this.isCustomContractorEmptyKmCalculation = false;
        this.isManualOverwrite = false;
        if (revenue)
            this.revenue = revenue;
    }
}
exports.JobCosts = JobCosts;
exports.RoutingProfiles = {
    FLUTARO_FASTEST: 'FLUTARO_FASTEST',
    FLUTARO_SHORTEST: 'FLUTARO_SHORTEST',
    BLG: 'BLG',
    DEFAULT: 'BLG',
};
exports.RoutingProfilesArray = [
    exports.RoutingProfiles.FLUTARO_FASTEST,
    exports.RoutingProfiles.BLG,
    exports.RoutingProfiles.FLUTARO_SHORTEST,
];
class MinusPositionCorrection {
    constructor(jobId, amount) {
        this.jobId = '';
        this.amount = 0;
        Object.assign(this, { jobId: jobId, amount: amount });
    }
}
exports.MinusPositionCorrection = MinusPositionCorrection;
