import { NotificationsService } from "../notifications/notifications.service";
import { AppAuthService } from "../../auth/app-auth.service";
import { UserProfileProvider } from "../../user/user.profile.provider";
import { Injectable } from "@angular/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { PushNotificationSchema, Token, } from "@capacitor/push-notifications/dist/esm/definitions";

@Injectable({
  providedIn: "root",
})
export class PushMessagesService {
  constructor(
    private fbAuth: AppAuthService,
    private notifications: NotificationsService,
    private userProfileProvider: UserProfileProvider
  ) {
  }

  private static async requestPushPermission() {
    const hasPermission = await PushNotifications.requestPermissions();
    console.debug(`activatePushOnDevice, hasPermission: ${hasPermission}`);
    // Enable the auto initialization of the library
    await FCM.setAutoInit({ enabled: true });
    return hasPermission;
  }

  async activatePushNative() {
    const pushIsActivated = await PushMessagesService.requestPushPermission();
    if (!pushIsActivated) {
      console.log(`activatePushNative, DEVICE-PUSH-ERROR: Push permissions not granted. Cant activate push. Aborting.`);
      this.notifications.showWarningToast(`Warning: You didnt give the App push notification permissions. 
			This means you won´t receive notifications on order updates. Please activate push notifications for Flutaro in your settings.`);
      return;
    }
    await this.registerPushNotificationsAndAddListeners();
  }

  private async registerPushNotificationsAndAddListeners() {
    const activated = PushNotifications.register();
    if (!activated) {
      console.log(`activateNativePushListener, Push not registered`);
      return false;
    }
    console.log(`activateNativePushListener, Push registered`);

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        this.handlePushNotification(notification);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      this.notifications.showWarningToast(
        `Push could not be registered correctly. Error: ${JSON.stringify(
          error
        )}`
      );
    });

    PushNotifications.addListener("registration", (token: Token) => {
      console.debug(
        `activateNativePushListener, updateFCMTokenInUserProfile (token: ${token.value}`
      );
      this.userProfileProvider.updateFCMTokenInUserProfile(token.value);
    });
    return true;
  }

  private handlePushNotification(message: PushNotificationSchema) {
    this.notifications.showPushNotification(
      `${message.notification.title}: ${message.notification.body}`
    );
  }
}
