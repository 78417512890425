import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { FlutaroLike } from "../../post/post";
import { AvatarService } from "../../avatar/avatar.service";
import { FlutaroUserProfile } from "@flutaro/package/lib/model/AuthClasses";

@Component({
  selector: "app-likes-modal",
  templateUrl: "./likes-modal.component.html",
  styleUrls: ["./likes-modal.component.scss"],
})
export class LikesModalComponent implements OnInit {
  title: string;
  likes: FlutaroLike[];
  userProfile: FlutaroUserProfile;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    private alertController: AlertController,
    private translate: TranslateService,
    private avatarProvider: AvatarService
  ) {
    this.likes = navParams.get("likes");
    this.userProfile = navParams.get("userProfile");
    this.title = navParams.get("title");
  }

  ngOnInit() {}

  showUser(like: FlutaroLike) {
    this.avatarProvider.avatarClicked(this.userProfile, like.uid);
  }

  dismiss() {
    this.modalController.dismiss(null);
  }
}
