import { cloneDeep } from 'lodash-es';

export function flutaroCloneObject(obj): any {
  //return JSON.parse(JSON.stringify(obj));
  return cloneDeep(obj);
}

export function cloneFeedObject(obj) {
  let newRefObj = flutaroCloneObject(obj);
  //transformFeedForWebApp(newRefObj);
  return newRefObj;
}
