import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateFilterPipes, TranslateJobsLengthPipes, } from "./translate.pipe";
import { AppOrderByPipe } from "../pages/chat/chat.pipes";
import { FilterFeedDataPipe, TranslateTimelineTypePipe, } from "../social/feed/feed.pipes";

@NgModule({
  declarations: [
    TranslateFilterPipes,
    TranslateJobsLengthPipes,
    AppOrderByPipe,
    FilterFeedDataPipe,
    TranslateTimelineTypePipe,
  ],
  imports: [CommonModule],
  exports: [
    TranslateFilterPipes,
    TranslateJobsLengthPipes,
    AppOrderByPipe,
    FilterFeedDataPipe,
    TranslateTimelineTypePipe,
  ],
})
export class PipesModule {
}
