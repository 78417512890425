import { DestinationType, JobDestination, JobWrapper, } from "@flutaro/package/lib/model/Job";
import { JobPairing, FLUT_PositionData } from "@flutaro/package/lib/model/Positiondata";
import { getJobsLastDeliveryDate } from "@flutaro/package/lib/functions/DestinationFunctions";

export function setCustomerETAInformationForPosition(
  position: FLUT_PositionData,
  job: JobWrapper
) {
  position.customerPickupLocationId = getCustomerPickupLocationId(job);
  position.customerDeliveryLocationId = getCustomerDeliveryLocationId(job);
  position.jobsDeliveryPlannedDate = getJobsLastDeliveryDate(job).toISOString();
}

export function getCustomerPickupLocationId(job: JobWrapper) {
  if (!job.destinations) {
    return "No_Destinations";
  }
  let descriptionAsCustomerId = job.destinations.find(
    (dest) => dest.locationType === DestinationType.PICKUP
  ).description;
  return descriptionAsCustomerId ? descriptionAsCustomerId : "No customer Id";
}

export function getCustomerDeliveryLocationId(job: JobWrapper) {
  if (!job.destinations) {
    return "No_Destinations";
  }
  let descriptionAsCustomerIdDelivery = findLastMatchingObjectInArray(
    job.destinations,
    DestinationType.DELIVERY
  ).description;
  return descriptionAsCustomerIdDelivery
    ? descriptionAsCustomerIdDelivery
    : "No customer Id";
}

export function findLastMatchingObjectInArray(
  destinations: JobDestination[],
  type: string
) {
  return destinations
    .slice()
    .reverse()
    .find((dest) => dest.locationType === type);
}

export function createPositionDataFromJobAndPosition(
  pairing: JobPairing,
  currentStartedJob: JobWrapper,
  latitude?,
  longitude?,
  timestamp?: Date
) {
  let flutPosition = new FLUT_PositionData();
  flutPosition.extras.pairing = pairing;
  flutPosition.latitude = latitude;
  flutPosition.longitude = longitude;

  flutPosition.vehicleId = currentStartedJob.vehicleId;
  flutPosition.licensePlate = currentStartedJob.vehicleLicensePlate;
  flutPosition.jobId = currentStartedJob.backendId;
  if (currentStartedJob.attributes["shipmentId"]) {
    flutPosition.jobCustomerId = currentStartedJob.attributes["shipmentId"];
  }
  if (currentStartedJob.attributes["customerId"]) {
    flutPosition.customerId = currentStartedJob.attributes["customerId"];
  }
  if (timestamp) {
    flutPosition.date = timestamp.toISOString();
  }
  if (latitude) {
    flutPosition.latitude = latitude;
  }
  if (longitude) {
    flutPosition.longitude = longitude;
  }

  setCustomerETAInformationForPosition(flutPosition, currentStartedJob);
  return flutPosition;
}
