"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateKeywords = exports.createKeywords = exports.generateKeywordsForName = exports.isChangedAndMigratedUserDoc = exports.isChangedAndMigratedAppUserDoc = exports.parseProfileBeforeFirestoreStorage = void 0;
const UserProfile_1 = require("../../model/UserProfile");
const AppJsHelperFunctions_1 = require("../AppJsHelperFunctions");
function parseProfileBeforeFirestoreStorage(profile) {
    let profileForStorage = AppJsHelperFunctions_1.cloneClassIntoPlainObject(profile);
    profileForStorage.vehicleInformation = AppJsHelperFunctions_1.cloneClassIntoPlainObject(profileForStorage.vehicleInformation);
    return profileForStorage;
}
exports.parseProfileBeforeFirestoreStorage = parseProfileBeforeFirestoreStorage;
function isChangedAndMigratedAppUserDoc(userDoc, user) {
    var _a;
    let isMigration = false;
    if (!userDoc.vehicleInformation) {
        userDoc.vehicleInformation = new UserProfile_1.TruckersVehicleInformation();
        isMigration = true;
    }
    if (!((_a = userDoc.chatChannels) === null || _a === void 0 ? void 0 : _a.length)) {
        userDoc.chatChannels = ['#general'];
        isMigration = true;
    }
    if (!userDoc.chatChannels.includes(userDoc.uid)) {
        userDoc.chatChannels.push(userDoc.uid);
        isMigration = true;
    }
    return isMigration || isChangedAndMigratedUserDoc(userDoc, user);
}
exports.isChangedAndMigratedAppUserDoc = isChangedAndMigratedAppUserDoc;
function isChangedAndMigratedUserDoc(userDoc, user) {
    let isMigration = false;
    // This is expected for all new accounts because Firebase doesnt have onUserUpdate implemented yet
    if (!userDoc.displayName) {
        userDoc.displayName = user.displayName;
        isMigration = true;
    }
    if (!userDoc.nickname) {
        userDoc.nickname = userDoc.displayName;
        isMigration = true;
    }
    if (!userDoc.keywords) {
        userDoc.keywords = generateKeywordsForName(userDoc.nickname);
        isMigration = true;
    }
    if (!userDoc.photoURL) {
        userDoc.photoURL = UserProfile_1.NoUserBase64;
        isMigration = true;
    }
    return isMigration;
}
exports.isChangedAndMigratedUserDoc = isChangedAndMigratedUserDoc;
function generateKeywordsForName(displayName) {
    if (!displayName)
        return [];
    let nameSplitted = displayName.match(/[^ ]+/g);
    if (!nameSplitted) {
        return [];
    }
    else if (nameSplitted.length > 2) {
        nameSplitted = [nameSplitted[0].toLowerCase(), nameSplitted[nameSplitted.length - 1].toLowerCase()];
    }
    else if (nameSplitted.length === 1) {
        nameSplitted[0] = nameSplitted[0].toLowerCase();
    }
    return generateKeywords(nameSplitted);
}
exports.generateKeywordsForName = generateKeywordsForName;
function createKeywords(name) {
    const arrName = [];
    let curName = '';
    name.split('').forEach(letter => {
        curName += letter.toLowerCase();
        arrName.push(curName);
    });
    return arrName;
}
exports.createKeywords = createKeywords;
function generateKeywords(names) {
    const [first, last] = names;
    const lastfix = last && last.length > 0 ? `${last}` : '';
    const keywordNameWidthoutMiddleName = createKeywords(`${first} ${lastfix}`);
    const keywordLastNameFirst = createKeywords(`${lastfix}`);
    return [
        ...new Set([
            ...keywordLastNameFirst,
            ...keywordNameWidthoutMiddleName
        ])
    ];
}
exports.generateKeywords = generateKeywords;
