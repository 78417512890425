"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegularWorkweek = exports.AlternatingWorkweek = exports.ProfileDate = void 0;
class ProfileDate {
}
exports.ProfileDate = ProfileDate;
class AlternatingWorkweek {
    constructor() {
        this.evenWorkweekStart = new ProfileDate();
        this.oddWorkweekStart = new ProfileDate();
        this.evenWorkweekEnd = new ProfileDate();
        this.oddWorkweekEnd = new ProfileDate();
        // Even
        this.evenWorkweekStart.minute = 0;
        this.evenWorkweekStart.hour = 0;
        this.evenWorkweekStart.weekDay = 0;
        this.evenWorkweekEnd.minute = 55;
        this.evenWorkweekEnd.hour = 23;
        this.evenWorkweekEnd.weekDay = 6;
        // Odd
        this.oddWorkweekStart.minute = 0;
        this.oddWorkweekStart.hour = 0;
        this.oddWorkweekStart.weekDay = 0;
        this.oddWorkweekEnd.minute = 55;
        this.oddWorkweekEnd.hour = 23;
        this.oddWorkweekEnd.weekDay = 6;
    }
}
exports.AlternatingWorkweek = AlternatingWorkweek;
class RegularWorkweek {
    constructor() {
        this.regularWorkweekStart = new ProfileDate();
        this.regularWorkweekEnd = new ProfileDate();
        this.regularWorkweekStart.minute = 0;
        this.regularWorkweekStart.hour = 0;
        this.regularWorkweekStart.weekDay = 0;
        this.regularWorkweekEnd.minute = 55;
        this.regularWorkweekEnd.hour = 23;
        this.regularWorkweekEnd.weekDay = 6;
    }
}
exports.RegularWorkweek = RegularWorkweek;
