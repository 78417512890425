"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformElementsSynonymsClasses = exports.parseFirestoreDateAttributes = exports.transformElementsDateAttributesToDate = exports.parseDBDateAttributes = exports.parseElementsBasics = exports.transformObjectIntoNoteClass = exports.transformObjectIntoChatMessageClass = exports.transformObjectIntoCargoClass = exports.transformObjectIntoSynonymClass = exports.transformObjectIntoRelationClass = exports.transformObjectIntoClientClass = exports.transformObjectIntoContractorClass = exports.transformObjectIntoDriverClass = exports.transformObjectIntoVehicleClass = exports.transformObjectIntoJobDestinationClass = exports.transformObjectIntoJobClass = exports.sortObjectsByDateAttribute = exports.objToMap = exports.generateContractorRateId = exports.copyExistingValuesIntoTargetClass = void 0;
const Synonym_1 = require("../model/Synonym");
const Contractor_1 = require("../model/Contractor");
const Driver_1 = require("../model/Driver");
const CargoClasses_1 = require("../model/CargoClasses");
const Vehicle_1 = require("../model/Vehicle");
const Client_1 = require("../model/Client");
const Relation_1 = require("../model/Relation");
const Job_1 = require("../model/Job");
const date_fns_1 = require("date-fns");
const ChatClasses_1 = require("../model/ChatClasses");
const TimetableNote_1 = require("../model/TimetableNote");
function copyExistingValuesIntoTargetClass(object, targetClass) {
    const sanitized = {};
    for (const p in targetClass) {
        // @ts-ignore
        sanitized[p] = (p in object ? object : targetClass)[p];
    }
    return sanitized;
}
exports.copyExistingValuesIntoTargetClass = copyExistingValuesIntoTargetClass;
function generateContractorRateId(contractor) {
    return `${contractor.backendId ? contractor.backendId : new Date().getTime()}_${contractor.rates.length} `;
}
exports.generateContractorRateId = generateContractorRateId;
function objToMap(obj) {
    // TODO: Check me
    let map = new Map();
    if (!obj)
        return map;
    Object.keys(obj).forEach((key) => {
        // @ts-ignore
        map.set(key, obj[key]);
    });
    return map;
}
exports.objToMap = objToMap;
function sortObjectsByDateAttribute(objects, dateIdentifier, ascending) {
    if (!objects || objects.length < 2 || !dateIdentifier)
        return objects;
    objects.sort((left, right) => {
        return ascending ? left[dateIdentifier] - right[dateIdentifier] : right[dateIdentifier] - left[dateIdentifier];
    });
    return objects;
}
exports.sortObjectsByDateAttribute = sortObjectsByDateAttribute;
function transformObjectIntoJobClass(object) {
    const job = new Job_1.JobWrapper();
    Object.assign(job, object);
    job.destinations = job.destinations.map(dest => {
        if (!dest.cargo)
            dest.cargo = [];
        dest.cargo.map((cargo) => transformObjectIntoCargoClass(cargo));
        return transformObjectIntoJobDestinationClass(dest);
    });
    return job;
}
exports.transformObjectIntoJobClass = transformObjectIntoJobClass;
function transformObjectIntoJobDestinationClass(object) {
    const jobDestination = new Job_1.JobDestination();
    Object.assign(jobDestination, object);
    return jobDestination;
}
exports.transformObjectIntoJobDestinationClass = transformObjectIntoJobDestinationClass;
function transformObjectIntoVehicleClass(object) {
    const vehicleAsClass = new Vehicle_1.Vehicle();
    Object.assign(vehicleAsClass, object);
    return vehicleAsClass;
}
exports.transformObjectIntoVehicleClass = transformObjectIntoVehicleClass;
function transformObjectIntoDriverClass(object) {
    const driverAsClass = new Driver_1.Driver();
    Object.assign(driverAsClass, object);
    return driverAsClass;
}
exports.transformObjectIntoDriverClass = transformObjectIntoDriverClass;
function transformObjectIntoContractorClass(object) {
    const contractorAsClass = new Contractor_1.Contractor();
    Object.assign(contractorAsClass, object);
    return contractorAsClass;
}
exports.transformObjectIntoContractorClass = transformObjectIntoContractorClass;
function transformObjectIntoClientClass(object) {
    const clientAsClass = new Client_1.Client();
    Object.assign(clientAsClass, object);
    return clientAsClass;
}
exports.transformObjectIntoClientClass = transformObjectIntoClientClass;
function transformObjectIntoRelationClass(object) {
    const relationAsClass = new Relation_1.Relation();
    Object.assign(relationAsClass, object);
    return relationAsClass;
}
exports.transformObjectIntoRelationClass = transformObjectIntoRelationClass;
function transformObjectIntoSynonymClass(object) {
    const synonymAsClass = new Synonym_1.Synonym();
    Object.assign(synonymAsClass, object);
    return synonymAsClass;
}
exports.transformObjectIntoSynonymClass = transformObjectIntoSynonymClass;
function transformObjectIntoCargoClass(object) {
    const cargoAsClass = new CargoClasses_1.Cargo();
    Object.assign(cargoAsClass, object);
    return cargoAsClass;
}
exports.transformObjectIntoCargoClass = transformObjectIntoCargoClass;
function transformObjectIntoChatMessageClass(object) {
    const messageAsClass = new ChatClasses_1.ChatMessage();
    Object.assign(messageAsClass, object);
    return messageAsClass;
}
exports.transformObjectIntoChatMessageClass = transformObjectIntoChatMessageClass;
function transformObjectIntoNoteClass(object) {
    const messageAsClass = new TimetableNote_1.TimetableNote(object.vehicleId, object.calendarweekDate);
    Object.assign(messageAsClass, object);
    return messageAsClass;
}
exports.transformObjectIntoNoteClass = transformObjectIntoNoteClass;
function parseElementsBasics(element) {
    return transformElementsDateAttributesToDate(transformElementsSynonymsClasses(element));
}
exports.parseElementsBasics = parseElementsBasics;
function parseDBDateAttributes(element) {
    return transformElementsDateAttributesToDate(element);
}
exports.parseDBDateAttributes = parseDBDateAttributes;
/**
 * Transforms all Jobs Date-Attributes Strings into the in Flutaro used Format (currently Moment, soon native JS-Date)
 * Last Test/Inspection-Date (TID): Aug/2020, VF
 * @element any
 */
function transformElementsDateAttributesToDate(element) {
    const dateArrays = ['weekEndWorkingDates', 'repeatingDates', 'weeklyExceptions'];
    for (const key of Object.keys(element)) {
        if (!element[key])
            continue;
        if (typeof element[key] === 'string' &&
            key.match(/^(earliestDate|latestDate|plannedDate|createdAt|latestCalculationDate|lastModifiedAt|latestUpdateDate|firstDayOfRepeatingJob|repeatUntilDay|startDate|endDate|blockStartDate|blockEndDate|displayDate|updatedOnDate|date|jobDate|latestEditDate|calendarweekDate)$/)) {
            element[key] = date_fns_1.parseISO(element[key]);
        }
        else if (dateArrays.includes(key)) {
            element[key] = element[key].map(dateString => date_fns_1.parseISO(dateString));
        }
        else if (typeof element[key] === 'object') {
            transformElementsDateAttributesToDate(element[key]);
        }
    }
    return element;
}
exports.transformElementsDateAttributesToDate = transformElementsDateAttributesToDate;
function parseFirestoreDateAttributes(element) {
    for (const key of Object.keys(element)) {
        const keysValue = element[key];
        if (!keysValue)
            continue;
        const isKeyMatch = key.match(/^(sendTime|receivedTime|lastModified|timestamp)$/);
        if (!isKeyMatch)
            continue;
        if (typeof keysValue === 'object' && !!keysValue.toDate) {
            // Firestore Server-Timestamp - New Default
            element[key] = keysValue.toDate();
        }
        else if (isKeyMatch && typeof keysValue === 'string') {
            // Legacy and should not be used, except for jobs so have same parsing for WebApp and App regarding Job-Dates
            element[key] = date_fns_1.parseISO(keysValue);
        }
    }
    return element;
}
exports.parseFirestoreDateAttributes = parseFirestoreDateAttributes;
function transformElementsSynonymsClasses(element) {
    for (const key of Object.keys(element)) {
        if (!element[key])
            continue;
        if (typeof element[key] === 'object' && key.match(/^(depotAddress|homeAddress|location|position|emptyKmAddress|addedStop)$/)) {
            element[key] = transformObjectIntoSynonymClass(element[key]);
        }
        else if (typeof element[key] === 'object') {
            transformElementsSynonymsClasses(element[key]);
        }
    }
    return element;
}
exports.transformElementsSynonymsClasses = transformElementsSynonymsClasses;
