import { Pipe, PipeTransform } from "@angular/core";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import {
  isAuthorizedAphroditeUser,
  isAuthorizedReadonlyAphroditeUser
} from "@flutaro/package/lib/functions/auth/AppAuthFunctions";

@Pipe({
  name: 'hasAphroditeAccess',
})
export class UserHasFullAphroditeAccessPipe implements PipeTransform {
  transform(user: FbStoreUserProfile): boolean {
    return isAuthorizedAphroditeUser(user)
  }
}

@Pipe({
  name: 'hasReadonlyAphroditeAccess',
})
export class UserHasReadonlyAphroditeAccessPipe implements PipeTransform {
  transform(user: FbStoreUserProfile): boolean {
    return isAuthorizedReadonlyAphroditeUser(user)
  }
}