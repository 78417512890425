import { Component, Input } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { FlutaroGroupPost, FlutaroPost, PostTypes } from "../../post";
import { TranslateService } from "@ngx-translate/core";
import { FlutaroLocationRating } from "../../../location/Location";

@Component({
	selector: 'post-add',
	templateUrl: './post.add.modal.html',
	styleUrls: ['./post.add.modal.scss']
})
export class PostAddModal {
	newPost: FlutaroPost | FlutaroGroupPost = new FlutaroPost();
	groupId: string;
	isEdit: boolean;
	@Input() data;
	@Input() user;

	constructor(public modalController: ModalController,
				private navParams: NavParams,
				private alertController: AlertController,
				private translate: TranslateService) {
		if (navParams.get('data')) {
			this.newPost = navParams.get('data');
			this.isEdit = true;
			console.log('Edit of Post! :)');
		}
		if (navParams.get('type')) {
			this.newPost.type = navParams.get('type');
		}
		if (!this.isEdit && navParams.get('groupId')) {
			this.groupId = navParams.get('groupId');
			console.log(`Post for Group - preparing data.`);
			this.newPost = new FlutaroGroupPost();
			this.newPost.type = PostTypes.GROUP_POST
			this.newPost['groupId'] = navParams.get('groupId');
		}
		if (navParams.get('user')) {
			this.newPost.authorId = navParams.get('user').uid;
		}
	}

	addRating(rating: FlutaroLocationRating) {
		if (!rating) {
			console.error(`ERROR: No Rating! Aborting addRating.`);
			return;
		}
		this.newPost = <any>rating;
		this.newPost.type = PostTypes.RATING;
	}

	dismiss() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.modalController.dismiss(null);
	}

	async save(disableTypeCheck?: boolean) {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		if (!disableTypeCheck && this.newPost.type === PostTypes.POST && !this.checkPostValid()) {
			return;
		}
		if (this.newPost.type === 'RATING' && (!this.newPost.location || !this.newPost.location.lat)) {
			await this.presentAlert(this.translate.instant('RATING_ADD_MISSING_INFORMATION_TITLE'), this.translate.instant('RATING_ADD_MISSING_INFORMATION_CONTENT'));
			return;
		}
		this.modalController.dismiss(this.newPost);
	}

	async presentAlert(title: string, content: string) {
		const alert = await this.alertController.create({
			header: title,
			message: content,
			buttons: ['OK']
		});

		await alert.present()
	}

	private checkPostValid() {
		return !!this.newPost.content;
	}
}
