import { DocumentReference } from "@angular/fire/compat/firestore";

export class ActivityFeedEntry {
  ref: DocumentReference;
  id: string;
  actionType: ActivityTypes;
  ownerId: string; // Owner means owner of the Timeline, e.g. the User who gets the Activity-Entry posted
  feedType: string;
  authorName: string;
  authorId: string;
  authorPhoto: string;
  timestamp: Date = new Date();
  postId: string = "";
  postRef;
  postDescription: string = ""; // Description of the Post the comment or like is for
  postType: string;
  mediaUrl: string; // Uknown
  isRead: boolean = false;
  commentData;

  constructor(
    type: ActivityTypes,
    ownerId: string,
    userId: string,
    userPhoto: string,
    userName: string,
    postId?: string,
    postDescription?: string,
    postType?: string,
    commentData?: string
  ) {
    this.actionType = type;
    this.ownerId = ownerId;
    this.authorId = userId;
    this.authorPhoto = userPhoto;
    this.authorName = userName;
    this.postId = postId;
    this.postDescription = postDescription;
    if (postType) {
      this.postType = postType;
    } else {
      delete this.postType;
    }
    if (commentData) {
      this.commentData = commentData;
    } else {
      delete this.commentData;
    }
  }
}

export enum ActivityTypes {
  FOLLOW = "FOLLOW",
  COMMENT = "COMMENT",
  LIKE = "LIKE",
  TAGGED = "TAGGED",
  INVITE = "INVITE",
}
