"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractorForVehicle = exports.getContractorRateForDate = void 0;
const date_fns_1 = require("date-fns");
const FlutaroDataFunctions_1 = require("./FlutaroDataFunctions");
const DataDateFunctions_1 = require("./DataDateFunctions");
function getContractorRateForDate(date, contractor) {
    if (!contractor ||
        !contractor.rates ||
        contractor.rates.length === 0 ||
        (contractor.rates.length === 1 && date_fns_1.isAfter(contractor.rates[0].date, date))) {
        return null;
    }
    else if (contractor.rates.length === 1) {
        return contractor.rates[0];
    }
    else {
        FlutaroDataFunctions_1.sortObjectsByDateAttribute(contractor.rates, 'date');
        return contractor.rates.find((rate) => DataDateFunctions_1.isBeforeOrSame(rate.date, date));
    }
}
exports.getContractorRateForDate = getContractorRateForDate;
function getContractorForVehicle(vehicleId, contractors) {
    if (!vehicleId || !(contractors === null || contractors === void 0 ? void 0 : contractors.length))
        return undefined;
    return contractors.find(contractor => contractor.vehicles.indexOf(vehicleId) !== -1);
}
exports.getContractorForVehicle = getContractorForVehicle;
