import { NgModule } from '@angular/core';
import { FlutaroRelativeTime, FilterArrayByKeyForValue, FlutaroParseDatePipe } from './flutaro.date.pipes';
import { TranslateTenantIdIntoNamePipe } from './translation.pipes';
import { GetVehiclesDriverNamePipe } from "./vehicle.pipes";
import {
  GetJobsStartDate,
  GetJobsEndDate,
  SortJobsByStartDate,
  GetJobsFirstPickUpPipe,
  GetJobsLastDeliveryPipe,
  FilterJobsForStatusPipe,
  FilterJobsForNotStatusPipe
} from "./flutaro.job.pipes";
import { TransformObjectIntoArray, FilterElementsByPipe, FlutaroOrderByPipe } from "./flutaro.data.pipes";
import { MinutesToDrivingTimePipe, DistanceAsKmDisplayedPipe } from './flutaro.format.pipes';
import { UserHasFullAphroditeAccessPipe, UserHasReadonlyAphroditeAccessPipe } from "./flutaro.auth.pipes";

@NgModule({
  declarations: [FlutaroRelativeTime,
    TranslateTenantIdIntoNamePipe,
    GetJobsStartDate,
    GetJobsEndDate,
    SortJobsByStartDate,
    GetJobsFirstPickUpPipe,
    GetJobsLastDeliveryPipe,
    GetVehiclesDriverNamePipe,
    TransformObjectIntoArray,
    FilterJobsForStatusPipe,
    FilterJobsForNotStatusPipe,
    MinutesToDrivingTimePipe,
    DistanceAsKmDisplayedPipe,
    FilterArrayByKeyForValue,
    UserHasFullAphroditeAccessPipe,
    UserHasReadonlyAphroditeAccessPipe,
    FilterElementsByPipe,
    FlutaroOrderByPipe,
    FlutaroParseDatePipe],
  imports: [],
  exports: [FlutaroRelativeTime,
    TranslateTenantIdIntoNamePipe,
    GetJobsStartDate,
    GetJobsEndDate,
    SortJobsByStartDate,
    GetJobsFirstPickUpPipe,
    GetJobsLastDeliveryPipe,
    GetVehiclesDriverNamePipe,
    TransformObjectIntoArray,
    FilterJobsForStatusPipe,
    FilterJobsForNotStatusPipe,
    MinutesToDrivingTimePipe,
    DistanceAsKmDisplayedPipe,
    FilterArrayByKeyForValue,
    UserHasFullAphroditeAccessPipe,
    UserHasReadonlyAphroditeAccessPipe,
    FilterElementsByPipe,
    FlutaroOrderByPipe,
    FlutaroParseDatePipe],
})
export class FlutaroPipesModule {
}
