import { Inject, Pipe, PipeTransform } from '@angular/core';
import { differenceInCalendarISOWeeks, format, isSameDay, parseISO, differenceInCalendarDays, } from 'date-fns';
import { AbstractTranslationService } from '../FlutaroLibClasses';
import { isValidDate } from '@flutaro/package/lib/functions/DataDateFunctions';

@Pipe({
  name: 'relativeTime',
})
export class FlutaroRelativeTime implements PipeTransform {
  constructor(@Inject('TranslateService') private translate: AbstractTranslationService) {
  }

  transform(value: Date | string, ...args: any[]): string {
    if (!value) return this.translate.instant('NO_DATE_AVAILABLE');
    const date = typeof value === 'string' ? parseISO(value) : value;
    if (!isValidDate(date)) {
      return this.translate.instant('NO_DATE_AVAILABLE');
    }
    const today = new Date();
    const diffInDaysToToday = differenceInCalendarDays(date, today);
    const diffInWeeksToToday = differenceInCalendarISOWeeks(date, today);
    const weekDay = this.translate.instant(format(date, `eeee`).toUpperCase());

    if (isSameDay(date, today)) {
      const today = this.translate.instant('TODAY');
      return format(date, `'${today}' HH:mm`);
    } else if (diffInDaysToToday === -1) {
      const yesterday = this.translate.instant('YESTERDAY');
      return format(date, `'${yesterday}' HH:mm`);
    } else if (diffInDaysToToday === 1) {
      const tomorrow = this.translate.instant('TOMORROW');
      return format(date, `'${tomorrow}' HH:mm`);
    } else if (diffInWeeksToToday === 0) {
      return format(date, `'${weekDay}' HH:mm`);
    } else if (diffInWeeksToToday === -1) {
      const lastWeek = this.translate.instant('LAST_WEEK');
      return format(date, `'${lastWeek}' '${weekDay}' HH:mm`);
    } else if (diffInWeeksToToday === 1) {
      const NEXT_WEEK = this.translate.instant('NEXT_WEEK');
      return format(date, `'${NEXT_WEEK}' '${weekDay}' HH:mm`);
    } else {
      return format(date, 'dd.LL.yyyy HH:mm');
    }
  }
}

@Pipe({
  name: 'filterKeyForValue'
})
export class FilterArrayByKeyForValue implements PipeTransform {
  transform(objects: any[], key: string, value: any): any[] {
    return objects.filter(obj => obj[key] === value);
  }
}

@Pipe({
  name: 'appFormatDate'
})
export class FlutaroParseDatePipe implements PipeTransform {
  transform(date: Date, formatType: 'daymonth' | 'date' | 'time' | 'datetime'): string {
    switch (formatType) {
      case 'date':
        return format(date, 'dd.MM.yyyy');
      case 'daymonth':
        return format(date, 'dd.MM.');
      case 'time':
        return format(date, 'HH:mm');
      case 'datetime':
        return format(date, 'dd.MM.yyyy HH:mm');
      default:
        return 'Unsupported format';
    }
  }
}
