<ion-card *ngIf="cargos && cargos.length" style=";background: linear-gradient(90deg,#3e4345 0,#64686a);">
  <ion-list
    *ngFor="let cargo of cargos">
    <ion-row>
      <ion-col col-12>
        <p class="jobModalHeaderTextLoading" translate>
          <i class="material-icons jobModalMainIcon">input</i>
          {{'JOB_CARGO_LOADING' | translate}}
        </p>
      </ion-col>
    </ion-row>

    <ion-item>
      <span translate>CARGO_CONTENT</span>
      <i class="material-icons" item-start>toc</i>
      <ion-label class="textRight">
        <h2>{{cargo.contents}}</h2>
        <p class="ion-text-wrap">
          {{cargo.amount}} x {{cargo.loadingEquipmentType?.description}}
        </p>
      </ion-label>
    </ion-item>

    <ion-item>
      <span translate>JOB_CARGO_CHANGE</span>
      <i class="material-icons" item-start>sync_alt</i>
      <ion-label class="textRight" *ngIf="cargo.exchangeLoadingEquipment">
        <h2 translate>{{'JOB_CARGO_DELIVER' | translate}}: {{cargo.amountLoadingEquipmentDelivered}}</h2>
        <h2 translate>{{'JOB_CARGO_RECEIVE' | translate}}: {{cargo.amountLoadingEquipmentTaken}}</h2>
      </ion-label>
    </ion-item>

    <ion-item>
      <span translate>JOB_CARGO_WEIGHT</span>
      <i class="material-icons" item-start>fitness_center</i>
      <ion-label class="textRight">
        <h2 translate>{{'JOB_CARGO_WEIGHT_GROSS' | translate}}: {{cargo.grossWeight}} {{cargo.grossWeightUnit}}</h2>
        <h2 translate>{{'JOB_CARGO_WEIGHT_NET' | translate}}: {{cargo.netWeight}} {{cargo.netWeightUnit}}</h2>
      </ion-label>
    </ion-item>

    <ion-item>
      <span translate>JOB_CARGO_LOAD_REFERENCE</span>
      <i class="material-icons" item-start>attachment</i>
      <ion-note class="cargoNoteItem" item-end>{{cargo.references}}</ion-note>
    </ion-item>

    <ion-item lines="none">
      <span translate>JOB_CARGO_CUSTOMER_NOTE</span>
      <i class="material-icons" item-start>edit</i>
      <ion-note class="cargoNoteItem"  item-end>{{cargo.customerRemarks}}</ion-note>
    </ion-item>
  </ion-list>
</ion-card>
