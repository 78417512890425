import { Injectable } from "@angular/core";
import { PostBasics, PostComment, PostTypes } from "../post/post";
import { prepareCommentBeforeFirebaseStorage, transformCommentForWebApp, } from "../../services/FirestoreFunctions";
import { groupsDataRef } from "../model/FirestoreModel";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivitiesService } from "../activities/activities.service";
import { ActivityFeedEntry, ActivityTypes } from "../activities/ActivitiesFeed";
import { map } from "rxjs/operators";
import { AnalyticsService } from "../../services/Analytics/analytics.service";
import { SOCIAL_EVENT } from "@flutaro/package/lib/model/AppAnalyticsEvents";
import { FirestoreCollection } from "@flutaro/package/lib/model/db/FirestoreClasses";

@Injectable({
  providedIn: "root",
})
export class CommentService {
  constructor(
    private fbStore: AngularFirestore,
    private activitiesProvider: ActivitiesService,
    private analytics: AnalyticsService
  ) {
  }

  getCommentsForPost(postId: string, groupId?: string) {
    const refFunction = (ref) =>
      ref.where("postId", "==", postId).orderBy("timestamp", "desc").limit(20);

    return this.fbStore
      .collection<PostComment[]>(
        !groupId
          ? FirestoreCollection.COMMENTS
          : `${groupsDataRef}/${groupId}/${FirestoreCollection.COMMENTS}`,
        refFunction
      )
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map((comment) => {
            return <PostComment>(
              transformCommentForWebApp(<any>comment)
            );
          })
        )
      )
      .toPromise();
  }

  async addComment(comment: PostComment, post: PostBasics): Promise<PostComment> {
    const isGroupComment = post.type === PostTypes.GROUP_POST || post["postType"] === PostTypes.GROUP_POST;
    const commentStorePath = !isGroupComment ? FirestoreCollection.COMMENTS : `${groupsDataRef}/${post["groupId"]}/comments`;
    this.analytics.logEvent(SOCIAL_EVENT.COMMENT_CREATED);
    return new Promise((resolve, reject) => {
      // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
      // In this example, we use setTimeout(...) to simulate async code.
      // In reality, you will probably be using something like XHR or an HTML5 API.
      const fbComment = prepareCommentBeforeFirebaseStorage(comment);
      this.fbStore
        .collection(commentStorePath)
        .add(fbComment)
        .then((commentRef) => {
          comment.id = commentRef.id;
          this.postActivityForComment(comment, post);
          console.log(
            `Successfully added comment ${comment.id} in ${commentStorePath}`
          );
          resolve(comment);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  editComment(comment: PostComment, post?) {
    const commentDocStorePath =
      post.type !== PostTypes.GROUP_POST
        ? `${FirestoreCollection.COMMENTS}/${comment.id}`
        : `${groupsDataRef}/${post["groupId"]}/${FirestoreCollection.COMMENTS}/${comment.id}`;
    this.analytics.logEvent(SOCIAL_EVENT.COMMENT_UPDATED);
    let updateMap = {
      lastModified: new Date(),
      content: comment.content,
    };
    this.fbStore.doc(commentDocStorePath).update(updateMap);
  }

  deleteComment(comment: PostComment, post?) {
    const commentDocStorePath =
      !post || post.type !== PostTypes.GROUP_POST
        ? `${FirestoreCollection.COMMENTS}/${comment.id}`
        : `${groupsDataRef}/${post["groupId"]}/${FirestoreCollection.COMMENTS}/${comment.id}`;
    this.analytics.logEvent(SOCIAL_EVENT.COMMENT_DELETED);
    this.fbStore
      .doc(commentDocStorePath)
      .delete()
      .then((success) => {
        console.log(
          `Successfully deleted comment ${comment.id} from ${commentDocStorePath}`
        );
      })
      .catch((error) => {
        console.error(
          `ERROR when trying to delete comment ${comment.id}. Error: ${error}`
        );
      });
  }

  postActivityForComment(comment: PostComment, post: PostBasics) {
    if (comment.authorId === post.authorId) return;
    const feedEntry = new ActivityFeedEntry(
      ActivityTypes.COMMENT,
      post.authorId,
      comment.authorId,
      comment.authorPhoto,
      comment.authorName,
      post.id,
      post.content,
      post.type,
      comment.content
    );
    this.activitiesProvider.postActivityForUser(feedEntry);
  }
}
