import { Pipe, PipeTransform } from "@angular/core";
import { ActivityTypes } from "../activities/ActivitiesFeed";
import { PostTypes } from "../post/post";

@Pipe({
	name: 'filterFeedData'
})
export class FilterFeedDataPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) return [];
		if (!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter(it => {
			return it.authorName.toLowerCase().includes(searchText)
		});
	}
}

@Pipe({
	name: 'translateTimelineType'
})
export class TranslateTimelineTypePipe implements PipeTransform {
	transform(activity: string, postType: string): string {
		switch (activity) {
			case ActivityTypes.FOLLOW:
				return 'IS_FOLLOWING_YOU_ACTIVITY';
			case ActivityTypes.LIKE:
				switch (postType) {
					case PostTypes.POST:
						return 'LIKES_YOUR_POST';
					case PostTypes.RATING:
						return 'LIKES_YOUR_RATING';
					case PostTypes.COMMENT:
						return 'LIKES_YOUR_COMMENT';
				}
				break;
			case ActivityTypes.COMMENT:
				switch (postType) {
					case PostTypes.POST:
						return 'COMMENTED_YOUR_POST';
					case PostTypes.RATING:
						return 'COMMENTED_YOUR_RATING';
					case PostTypes.COMMENT:
						return 'COMMENTED_YOUR_COMMENT';
				}
				break;
			case ActivityTypes.INVITE:
				switch (postType) {
					case PostTypes.GROUP_POST:
						return 'INVITED_YOU_INTO_GROUP';
				}
				break;
			case ActivityTypes.TAGGED:
				return 'MARKED_YOU_ACTIVITY';
			default:
				return 'Unknown type';
		}
	}
}
