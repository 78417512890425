"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FLUT_LoadingEquipmentTypes = exports.CargoReference = exports.LoadingEquipmentType = exports.Cargo = void 0;
class Cargo {
    constructor() {
        this.contents = '';
    }
}
exports.Cargo = Cargo;
class LoadingEquipmentType {
    constructor(description, shortDesc) {
        this.description = ''; // "Europalette"
        this.shortDesc = ''; //"EP"
        this.description = description;
        this.shortDesc = shortDesc;
    }
}
exports.LoadingEquipmentType = LoadingEquipmentType;
class CargoReference {
}
exports.CargoReference = CargoReference;
exports.FLUT_LoadingEquipmentTypes = [
    new LoadingEquipmentType('Europalette', 'EP'),
    new LoadingEquipmentType('Viertelpalette', 'VP'),
    new LoadingEquipmentType('Halbpalette', 'HP'),
    new LoadingEquipmentType('Kiste', 'Kiste'),
    new LoadingEquipmentType('Einwegpalette', 'Palette'),
    new LoadingEquipmentType('Rollen', 'RLL'),
    new LoadingEquipmentType('Bund', 'Bund'),
    new LoadingEquipmentType('Colli', 'COL'),
    new LoadingEquipmentType('Ladung', 'LDG'),
    new LoadingEquipmentType('Gestell', 'GST'),
    new LoadingEquipmentType('Rollen', 'RLL'),
    new LoadingEquipmentType('Big Bag', 'BBG'),
    new LoadingEquipmentType('Box', 'BOX'),
    new LoadingEquipmentType('Karton', 'KRT'),
    new LoadingEquipmentType('Gitterbox', 'GTB'),
    new LoadingEquipmentType('Container', 'CNT'),
];
