"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterJobsForNotStatus = exports.filterJobsForStatus = exports.getLatestJobOfJobs = exports.getEarliestJobOfJobs = exports.jobIsInWeekBeforeDate = exports.areJobsOverlapping = exports.sortJobsByLastDeliveryDateDesc = exports.sortJobsByFirstPickupDateAsc = exports.getDriversLastJobOfWeekForDate = exports.filterEventsStartingInTimeRange = exports.filterJobsStartingInTimeRange = exports.isDriversLastJobBlock = exports.getJobsWithLastDeliverySameDayAsDate = exports.getJobsWithSameStartDateAsDate = exports.setJobsOverlappingAndGetHighestValue = exports.areTwoJobsOverlapping = exports.areTimeIntervalsOverlapping = exports.filterJobsWithStartOnReferenceDate = exports.getJobsInTimeRange = exports.updateDestinationsForDistanceResponse = exports.updateJobForDistanceResponse = void 0;
const Driver_1 = require("../model/Driver");
const DestinationFunctions_1 = require("./DestinationFunctions");
const date_fns_1 = require("date-fns");
const DataDateFunctions_1 = require("./DataDateFunctions");
function updateJobForDistanceResponse(jobWrapper, distance) {
    updateDestinationsForDistanceResponse(jobWrapper.destinations, distance);
    jobWrapper.totalTimeInMinutes = distance.totalDuration;
    jobWrapper.totalDistance = distance.totalDistance;
}
exports.updateJobForDistanceResponse = updateJobForDistanceResponse;
function updateDestinationsForDistanceResponse(destinations, distance) {
    if (destinations.length > 2) {
        distance.sections.forEach((section, index) => {
            const dest = destinations[index + 1];
            dest.timeInMinutes = section.duration;
            dest.distance = section.length;
        });
    }
    else {
        destinations[1].distance = distance.totalDistance;
        destinations[1].timeInMinutes = distance.totalDuration;
    }
}
exports.updateDestinationsForDistanceResponse = updateDestinationsForDistanceResponse;
function getJobsInTimeRange(jobs, startDate, endDate) {
    return jobs.filter((job) => areTimeIntervalsOverlapping(DestinationFunctions_1.getJobsFirstPickupDate(job), DestinationFunctions_1.getJobsLastDeliveryDate(job), startDate, endDate, 'getJobsInTimeRange', job.job.identifier));
}
exports.getJobsInTimeRange = getJobsInTimeRange;
function filterJobsWithStartOnReferenceDate(jobs, referenceDate, isSundayTable) {
    return jobs.filter((job) => {
        var _a;
        if (isSundayTable)
            return date_fns_1.isSameDay((_a = DestinationFunctions_1.getFirstPickUp(job)) === null || _a === void 0 ? void 0 : _a.plannedDate, referenceDate);
        if (date_fns_1.getISODay(referenceDate) !== 6)
            return date_fns_1.isSameDay(DestinationFunctions_1.getJobsFirstPickupDate(job), referenceDate);
        // Saturday and noSundayTable - show jobs also for sunday to include all jobs
        return (date_fns_1.isSameDay(DestinationFunctions_1.getJobsFirstPickupDate(job), referenceDate) ||
            date_fns_1.isSameDay(DestinationFunctions_1.getJobsFirstPickupDate(job), date_fns_1.addDays(referenceDate, 1)));
    });
}
exports.filterJobsWithStartOnReferenceDate = filterJobsWithStartOnReferenceDate;
function areTimeIntervalsOverlapping(interval1Start, interval1End, interval2Start, interval2End, callingFunction, jobID) {
    if (!DataDateFunctions_1.isValidDateInterval(interval1Start, interval1End) || !DataDateFunctions_1.isValidDateInterval(interval2Start, interval2End)) {
        console.error(`areTimeIntervalsOverlapping, not valid interval ERROR
    		interval1Start: ${interval1Start},
    		interval1End: ${interval1End}`);
        if (callingFunction)
            console.error(`areTimeIntervalsOverlapping, error producing function: ${callingFunction}`);
        if (jobID)
            console.error(`areTimeIntervalsOverlapping, error producing job: ${jobID}`);
        return false;
    }
    const startInterval = new DataDateFunctions_1.DateInterval(interval1Start, interval1End);
    const endInterval = new DataDateFunctions_1.DateInterval(interval2Start, interval2End);
    try {
        return date_fns_1.areIntervalsOverlapping(startInterval, endInterval);
    }
    catch (e) {
        console.log(`areTimeIntervalsOverlapping, error in interval: `);
        console.log(startInterval);
        console.log(endInterval);
        return false;
    }
}
exports.areTimeIntervalsOverlapping = areTimeIntervalsOverlapping;
function areTwoJobsOverlapping(job, referenceJob) {
    return areTimeIntervalsOverlapping(DestinationFunctions_1.jobsFirstPickup(referenceJob).plannedDate, DestinationFunctions_1.jobsLastDelivery(referenceJob).plannedDate, DestinationFunctions_1.jobsFirstPickup(job).plannedDate, DestinationFunctions_1.jobsLastDelivery(job).plannedDate, 'areTwoJobsOverlapping');
}
exports.areTwoJobsOverlapping = areTwoJobsOverlapping;
function setJobsOverlappingAndGetHighestValue(jobs) {
    if (!jobs.length)
        return 0;
    const overlappingMatrix = [];
    jobs.forEach((job, index) => {
        if (index === 0) {
            job.overlapping = 0;
            overlappingMatrix.push([job]);
        }
        else {
            let isPlaced = false;
            overlappingMatrix.forEach((jobsArr, jobsArrIndex) => {
                if (isPlaced)
                    return;
                if (jobsArr.some((jobFromArr) => areTwoJobsOverlapping(jobFromArr, job)))
                    return;
                jobsArr.push(job);
                job.overlapping = jobsArrIndex;
                isPlaced = true;
            });
            if (isPlaced)
                return;
            // Start a new Row for overlapping jobs
            overlappingMatrix.push([job]);
            job.overlapping = overlappingMatrix.length - 1;
        }
    });
    return overlappingMatrix.length - 1;
}
exports.setJobsOverlappingAndGetHighestValue = setJobsOverlappingAndGetHighestValue;
function getJobsWithSameStartDateAsDate(jobs, referenceDate) {
    if (!jobs || jobs.length === 0 || !referenceDate)
        return [];
    return jobs.filter((driversJob) => date_fns_1.isSameDay(DestinationFunctions_1.getJobsFirstPickupDate(driversJob), referenceDate));
}
exports.getJobsWithSameStartDateAsDate = getJobsWithSameStartDateAsDate;
function getJobsWithLastDeliverySameDayAsDate(jobs, referenceDate) {
    if (!jobs || jobs.length === 0 || !referenceDate)
        return [];
    return jobs.filter((driversJob) => date_fns_1.isSameDay(DestinationFunctions_1.getJobsLastDeliveryDate(driversJob), referenceDate));
}
exports.getJobsWithLastDeliverySameDayAsDate = getJobsWithLastDeliverySameDayAsDate;
function isDriversLastJobBlock(driver, planningEntitiesLastJob, referenceDay) {
    if (!driver.block || !driver.block.blockZip)
        return false;
    const blockIsInCurrentWeek = date_fns_1.isSameISOWeek(referenceDay, driver.block.blockEndDate);
    const blockIsRelevantByReferenceDate = DataDateFunctions_1.isSameDayOrAfter(referenceDay, driver.block.blockEndDate);
    if (!planningEntitiesLastJob) {
        return blockIsInCurrentWeek && blockIsRelevantByReferenceDate;
    }
    else {
        return (blockIsInCurrentWeek &&
            blockIsRelevantByReferenceDate &&
            date_fns_1.isAfter(driver.block.blockEndDate, DestinationFunctions_1.jobsLastDelivery(planningEntitiesLastJob).plannedDate));
    }
}
exports.isDriversLastJobBlock = isDriversLastJobBlock;
function filterJobsStartingInTimeRange(jobs, start, end, noDriverOnly) {
    return jobs.filter((job) => {
        if (noDriverOnly && job.driver !== Driver_1.NoDriver.backendId)
            return false;
        return DataDateFunctions_1.isDateInInterval(DestinationFunctions_1.getFirstPickUp(job).plannedDate, start, end);
    });
}
exports.filterJobsStartingInTimeRange = filterJobsStartingInTimeRange;
function filterEventsStartingInTimeRange(events, start, end) {
    return events.filter((event) => areTimeIntervalsOverlapping(event.startTime, event.endTime, start, end, 'filterEventsStartingInTimeRange'));
}
exports.filterEventsStartingInTimeRange = filterEventsStartingInTimeRange;
function getDriversLastJobOfWeekForDate(jobs, date, driverId) {
    if (!jobs || !jobs.length)
        return null;
    if (jobs.length < 2)
        return jobs[0];
    let driversJobs = jobs.filter((job) => job.driver === driverId && date_fns_1.isSameISOWeek(DestinationFunctions_1.getJobsFirstPickupDate(job), date));
    driversJobs = sortJobsByLastDeliveryDateDesc(driversJobs);
    return driversJobs[0];
}
exports.getDriversLastJobOfWeekForDate = getDriversLastJobOfWeekForDate;
function sortJobsByFirstPickupDateAsc(jobs) {
    if (!jobs || jobs.length < 2)
        return jobs;
    jobs.sort((left, right) => {
        return DestinationFunctions_1.getJobsFirstPickupDate(left) - DestinationFunctions_1.getJobsFirstPickupDate(right);
    });
    return jobs;
}
exports.sortJobsByFirstPickupDateAsc = sortJobsByFirstPickupDateAsc;
function sortJobsByLastDeliveryDateDesc(jobs) {
    if (!jobs || jobs.length < 2)
        return jobs;
    jobs.sort((left, right) => {
        return DestinationFunctions_1.getJobsLastDeliveryDate(right) - DestinationFunctions_1.getJobsLastDeliveryDate(left);
    });
    return jobs;
}
exports.sortJobsByLastDeliveryDateDesc = sortJobsByLastDeliveryDateDesc;
function areJobsOverlapping(job, driversJobs) {
    const maxOverlapping = setJobsOverlappingAndGetHighestValue(driversJobs.concat([job]));
    return maxOverlapping > 0;
}
exports.areJobsOverlapping = areJobsOverlapping;
function jobIsInWeekBeforeDate(job, date) {
    return date_fns_1.isBefore(DestinationFunctions_1.getJobsLastDeliveryDate(job), date_fns_1.startOfISOWeek(date));
}
exports.jobIsInWeekBeforeDate = jobIsInWeekBeforeDate;
function getEarliestJobOfJobs(jobs) {
    if (!(jobs === null || jobs === void 0 ? void 0 : jobs.length))
        return undefined;
    if (jobs.length === 1)
        return jobs[0];
    return sortJobsByFirstPickupDateAsc(jobs)[0];
}
exports.getEarliestJobOfJobs = getEarliestJobOfJobs;
function getLatestJobOfJobs(jobs) {
    if (!(jobs === null || jobs === void 0 ? void 0 : jobs.length))
        return undefined;
    if (jobs.length === 1)
        return jobs[0];
    return sortJobsByFirstPickupDateAsc(jobs)[jobs.length - 1];
}
exports.getLatestJobOfJobs = getLatestJobOfJobs;
function filterJobsForStatus(jobs, status, status2) {
    if (!jobs.length || !status)
        return [];
    if (!status2)
        status2 = status;
    return jobs.filter(job => job.job.status === status || job.job.status === status2);
}
exports.filterJobsForStatus = filterJobsForStatus;
function filterJobsForNotStatus(jobs, status, status2) {
    if (!jobs.length || !status)
        return [];
    if (!status2)
        status2 = status;
    return jobs.filter(job => job.job.status !== status && job.job.status !== status2);
}
exports.filterJobsForNotStatus = filterJobsForNotStatus;
