import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, } from "@angular/fire/compat/firestore";
import { JobWrapper } from "@flutaro/package/lib/model/Job";
import { AnalyticsService } from "./Analytics/analytics.service";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { AppChatChannel } from "../model/chat.model";
import { ChatMessage, FBChatChannel, ChatMessageType, } from "@flutaro/package/lib/model/ChatClasses";
import { to, } from "@flutaro/package/lib/functions/AppJsHelperFunctions";
import { transformChatMessageForFirestore } from "@flutaro/package/lib/functions/ChatMessageFunctions";
import { MESSAGE_EVENT } from "@flutaro/package/lib/model/AppAnalyticsEvents";
import { BehaviorSubject } from "rxjs";
import { parseFirestoreDateAttributes } from "@flutaro/package/lib/functions/FlutaroDataFunctions";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  companyChannelsCollection: AngularFirestoreCollection<FBChatChannel>;
  $channels: BehaviorSubject<AppChatChannel[]> = new BehaviorSubject([]);
  channel: AppChatChannel;
  type: string;
  job: JobWrapper;
  onTop: boolean;
  // TODO: Refactor me :)
  newMessagesInOpenChannelCounter = 0;
  updateChannelId;
  userProfile: FbStoreUserProfile;

  constructor(private db: AngularFirestore,
              private analytics: AnalyticsService) {
  }

  listenToUsersChannels(userProfile: FbStoreUserProfile) {
    this.userProfile = userProfile;
    this.companyChannelsCollection = this.db.collection<FBChatChannel>(`chat/${userProfile.company}/channels`);
    this.userProfile.chatChannels.forEach((channel) => {
      this.listenToChatChannel(channel);
    });
  }

  getUserChannel(): AppChatChannel | undefined {
    return this.$channels.getValue().find(channel => channel.id === this.userProfile.uid);
  }

  async sendMessage(message: string,
                    channelId: string,
                    type: string,
                    job?: JobWrapper) {
    let newFbMessage = new ChatMessage();
    const userProfile = this.userProfile;
    newFbMessage.senderId = userProfile.uid;
    newFbMessage.senderName = userProfile.displayName;
    newFbMessage.senderAvatar = userProfile.photoURL;
    newFbMessage.message = message;
    type
      ? (newFbMessage.messageType = type)
      : (newFbMessage.messageType = ChatMessageType);
    if (job) {
      newFbMessage.messageJobId = job.backendId;
      newFbMessage.messageJobIdentifier = job.job.identifier;
    }

    const [messageError, messageSuccess] = await to(
      this.companyChannelsCollection
        .doc(channelId)
        .collection("messages")
        .add(transformChatMessageForFirestore(newFbMessage))
    );

    if (messageError) {
      console.error(`sendMessage, error when sending message:`);
      console.error(messageError);
      return;
    }
    let updateMap = {
      lastModified: new Date(),
      newCompanyMessage: true,
    };
    await this.companyChannelsCollection.doc(channelId).update(updateMap);
    this.analytics.logEvent(MESSAGE_EVENT.SEND, "MESSAGE_EVENT.SEND-ParameterName");
  }

  updateNewMessageForAppUserAttribute(
    channelId: string,
    newMessageForAppUser: boolean
  ) {
    const updateMap = { newMessageForAppUser: newMessageForAppUser, };
    this.companyChannelsCollection.doc(channelId).update(updateMap);
  }

  /**
   * Gets Channel Document with channel settings, attributes and messages.
   * Creates the Observable based on channel settings and stores in channels Array.
   * @param {string} channelName
   */
  listenToChatChannel(channelName: string) {
    this.companyChannelsCollection
      .doc<AppChatChannel>(channelName)
      .valueChanges()
      .subscribe((chatChannel) => {
        this.listenToChannelMessages(chatChannel);
      });
  }

  private listenToChannelMessages(channel: AppChatChannel) {
    const messagesCollection = this.companyChannelsCollection
      .doc(channel.id)
      .collection<ChatMessage>("messages");
    channel.messages = messagesCollection.valueChanges();
    this.updateChannelId = channel.id;
    if (this.onTop && this.updateChannelId === this.channel.id) {
      this.newMessagesInOpenChannelCounter++;
    } else {
      this.newMessagesInOpenChannelCounter = 0;
    }
    let newChannels = this.$channels.getValue();
    channel = parseFirestoreDateAttributes(channel);
    const channelIndex = newChannels.findIndex(oldChannel => oldChannel.id === channel.id);
    if (channelIndex === -1) {
      newChannels.push(channel);
    } else {
      newChannels[channelIndex] = channel;
    }
    this.$channels.next(newChannels);
  }
}
