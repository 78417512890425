"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimetableNote = void 0;
const IElement_1 = require("./IElement");
const date_fns_1 = require("date-fns");
class TimetableNote extends IElement_1.IElement {
    constructor(vehicleId, calendarweekDate) {
        super();
        this.note = '';
        this.toString = () => `in W${date_fns_1.getISOWeek(this.calendarweekDate)}/${date_fns_1.getYear(this.calendarweekDate)}`;
        this.vehicleId = vehicleId;
        this.calendarweekDate = date_fns_1.addMinutes(calendarweekDate, 1); // TODO: change to isSameOrAfter/Between once mongoDB access is refactored 
    }
}
exports.TimetableNote = TimetableNote;
