import { Injectable } from "@angular/core";
import { JobService } from "../../pages/jobs/job.service";
import { createPositionDataFromJobAndPosition } from "./PositionDataFunctions";
import { JobWrapper } from "@flutaro/package/lib/model/Job";
import { environment } from "../../../environments/environment";
import { AnalyticsService } from "../Analytics/analytics.service";
import { NotificationsService } from "../notifications/notifications.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { translateProviderState } from "./BackgroundTrackingHelpers";
import { createBackgroundTrackingConfigurationForJob } from "./BackgroundGeoConfiguration";
import { JobPairing } from "@flutaro/package/lib/model/Positiondata";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import BackgroundGeolocation, { Geofence } from "@transistorsoft/capacitor-background-geolocation";
import { to, flutaroWait } from "@flutaro/package/lib/functions/AppJsHelperFunctions";
import { GEO_TRACKING_EVENT, GEO_FENCE_EVENT } from "@flutaro/package/lib/model/AppAnalyticsEvents";
import { GpsConfigurationService } from "./gps.configuration.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GeodataBackgroundService {
  backgroundGeoWatchIsActivated: boolean;
  $isFullBackgroundLocationServiceAuthorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  companyGeoFences: Geofence[] = [];

  constructor(
    private jobProvider: JobService,
    private notifications: NotificationsService,
    private analytics: AnalyticsService,
    private aFS: AngularFirestore,
    private gpsConfigService: GpsConfigurationService
  ) {
  }

  /**
   * Activates Background-Geo-Plugin recording and Job watch. The ONLY place where BackgroundGeolocation.ready is called!
   * Only call once UserProfile is loaded and platform initialized.
   * iOs Reference: https://developer.apple.com/documentation/corelocation/cllocationmanager/1620551-requestalwaysauthorization
   */
  async configureAndActivateBackgroundPlugin(userProfile: FbStoreUserProfile) {
    console.log(`configureAndActivateBackgroundPlugin starting`);
    this.listenToGeolocationProviderChanges();
    await this.gpsConfigService.initAndConfigureBackgroundTracking(userProfile);
    this.listenToCompanyGeofences(userProfile);
    this.checkGPSStateAndActivateJobStartedWatch(userProfile);
    console.log(`configureAndActivateBackgroundPlugin finished`);
  }

  async requestLocationPermission() {
    console.debug(`requestLocationPermission called at ${new Date().toISOString()}`);
    const [permissionError, currentState] = await to(BackgroundGeolocation.requestPermission());
    if (permissionError) {
      console.log(`requestLocationPermission, error when requesting permissions. Error: ${permissionError}`);
    }
    this.$isFullBackgroundLocationServiceAuthorized.next(currentState === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS);
    console.debug(`requestLocationPermission, new $isFullBackgroundLocationServiceAuthorized state: ${this.$isFullBackgroundLocationServiceAuthorized.getValue()}`);
    return currentState;
  }

  async endBackgroundGeoRecording() {
    if (!this.backgroundGeoWatchIsActivated) {
      console.error(`endBackgroundGeoRecording called, but backgroundGeoWatchIsActivated = false. This shouldnt happen`);
      return;
    }
    const state = await BackgroundGeolocation.stop();
    console.debug(`endBackgroundGeoRecording, stopped tracking. Current Tracking-State isEnabled: ${state.enabled}`);
    this.backgroundGeoWatchIsActivated = false;
    return true;
  }

  private listenToCompanyGeofences(userProfile: FbStoreUserProfile) {
    this.aFS
      .collection("companyData")
      .doc(userProfile.company)
      .collection("geofences")
      .valueChanges()
      .subscribe((geofences) => {
        if (!geofences) {
          return;
        }

        console.log(geofences);
        this.companyGeoFences = <any>geofences;
      });
  }

  private addGeoFence(geofence: Geofence) {
    BackgroundGeolocation.addGeofence({
      identifier: geofence.identifier,
      radius: geofence.radius,
      latitude: geofence.latitude,
      longitude: geofence.longitude,
      notifyOnEntry: true,
      notifyOnExit: true,
    })
      .then((success) => {
        console.log("[addGeofence] success");
      })
      .catch((error) => {
        console.log("[addGeofence] FAILURE: ", error);
        this.analytics.logErrorCrashAnalytics(error);
      });
  }

  private async storeLatestPositionOFFAndEndBackgroundGeoRecording() {
    console.debug(`storeLatestPositionOFFAndEndBackgroundGeoRecording, called - getting current position for Jobs-Off Tracking`);
    await BackgroundGeolocation.setConfig({
      extras: {
        pairing: JobPairing.OFF,
        apiKeyFbBGApp: environment.apiKeyFbBGApp,
      }
    });
    const [locationError, location] = await to(BackgroundGeolocation.getCurrentPosition({
      timeout: 2,
      desiredAccuracy: 50,
      samples: 1
    }));
    if (locationError) {
      console.error(`storeLatestPositionOFFAndEndBackgroundGeoRecording, error when calling getCurrentPosition. Error: ${locationError}`);
      this.analytics.logErrorCrashAnalytics(`getCurrentPosition-error`);
    }
    await this.endBackgroundGeoRecording();
  }

  private async activateBackgroundGeolocation(
    currentStartedJob: JobWrapper,
    userProfile: FbStoreUserProfile
  ) {
    console.log(`activateBackgroundGeolocation, called - creating and setting background plugin configuration and starting service`);
    const position = createPositionDataFromJobAndPosition(
      JobPairing.ON,
      currentStartedJob
    );
    let pluginConfiguration = createBackgroundTrackingConfigurationForJob(
      position,
      userProfile,
      environment.production,
      environment.apiKeyFbBGApp,
      this.gpsConfigService.createBackgroundGPSPermissionRationale(),
      this.gpsConfigService.createBackgroundGPSNotificationText(),
      this.gpsConfigService.createBackgroundGPSLocationAuthorizationAlertForIOs()
    );
    console.log(`activateBackgroundGeolocation, pluginConfiguration created`);
    console.debug(pluginConfiguration);

    const backgroundPluginState = await BackgroundGeolocation.setConfig(pluginConfiguration);
    console.log(`activateBackgroundGeolocation, BackgroundGeolocation.setConfig called`);
    this.addGeoFencesFromFirestore();
    this.backgroundGeoWatchIsActivated = backgroundPluginState.enabled;
    console.debug(`activateBackgroundGeolocation, backgroundPluginState.enabled is ${backgroundPluginState.enabled}`);
    if (this.backgroundGeoWatchIsActivated) {
      console.debug(
        `activateBackgroundGeolocation, BackgroundGeolocation Plugin is already started and keeps recording`
      );
      this.analytics.logEvent(
        GEO_TRACKING_EVENT.GEO_TRACKING_IN_PROGRESS,
        currentStartedJob.backendId
      );
    } else {
      console.debug(`activateBackgroundGeolocation, BackgroundGeolocation Plugin is not yet started - starting ....`);
      const [pluginError, backgroundPluginStartState] = await to(BackgroundGeolocation.start());
      if (pluginError) {
        console.log(`ERROR in activateBackgroundGeolocation: Background-Plugin couldn´t be started. Plugin Error: ${pluginError}`);
        this.analytics.logEvent(
          GEO_TRACKING_EVENT.TRACKING_PLUGIN_ERROR,
          pluginError
        );
        this.notifications.showErrorToast(`Error activating Background Position-Tracking Plugin, couldnt start the service`);
        return false;
      }
      console.debug(`activateBackgroundGeolocation, BackgroundGeolocation started and running`);
      this.backgroundGeoWatchIsActivated = backgroundPluginStartState.enabled;
      this.analytics.logEvent(
        GEO_TRACKING_EVENT.GEO_TRACKING_ACTIVATE,
        currentStartedJob.backendId
      );
      return true;
    }
  }

  private addGeoFencesFromFirestore() {
    this.analytics.logEvent(
      GEO_FENCE_EVENT.ADDED,
      "geofences",
      this.companyGeoFences.length
    );
    this.companyGeoFences.forEach((geofence) => {
      this.addGeoFence(geofence);
    });
  }

  private async checkGPSStateAndActivateJobStartedWatch(userProfile: FbStoreUserProfile) {
    while (!await this.gpsConfigService.isAlwaysTrackingEnabled()) {
      console.debug(`checkGPSStateAndActivateJobStartedWatch, always-tracking not yet enabled - trying again in a second`);
      await flutaroWait(1000);
    }
    console.log(`checkGPSStateAndActivateJobStartedWatch, starting subscription and checkForStartedJobAndBackgroundGeoWatch`);
    this.jobProvider.currentStartedJob.subscribe((startedJob) => {
      this.checkForStartedJobAndBackgroundGeoWatch(startedJob, userProfile);
    });
  }

  private checkForStartedJobAndBackgroundGeoWatch(
    startedJob: JobWrapper,
    userProfile: FbStoreUserProfile
  ) {
    console.log(`checkForStartedJobAndBackgroundGeoWatch, startedJob: ${!!startedJob}, backgroundGeoWatchIsActivated already activated: ${this.backgroundGeoWatchIsActivated}`);
    if (startedJob && this.backgroundGeoWatchIsActivated) {
      return;
    } else if (!startedJob && this.backgroundGeoWatchIsActivated) {
      this.storeLatestPositionOFFAndEndBackgroundGeoRecording();
    } else if (startedJob && !this.backgroundGeoWatchIsActivated) {
      this.activateBackgroundGeolocation(startedJob, userProfile);
    }
  }

  private listenToGeolocationProviderChanges() {
    console.debug(`listenToLGeolocationProviderChanges, start listening to provider changes`);
    let currentGPSTrackingAuthorizationState: number;
    BackgroundGeolocation.onProviderChange(async (providerChange) => {
      const newProviderState = providerChange.status;
      console.debug(`listenToLGeolocationProviderChanges, new provider state received at ${new Date().toISOString()}. New State from Provider: ${translateProviderState(newProviderState)}`);
      if (newProviderState === currentGPSTrackingAuthorizationState) {
        console.debug(`SAME STATE: listenToLGeolocationProviderChanges, providerChange.status is same as lastState. Stopping here`);
        return;
      }
      currentGPSTrackingAuthorizationState = newProviderState;
      this.$isFullBackgroundLocationServiceAuthorized.next(newProviderState === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS);
      switch (newProviderState) {
        case BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED:
        case BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED:
          console.debug(`listenToGPSProviderChanges, AUTHORIZATION_STATUS_NOT_DETERMINED or AUTHORIZATION_STATUS_DENIED - showing Info-Alert and requesting permission`);
          await this.notifications.showInfoAlertWithOkAction('GPS_ACTIVATE_REQUEST_INFO_HEADER', 'GPS_ACTIVATE_REQUEST_INFO_MESSAGE');
          await this.requestLocationPermission();
          break;
        default:
          console.debug(`listenToGPSProviderChanges, authorization granted. Background-Plugin handles further permission handling`);
      }
      if (providerChange.accuracyAuthorization == BackgroundGeolocation.ACCURACY_AUTHORIZATION_REDUCED) {
        // iOs 14+ only - needs additional request for temporaryFullAccuracy (=== lifetime of this application run (until terminate)). Android and iOs 13 (and lower) always return FullAccuracy
        // Supply "Purpose" key from Info.plist as 1st argument.
        console.log(`listenToGPSProviderChanges, BackgroundGeolocation.accuracyAuthorization triggered because of ACCURACY_AUTHORIZATION_REDUCED. State: ${newProviderState}`);
        BackgroundGeolocation.requestTemporaryFullAccuracy("HighAccuracyPurpose")
          .then((accuracyAuthorization) => {
            if (accuracyAuthorization == BackgroundGeolocation.ACCURACY_AUTHORIZATION_FULL) {
              console.log(
                "[requestTemporaryFullAccuracy] GRANTED:",
                accuracyAuthorization
              );
            } else {
              console.log(
                "[requestTemporaryFullAccuracy] DENIED:",
                accuracyAuthorization
              );
            }
          })
          .catch((error) => {
            console.log(
              "[requestTemporaryFullAccuracy] FAILED TO SHOW DIALOG:",
              error
            );
          });
      }
    });
  }
}
