"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoDriver = exports.NoDriverId = exports.WeekEndOutside = exports.Driver = void 0;
const FlutaroClasses_1 = require("./FlutaroClasses");
const Synonym_1 = require("./Synonym");
const IElement_1 = require("./IElement");
const Position_1 = require("./Position");
class Driver extends IElement_1.PlanningEntity {
    constructor() {
        super();
        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.emergencyPhone = '';
        this.license = '';
        this.overlapping = 0;
        // Driver qualifications
        this.trainings = [];
        this.homeAddress = new Synonym_1.Synonym();
        this.regularWorkweekDriver = true;
        // Regular Workweeks are set default true and initialized.
        // Either regularWorkweek or alternatingWorkweek Object is set and the is-Value set true.
        this.regularWorkweek = new FlutaroClasses_1.RegularWorkweek();
        this.appointments = [];
        this.keepCargoDuringAbsence = true;
        this.goHomeEveryDay = false;
        // App Settings
        this.isAppActivated = false;
        // TODO: this should go into an own appUserAccount Object, which is only set if ann AppUsere is assigned to a vehicle or driver readonly appUID: string; // If the vehicle is an activated app account the UID will be set as long as the App Account is attached to the vehicle
        // Calculation related Attributes
        this.isAlwaysOutside = false;
        this.weekEndOutsides = []; // A driver can be marked to "stay outside at his last location for the weekend" instead of driving home, for cost and capacity calculation
        this.weekEndWorkingDates = [];
        this.toString = () => {
            if (!this.firstName && !this.lastName)
                return ''; // Early return if not a real address
            return `${this.firstName ? this.firstName + ' ' : ''}${this.lastName ? this.lastName : ''}`.trim();
        };
    }
}
exports.Driver = Driver;
class WeekEndOutside extends Position_1.AddressPosition {
    constructor(date, addressLine, zip, city, country, latitude, longitude, jobId, jobDate) {
        super();
        Object.assign(this, { date, addressLine, zip, city, country, latitude, longitude, jobId, jobDate });
    }
}
exports.WeekEndOutside = WeekEndOutside;
exports.NoDriverId = '-1';
exports.NoDriver = setNoDriverAttributes(new Driver());
function setNoDriverAttributes(driver) {
    driver.backendId = '-1';
    driver.firstName = 'No';
    driver.lastName = 'Driver';
    return driver;
}
