import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'post-event',
	templateUrl: './post-event.component.html',
	styleUrls: ['./post-event.component.scss'],
})
export class PostEventComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
