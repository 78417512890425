import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { ChatPage } from '../chat/chat.page';
import { AppChatChannel } from '../../model/chat.model';
import { Router } from '@angular/router';
import { UserProfileProvider } from "../../user/user.profile.provider";

@Component({
	selector: 'page-contact',
	templateUrl: './contact.page.html',
	styleUrls: ['./contact.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ContactPage implements OnInit {
	chatPage = ChatPage;

	constructor(
		public chatProvider: ChatService,
		public router: Router,
		public userProfileProvider: UserProfileProvider
	) {
	}

	ngOnInit() {
	}

	setChannel(channel: AppChatChannel) {
		if (channel.newMessageForAppUser) {
			channel.newMessageForAppUser = false;
			this.chatProvider.updateNewMessageForAppUserAttribute(
				channel.id,
				channel.newMessageForAppUser
			);
		}
		this.chatProvider.channel = channel;
		this.router.navigate(['/contact/page-chat']);
	}
}
