"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDriver = exports.getDriverById = void 0;
const date_fns_1 = require("date-fns");
const FlutaroDataFunctions_1 = require("../FlutaroDataFunctions");
const FlutaroClasses_1 = require("../../model/FlutaroClasses");
function getDriverById(driverId, drivers) {
    const driverForId = drivers.find(driver => driver.backendId === driverId);
    if (!driverForId) {
        // TODO: Activate again once DriverSchedule is in place and no driver.vehicleObject asynch update runs against vehicle update
        //console.error(`getDriverById couldnt find driver ${driverId} from ${drivers.length} provided Drivers. Returning null, check for reason - maybe deleted and not updated iin job`);
        return driverForId;
    }
    return driverForId;
}
exports.getDriverById = getDriverById;
function parseDriver(driver) {
    const newElement = FlutaroDataFunctions_1.transformObjectIntoDriverClass(FlutaroDataFunctions_1.parseElementsBasics(driver));
    // Temp Migrations - check regularly for deletion
    if (!newElement.appointments)
        newElement.appointments = [];
    if (!newElement.weekEndOutsides)
        newElement.weekEndOutsides = [];
    if (!newElement.weekEndWorkingDates)
        newElement.weekEndWorkingDates = [];
    if (!newElement.regularWorkweek && !newElement.alternatingWorkWeek) {
        newElement.regularWorkweekDriver = true;
        newElement.regularWorkweek = new FlutaroClasses_1.RegularWorkweek();
    }
    newElement.appointments = newElement.appointments.filter((appointment) => !!appointment.startDate && !!appointment.endDate);
    newElement.appointments = newElement.appointments.filter((appointment) => date_fns_1.isAfter(appointment.endDate, appointment.startDate));
    // Block Temp Fix
    if (newElement.block && (!date_fns_1.isDate(newElement.block.blockStartDate) || date_fns_1.isAfter(newElement.block.blockStartDate, newElement.block.blockEndDate))) {
        console.error(`driver parseElement, error in Block for driver ${newElement.backendId} - invalid interval (${newElement.block.blockStartDate} - ${newElement.block.blockEndDate}. Deleting block from Driver temporarily`);
        newElement.block = undefined;
    }
    //Appointments
    FlutaroDataFunctions_1.sortObjectsByDateAttribute(newElement.appointments, 'startDate');
    return newElement;
}
exports.parseDriver = parseDriver;
