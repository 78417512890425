<ion-row class="listHeaderRow ion-align-items-center">
  <ion-col col-5>
    <div class="listHeaderText">
      <ion-icon class="ionIconListHeader" name="document"></ion-icon>
      {{jobsFilter.$filteredJobs.getValue().length}} {{jobsFilter.$filteredJobs.getValue().length | translateJobsLength | translate}}
    </div>
  </ion-col>
  <ion-col *ngIf="jobsFilter.activeDateFilter" col-7>
    <p class="activeDateFilter">
      <ion-icon class="ionIconListHeader" name="funnel-outline"></ion-icon>
      {{jobsFilter.activeDateFilter | translateFilter | translate}}
    </p>
  </ion-col>
</ion-row>

<ion-content class="contentJobComponent">
  <ion-list>
    <jobs-items (acceptedOrderChange)="acceptOrder($event)"
                [currentStartedJob]="jobProvider.currentStartedJob | async"
                [isOnline]="deviceProvider.$isOnline | async"
                [jobsList]="jobsFilter.$filteredJobs | async"
                [userProfile]="userProvider.$userProfile | async">
    </jobs-items>

    <ion-item class="doneJobListCheckboxButton">
      <ion-label translate>JOBS_SHOW_HIDDEN</ion-label>
      <ion-checkbox [(ngModel)]="showHiddenJobs" color="secondary"></ion-checkbox>
    </ion-item>

    <div *ngIf="showHiddenJobs">
      <ion-item *ngFor="let job of jobProvider.$allData | async | filterForStatus:8:6"
                class="doneJobList">
        <button (click)="openJobModal(job)"
                class="jobListDone jobListButtonItem jobListButtonDoneItem"
                ion-item no-lines>
          <ion-row>
            <ion-col col-1>
              <div class="jobStatusStartedDiv">
                <div class="jobStatusDoneIcon"></div>
              </div>
            </ion-col>
            <ion-col col-3>
              <h3 id="jobListTimeItemStart">{{(job | jobsFirstPickUp).plannedDate | appFormatDate:'time'}}</h3>
              <div class="jobListLine"></div>
              <h3
                id="jobListTimeItemEnd">{{(job | jobsLastDelivery).plannedDate | appFormatDate:'time'}}</h3>
            </ion-col>
            <ion-col col-8>
              <ion-row>
                <h2 class="addressElement">
                  <ion-icon class="jobListStartIcon" name="pin"></ion-icon>
                  {{(job | jobsFirstPickUp).position.city}}
                </h2>
              </ion-row>
              <ion-row>
                <ion-col col-12>
                  <p class="jobListDistance">
                    {{job.totalDistance | distanceAsKm}}
                    ~ {{job.totalTimeInMinutes | minutesToDrivingTime}}</p>
                </ion-col>
              </ion-row>
              <ion-row>
                <h2 class="addressElement">
                  <ion-icon class="jobListEndIcon" name="pin"></ion-icon>
                  {{(job | jobsLastDelivery).position.city}}
                </h2>
              </ion-row>
            </ion-col>
          </ion-row>
        </button>
      </ion-item>
    </div>
  </ion-list>
</ion-content>
