<ion-searchbar color="colorSearchbar" [placeholder]="'SEARCH_FOR' | translate" [(ngModel)]="searchQuery"
               (input)="searchUsers($event)" [autocorrect]="'off'" (ionClear)="clearSearch()"
               (ionCancel)="clearSearch()"
               [showCancelButton]="'focus'"
               *ngIf="isEdit">
</ion-searchbar>
<ion-list *ngIf="isEdit">
    <ion-item [color]="'searchbarSearchListItem'" lines="none"
              *ngFor="let userOfSearch of userSearchList" (click)="addTaggedFriend(userOfSearch)">
        <app-avatar [authorPhoto]="userOfSearch.photoURL" [user]="user"
                    [authorId]="userOfSearch.uid">
        </app-avatar>
        {{userOfSearch.nickname}}
    </ion-item>
</ion-list>
<ion-list *ngIf="isEdit">
    <ion-item [color]="'searchbarSearchListItem'" lines="none"
              *ngFor="let taggedFriend of newTaggedFriends">
        <app-avatar [authorId]="taggedFriend.uid" [authorPhoto]="taggedFriend.photoURL"
                    [user]="user">
        </app-avatar>
        {{taggedFriend.nickname}}

        <ion-icon (click)="deleteTaggedFriend(taggedFriend)" *ngIf="taggedFriend.uid !== user.uid"
                  [name]="'close-circle'"
                  slot="end">
        </ion-icon>
    </ion-item>
    <ion-item-divider class="invitedUserDivider">
        {{dividerTitle | translate}}
    </ion-item-divider>
    <ion-item [color]="'searchbarSearchListItem'" lines="none"
              *ngFor="let taggedFriend of taggedFriends">
        <app-avatar [authorPhoto]="taggedFriend.photoURL" [user]="user"
                    [authorId]="taggedFriend.uid">
        </app-avatar>
        {{taggedFriend.nickname}}

        <ion-icon (click)="deleteTaggedFriend(taggedFriend)" *ngIf="taggedFriend.uid !== user.uid"
                  [name]="'close-circle'"
                  slot="end">
        </ion-icon>
    </ion-item>
</ion-list>
<!-- TODO @Gerrit: Wir können auch die ganze Liste inkl Icon anzeigen, auf klick oder so. Aber vllt. erst später ^^ Designen auf jeden Fall schon mal --->
<p *ngIf="!isEdit && taggedFriends && taggedFriends.length > 0" class="taggedFriendsText">
    {{'TAGGED_FRIENDS' | translate}}: <span>{{taggedFriends | taggedFriends}}</span>
</p>
