import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'page-job-decline',
	templateUrl: './job-decline.page.html',
	styleUrls: ['./job-decline.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class JobDeclinePage implements OnInit {
	jobIdentifier: string;
	jobDeclineReason: string;

	constructor(
		public viewCtrl: ModalController
	) {
	}

	ngOnInit() {
	}

	ionViewDidLoad() {
		console.log('ionViewDidLoad JobDeclinePage');
	}

	declineJob() {
		this.viewCtrl.dismiss(this.jobDeclineReason);
	}

	abbort() {
		this.viewCtrl.dismiss();
	}
}
