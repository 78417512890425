import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FlutaroUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { map } from "rxjs/operators";
import { ActivityFeedEntry, ActivityTypes } from "../activities/ActivitiesFeed";
import { ActivitiesService } from "../activities/activities.service";
import { AnalyticsService } from "../../services/Analytics/analytics.service";
import { SOCIAL_EVENT } from "@flutaro/package/lib/model/AppAnalyticsEvents";
import { UserProfilePublic } from "@flutaro/package/lib/model/UserProfile";
import { FirestoreCollection } from "@flutaro/package/lib/model/db/FirestoreClasses";

const followersRef = "followers";
const followingRef = "following";

@Injectable({
  providedIn: "root",
})
export class FollowerService {
  constructor(
    private fbStore: AngularFirestore,
    private activitiesProvider: ActivitiesService,
    private analytics: AnalyticsService
  ) {
  }

  followUser(profile: UserProfilePublic, user: FlutaroUserProfile) {
    this.analytics.logEvent(SOCIAL_EVENT.FOLLOW);
    // Make auth user follower of THAT user (update THEIR followers collection)
    this.fbStore
      .collection(`${FirestoreCollection.SOCIAL}/${profile.uid}/${followersRef}`)
      .doc(user.uid)
      .set({
        follower: true,
        name: user.nickname,
        photoURL: user.photoURL,
      });
    // Put THAT user on YOUR following collection (update your following collection)
    this.fbStore
      .collection(`${FirestoreCollection.SOCIAL}/${user.uid}/${followingRef}`)
      .doc(profile.uid)
      .set({
        following: true,
        name: profile.nickname,
        photoURL: profile.photoURL,
      });
    // add activity feed item for that user to notify about new follower (us)
    const feedEntry = new ActivityFeedEntry(
      ActivityTypes.FOLLOW,
      profile.uid,
      user.uid,
      user.photoURL,
      user.nickname
    );
    //this.activitiesProvider.postActivityForUser(feedEntry);
  }

  unfollowUser(userUid: string, follower: FlutaroUserProfile) {
    this.analytics.logEvent(SOCIAL_EVENT.UNFOLLOW);
    // remove
    this.fbStore
      .collection(`${FirestoreCollection.SOCIAL}/${userUid}/${followersRef}`)
      .doc(follower.uid)
      .delete();
    this.fbStore
      .collection(`${FirestoreCollection.SOCIAL}/${follower.uid}/${followingRef}`)
      .doc(userUid)
      .delete();
  }

  async getFollowers(userUid: string) {
    return this.fbStore
      .collection(`${FirestoreCollection.SOCIAL}/${userUid}/${followersRef}`)
      .get()
      .pipe(map((snapshot) => snapshot.size))
      .toPromise();
  }

  async getFollowing(userUid: string) {
    return this.fbStore
      .collection(`${FirestoreCollection.SOCIAL}/${userUid}/${followingRef}`)
      .get()
      .pipe(map((snapshot) => snapshot.size))
      .toPromise();
  }

  async checkIfFollowing(followingUID: string, follower: FlutaroUserProfile) {
    return this.fbStore
      .collection(`${FirestoreCollection.SOCIAL}/${followingUID}/${followersRef}`)
      .doc(follower.uid)
      .get()
      .pipe(map((doc) => doc.exists))
      .toPromise();
  }
}
