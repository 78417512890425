"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Synonym = exports.DistributionCenterSettings = exports.Location = exports.LocationTypes = exports.LocationOpeningTime = void 0;
const Position_1 = require("./Position");
class LocationOpeningTime {
    constructor() {
        this.hour = 0;
        this.minute = 0;
    }
}
exports.LocationOpeningTime = LocationOpeningTime;
exports.LocationTypes = {
    STORE: 'STO',
    LOCALSERVICECENTER: 'LSC',
    PARCELUNIT: 'PU',
    MULTICONSUMERDISTRIBUTIONCENTER: 'M-CDS',
    OTHER: 'sonst.',
};
class Location {
    constructor() {
        this.timewindowInMinutes = 0;
        // If isFixed is activated for Delivery and/or Pickup the regularOpening-Times for the Location are ignored.
        // The Jobs-Date is seen as a Date with timeWindowInMinutes = 0 for this Location for Pickup and/or Delivery.
        this.pickupIsFixed = false;
        this.deliveryIsFixed = false;
        // Regulating Times can differ depending on Pickup or Delivery. They take always effect no matter if DC or isFixed.
        this.pickupRegulatingTimeInMinutes = 0;
        this.deliveryRegulatingTimeInMinutes = 0;
        // Index of Day in Week - 0 is Monday, 6 Sunday
        this.openingWeekday = 0;
        this.closingWeekday = 5;
        // Hour and Minute Settings for
        this.openingDaily = new LocationOpeningTime();
        this.closingDaily = new LocationOpeningTime();
        this.type = '';
    }
}
exports.Location = Location;
class DistributionCenterSettings {
    constructor() {
        this.closingStartWeekday = 6;
        this.closingStartTime = new LocationOpeningTime();
        this.closingEndWeekday = 6;
        this.closingEndTime = new LocationOpeningTime();
    }
}
exports.DistributionCenterSettings = DistributionCenterSettings;
class Synonym extends Position_1.Address {
    constructor(city, zip, addressLine, country) {
        super();
        this.isInvalid = false;
        this.location = new Location();
        this.distributionCenter = false;
        this.name = '';
        this.description = '';
        this.toString = (isShort = false) => {
            return `${!isShort && this.code ? this.code + ': ' : ''}${!isShort && this.addressLine ? this.addressLine + ', ' : ''}${this.zip ? this.zip + ' ' : ''}${this.city ? this.city : ''}${this.country ? ', ' + this.country : ''}`;
        };
        Object.assign(this, { city, addressLine, zip, country });
    }
}
exports.Synonym = Synonym;
