"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCREEN_VIEWS = exports.SOCIAL_EVENT = exports.AUTH_EVENT = exports.MESSAGE_EVENT = exports.GEO_FENCE_EVENT = exports.GEO_TRACKING_EVENT = void 0;
var GEO_TRACKING_EVENT;
(function (GEO_TRACKING_EVENT) {
    GEO_TRACKING_EVENT["GEO_TRACKING_ACTIVATE"] = "geo_tracking_activated";
    GEO_TRACKING_EVENT["GEO_TRACKING_DEACTIVATE"] = "geo_tracking_deactivated";
    GEO_TRACKING_EVENT["GEO_TRACKING_IN_PROGRESS"] = "geo_tracking_in_progress";
    GEO_TRACKING_EVENT["TRACKING_PLUGIN_ERROR"] = "geo_tracking_plugin_error";
})(GEO_TRACKING_EVENT = exports.GEO_TRACKING_EVENT || (exports.GEO_TRACKING_EVENT = {}));
var GEO_FENCE_EVENT;
(function (GEO_FENCE_EVENT) {
    GEO_FENCE_EVENT["ADDED"] = "geofences_added";
})(GEO_FENCE_EVENT = exports.GEO_FENCE_EVENT || (exports.GEO_FENCE_EVENT = {}));
var MESSAGE_EVENT;
(function (MESSAGE_EVENT) {
    MESSAGE_EVENT["SEND"] = "message_send";
})(MESSAGE_EVENT = exports.MESSAGE_EVENT || (exports.MESSAGE_EVENT = {}));
var AUTH_EVENT;
(function (AUTH_EVENT) {
    AUTH_EVENT["LOGIN"] = "login";
    AUTH_EVENT["SIGN_UP"] = "sign_up";
})(AUTH_EVENT = exports.AUTH_EVENT || (exports.AUTH_EVENT = {}));
var SOCIAL_EVENT;
(function (SOCIAL_EVENT) {
    SOCIAL_EVENT["LIKE"] = "liked";
    SOCIAL_EVENT["UNLIKE"] = "unliked";
    SOCIAL_EVENT["POST_CREATED"] = "post_created";
    SOCIAL_EVENT["POST_UPDATED"] = "post_updated";
    SOCIAL_EVENT["POST_DELETED"] = "post_deleted";
    SOCIAL_EVENT["RATING_CREATED"] = "rating_created";
    SOCIAL_EVENT["RATING_UPDATED"] = "rating_updated";
    SOCIAL_EVENT["RATING_DELETED"] = "rating_deleted";
    SOCIAL_EVENT["COMMENT_CREATED"] = "comment_created";
    SOCIAL_EVENT["COMMENT_UPDATED"] = "comment_updated";
    SOCIAL_EVENT["COMMENT_DELETED"] = "comment_deleted";
    SOCIAL_EVENT["GROUP_CREATED"] = "group_created";
    SOCIAL_EVENT["GROUP_UPDATED"] = "group_updated";
    SOCIAL_EVENT["GROUP_DELETED"] = "group_deleted";
    SOCIAL_EVENT["GROUP_MEMBER_JOIN"] = "group_member_join";
    SOCIAL_EVENT["GROUP_MEMBER_LEAVE"] = "group_member_leave";
    SOCIAL_EVENT["GROUP_POST_CREATED"] = "group_post_created";
    SOCIAL_EVENT["GROUP_POST_UPDATED"] = "group_post_updated";
    SOCIAL_EVENT["GROUP_POST_DELETED"] = "group_post_deleted";
    SOCIAL_EVENT["EVENT_CREATED"] = "event_created";
    SOCIAL_EVENT["EVENT_UPDATED"] = "event_updated";
    SOCIAL_EVENT["EVENT_DELETED"] = "event_deleted";
    SOCIAL_EVENT["FOLLOW"] = "follow";
    SOCIAL_EVENT["UNFOLLOW"] = "unfollow";
})(SOCIAL_EVENT = exports.SOCIAL_EVENT || (exports.SOCIAL_EVENT = {}));
var SCREEN_VIEWS;
(function (SCREEN_VIEWS) {
    SCREEN_VIEWS["ABOUT_PAGE"] = "about";
})(SCREEN_VIEWS = exports.SCREEN_VIEWS || (exports.SCREEN_VIEWS = {}));
