<div *ngIf="post" class="activityOnlyForIonBackdrop">
  <ion-toolbar *ngIf="isModal">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="light">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="post.type === 'POST' || post.type === 'GROUP'" class="ion-text-center" translate>POST
    </ion-title>
    <ion-title *ngIf="post.type === 'RATING'" class="ion-text-center" translate>LOCATION_RATING</ion-title>
  </ion-toolbar>

  <div class="activityOnlyForIonBackgroundCardWrapper">
    <ion-card [color]="'color64666E'"
              style="border: 2px solid #3e4345;box-shadow:0 2px 5px 0 rgba(0, 0, 0, 0.2);-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2)">
      <ion-card-header *ngIf="post.type !== 'POST'">
        <ion-card-title *ngIf="post.type === 'RATING'" [color]="'colorPostCardTitle'" translate>LOCATION_RATING
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <app-feed-entry-header (deleteComment)="deleteComment($event)"
                               (groupPostDeleteChange)="emitGroupPostDelete($event)"
                               (groupPostEditChange)="emitGroupPostEdit($event)"
                               [isComment]="isComment"
                               [isReadOnly]="isReadOnly"
                               [post]="post"
                               [user]="user">
        </app-feed-entry-header>

        <div *ngIf="post.type !== 'RATING'">
          <ion-item [color]="'colorPost64666E'" lines="none">
            <p class="postContent">{{post.content }} </p>
          </ion-item>

          <ion-img *ngIf="post.photo" class="postImage" src="{{post.photo}}"></ion-img>

          <app-webpage-preview *ngIf="post.pagePreview"
                               [(webPagePreview)]="post.pagePreview">
          </app-webpage-preview>

          <post-location *ngIf="post.location" [isRating]="post.type === 'RATING'"
                         [location]="post.location"
                         [readOnly]="true">
          </post-location>

          <app-tag-friends [isEdit]="false"
                           [taggedFriends]="post.taggedFriends"
                           [user]="user">
          </app-tag-friends>
        </div>

        <div *ngIf="post.type === 'RATING'">
          <app-location-rating [rating]="post" [readOnly]="true"></app-location-rating>
        </div>

        <ion-grid class="likeCommentDiv">
          <ion-row>
            <!-- TODO: @vincent:
              add css-class "toggleHeartAnimation" every time,
              when the user clicks on the element: (click)="toggleHeart()"-->
            <span (click)="toggleHeart()" class="likeSpan">
                        <ion-icon *ngIf="isLiked" class="isLiked" name="heart"></ion-icon>
                        <ion-icon *ngIf="!isLiked" class="isNotLiked" name="heart-outline"></ion-icon>
                      </span>
            <span (click)="openCloseComments()" class="commentSpan">
                        <ion-icon name="chatbubble-outline"></ion-icon>
                        <ion-badge *ngIf="commentDepth !== 3 && post.commentsAmount > 0">{{post.commentsAmount}}
                        </ion-badge>
                      </span>
          </ion-row>
          <!-- show only if one like or more -->
          <ion-row *ngIf="post.likes.length > 0">
            <p (click)="showLikes()" class="flutaroLikesText">
              Gefällt {{post.likes.length}}
              <span>weiteren Personen</span>
              <!-- TODO: if length = 1 -> text: Person -->
              <!-- TODO: if length > 1 -> text: Personen -->
            </p>
          </ion-row>
        </ion-grid>

        <ion-list *ngIf="isCommentsOpened" class="postListComments">
          <ion-item *ngFor="let comment of comments | appOrderBy: ['-timestamp']"
                    [color]="'colortransparentPost'"
                    lines="none">
            <post-comment
              (commentChange)="editPostComment($event)"
              (level3CommentChange)="addComment($event)"
              [commentDepth]="commentDepth"
              [comment]="comment"
              [isNew]="false"
              [ngClass]="commentDepth === 1 ? 'deepLevel1' : 'deepLevel2'"
              [parentPost]="parentPost ? parentPost : post"
              [post]="post"
              [user]="user">
            </post-comment>
          </ion-item>
          <!-- New Comment Section -->
          <ion-item [color]="'color64666EProfile'" lines="none">
            <post-comment
              (commentChange)="addComment($event)"
              [(comment)]="newComment"
              [commentDepth]="commentDepth"
              [isNew]="true"
              [ngClass]="commentDepth === 1 ? 'deepLevel1' : 'deepLevel2'"
              [parentPost]="parentPost ? parentPost : post"
              [post]="post"
              [user]="user">
            </post-comment>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </div>
</div>
