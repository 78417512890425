"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setJobsPlannedDatesToEarliestValidDate = exports.setDestinationsPlannedDatesToEarliestValidDate = exports.changeJobsDriverAttributesToNoDriver = exports.changeJobsAttributesToNoDriver = exports.changeJobsAttributesForNewVehicle = exports.parseJob = exports.parseJobs = void 0;
const Job_1 = require("../model/Job");
const Driver_1 = require("../model/Driver");
const date_fns_1 = require("date-fns");
const FlutaroDataFunctions_1 = require("./FlutaroDataFunctions");
const AppClasses_1 = require("../model/AppClasses");
const DataDateFunctions_1 = require("./DataDateFunctions");
const CostsJobFunctions_1 = require("./costs/CostsJobFunctions");
function parseJobs(jobs) {
    return jobs.map(job => parseJob(job));
}
exports.parseJobs = parseJobs;
function parseJob(job) {
    var _a;
    if (!(job === null || job === void 0 ? void 0 : job.job) || !((_a = job.destinations) === null || _a === void 0 ? void 0 : _a.length)) {
        console.error(`parseJob, ERROR: no job, job.job or destinations present. Aborting parse`);
        return job;
    }
    if (!job.job.requirements)
        job.job.requirements = [];
    if (!job.job.properties)
        job.job.properties = [];
    if (!job.attributes)
        job.attributes = new Job_1.JobAttributes();
    if (!job.appSettings)
        job.appSettings = new AppClasses_1.JobAppSettings();
    return FlutaroDataFunctions_1.parseElementsBasics(FlutaroDataFunctions_1.transformObjectIntoJobClass(job));
}
exports.parseJob = parseJob;
/**
 *
 * @param job
 * @param vehicle
 * @param {Driver} driver
 * @param contractor
 * @param trailerSchedule
 */
function changeJobsAttributesForNewVehicle(job, vehicle, driver, contractor, trailerSchedule) {
    var _a;
    if (!vehicle) {
        console.log(`ERROR in changeDriverVehicleAttributes: No Vehicle! Aborting.`);
        return;
    }
    job.job.status = Job_1.JobStatuses.PLANNED;
    job.vehicleId = vehicle.backendId;
    job.vehicleLicensePlate = vehicle.licensePlate;
    if (vehicle.isSpot) {
        job.isVehicleSpot = true;
        job.isLocked = true;
    }
    // Driver
    if (driver) {
        job.driver = driver.backendId;
        job.driverName = driver.toString();
    }
    // Contractor
    if (contractor === null || contractor === void 0 ? void 0 : contractor.contractorId) {
        job.attributes.contractorId = contractor.contractorId;
    }
    else if ((_a = job.attributes) === null || _a === void 0 ? void 0 : _a.contractorId) {
        delete job.attributes.contractorId;
    }
    // Trailer
    if (trailerSchedule) {
        job.trailerId = trailerSchedule.entityId;
        job.trailerLicensePlate = trailerSchedule.licensePlate;
    }
    else {
        delete job.trailerId;
        delete job.trailerLicensePlate;
    }
}
exports.changeJobsAttributesForNewVehicle = changeJobsAttributesForNewVehicle;
function changeJobsAttributesToNoDriver(job) {
    job.job.status = Job_1.JobStatuses.NEW;
    changeJobsDriverAttributesToNoDriver(job);
    CostsJobFunctions_1.resetJobCosts(job);
    return job;
}
exports.changeJobsAttributesToNoDriver = changeJobsAttributesToNoDriver;
function changeJobsDriverAttributesToNoDriver(job) {
    job.driver = Driver_1.NoDriver.backendId;
    job.driverName = Driver_1.NoDriver.name;
    job.appSettings.inSyncWithApp = true;
    delete job.vehicleId;
    delete job.vehicleLicensePlate;
    delete job.appSettings.appUserEmail;
    delete job.appSettings.declineReason;
    if (job.attributes) {
        delete job.attributes.contractorId;
    }
}
exports.changeJobsDriverAttributesToNoDriver = changeJobsDriverAttributesToNoDriver;
function setDestinationsPlannedDatesToEarliestValidDate(destinations) {
    destinations.forEach((dest, index) => {
        if (index === 0) {
            dest.plannedDate = dest.earliestDate;
            return;
        }
        const prevDestination = destinations[index - 1];
        if (!DataDateFunctions_1.isValidDate(prevDestination.plannedDate) || !DataDateFunctions_1.isValidDate(dest.earliestDate)) {
            console.log(`setJobsPlannedDatesToEarliestValidDate, prevDestination.plannedDate(${prevDestination.plannedDate}) or dest.earliestDate(${dest.earliestDate}) invalid. Cant set plannedDate`);
            return;
        }
        const earliestActionDate = date_fns_1.addMinutes(prevDestination.plannedDate, dest.timeInMinutes);
        if (date_fns_1.isBefore(earliestActionDate, dest.earliestDate)) {
            dest.plannedDate = dest.earliestDate;
        }
        else {
            dest.plannedDate = earliestActionDate;
        }
    });
}
exports.setDestinationsPlannedDatesToEarliestValidDate = setDestinationsPlannedDatesToEarliestValidDate;
function setJobsPlannedDatesToEarliestValidDate(job) {
    setDestinationsPlannedDatesToEarliestValidDate(job.destinations);
}
exports.setJobsPlannedDatesToEarliestValidDate = setJobsPlannedDatesToEarliestValidDate;
