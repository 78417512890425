import { cloneDeep } from "lodash-es";
import { FlutaroPost, PostComment, PostTypes, SocialElementBasics, } from "../social/post/post";
import { ActivityFeedEntry, ActivityTypes, } from "../social/activities/ActivitiesFeed";
import { FlutaroLocationRating } from "../social/location/Location";
import { flutaroCloneObject } from "../AppFunctions";
import { FlutaroGroup } from "../social/groups/FlutaroGroup";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { isValidDate } from "@flutaro/package/lib/functions/DataDateFunctions";
import firebase from "firebase/compat/app";
import { cloneClassIntoPlainObject } from "@flutaro/package/lib/functions/AppJsHelperFunctions";
import { parseProfileBeforeFirestoreStorage } from "@flutaro/package/lib/functions/db/UserProfileFunctions";
import { parseFirestoreDateAttributes } from "@flutaro/package/lib/functions/FlutaroDataFunctions";
import DocumentData = firebase.firestore.DocumentData;
import DocumentReference = firebase.firestore.DocumentReference;
import FieldValue = firebase.firestore.FieldValue;

export function transformTypedArrayForFirestoreStorage(object) {
  return [].concat(object);
}

export function transformDateForFirestoreStorage(date: Date) {
  return isValidDate(date) ? date : null;
}

export function prepareUserProfileUpdateBeforeFirebaseStorage(userProfile: FbStoreUserProfile) {
  // TODO: refactor/change me for Public Profile
  let pUserProfile = prepareProfileBeforeFirebaseStorage(userProfile);

  delete pUserProfile.uid;
  delete pUserProfile.company;
  delete pUserProfile.fcmTokens;
  delete pUserProfile.roles;
  //TODO: activate again after check of successful migration delete pUserProfile.chatChannels;
  delete pUserProfile.email;
  delete pUserProfile.emailVerified;

  return pUserProfile;
}

export function prepareSocialDocumentBeforeFirestoreStorage(
  element: SocialElementBasics
) {
  let firestoreElement = cloneDeep(element);
  firestoreElement.lastModified =
    firebase.firestore.FieldValue.serverTimestamp();
  if (!firestoreElement.timestamp) {
    firestoreElement.timestamp =
      firebase.firestore.FieldValue.serverTimestamp();
  }

  return firestoreElement;
}

export function prepareGroupBeforeFirebaseStorage(group: FlutaroGroup) {
  return cloneClassIntoPlainObject(
    prepareGroupMembersBeforeFirebaseStorage(
      prepareSocialDocumentBeforeFirestoreStorage(group)
    )
  );
}

export function prepareGroupMembersBeforeFirebaseStorage(group: FlutaroGroup) {
  group.members = group.members.map((member) =>
    cloneClassIntoPlainObject(member)
  );
  return group;
}

export function preparePostBeforeFirebaseStorage(feed: FlutaroPost) {
  let post = prepareSocialDocumentBeforeFirestoreStorage(feed);
  feed.lastModified = new Date();
  if (!post.timestamp) {
    feed.timestamp = new Date();
  } else if (post.timestamp.isValid()) {
    post.timestamp = transformDateForFirestoreStorage(post.timestamp);
  } else {
    console.error(
      `ERROR in preparePostBeforeFirebaseStorage: Timestamp has invalid format. Aborting.`
    );
    return;
  }

  if (post.taggedFriends.length > 0) {
    post.taggedFriends = post.taggedFriends.map((friend) =>
      cloneClassIntoPlainObject(friend)
    );
    post.taggedFriends = transformTypedArrayForFirestoreStorage(
      post.taggedFriends
    );
  }
  if (post.location) {
    post.location = cloneClassIntoPlainObject(post.location);
  }
  return cloneClassIntoPlainObject(post);
}

export function prepareLocationRatingBeforeFirebaseStorage(
  rating: FlutaroLocationRating
) {
  let post = cloneDeep(rating);
  post.timestamp = transformDateForFirestoreStorage(post.timestamp);
  post.lastModified = transformDateForFirestoreStorage(post.lastModified);
  post.rating = cloneClassIntoPlainObject(post.rating);
  post.location = cloneClassIntoPlainObject(post.location);
  return cloneClassIntoPlainObject(post);
}

export function prepareCommentBeforeFirebaseStorage(comment: PostComment) {
  let post = cloneDeep(comment);
  post.timestamp = transformDateForFirestoreStorage(post.timestamp);
  post.lastModified = transformDateForFirestoreStorage(post.lastModified);
  return cloneClassIntoPlainObject(post);
}

export function prepareTimelineEntryForFiretore(
  timelineEntry: ActivityFeedEntry
) {
  let post = flutaroCloneObject(timelineEntry);
  post.timestamp = transformDateForFirestoreStorage(post.timestamp);
  if (post.actionType !== ActivityTypes.COMMENT) {
    delete post.commentData;
  }
  if (post.actionType === ActivityTypes.FOLLOW) {
    delete post.postId;
    delete post.postDescription;
  }

  return cloneClassIntoPlainObject(post);
}

export function preparePostBeforeFirebaseUpdate(feed: FlutaroPost | PostComment | FlutaroLocationRating) {
  let post = cloneDeep(feed);
  post.lastModified = transformDateForFirestoreStorage(post.lastModified);
  if (post.type === PostTypes.RATING) {
    post.rating = cloneClassIntoPlainObject(post.rating);
  }
  return cloneClassIntoPlainObject(post);
}

export function transformTimelineData(timeline: ActivityFeedEntry[]): ActivityFeedEntry[] {
  return timeline.map(entry => parseFirestoreDateAttributes(entry))
}

export function transformFeedForApp(
  docSnapshot: firebase.firestore.DocumentSnapshot<DocumentData>
) {
  let feed = docSnapshot.data();
  feed.ref = docSnapshot.ref;
  feed.id = docSnapshot.id;
  return parseFirestoreDateAttributes(feed);
}

export function transformCommentForWebApp(comment: firebase.firestore.DocumentSnapshot<DocumentData>) {
  return transformFeedForApp(comment);
}

export function setDocRefAfterDocAddedInFirestore(
  docDataObject,
  docDataRef: DocumentReference
) {
  docDataObject.docRef = docDataRef.path;
  docDataObject.id = docDataRef.id;
}

export function prepareProfileBeforeFirebaseStorage(profile: FbStoreUserProfile): any {
  let profileForStorage = parseProfileBeforeFirestoreStorage(profile);
  profileForStorage.lastModified = FieldValue.serverTimestamp();
  return profileForStorage
}
