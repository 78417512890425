import { JobWrapper, JobDestination } from "@flutaro/package/lib/model/Job";
import {
  getJobsFirstPickupDate,
  getJobsLastDeliveryDate,
  getLastDestination,
  getFirstPickUp
} from "@flutaro/package/lib/functions/DestinationFunctions";
import { Pipe, PipeTransform } from "@angular/core";
import {
  sortJobsByFirstPickupDateAsc,
  filterJobsForNotStatus,
  filterJobsForStatus
} from "@flutaro/package/lib/functions/FlutaroJobFunctions";

@Pipe({
  name: 'jobsStartDate'
})
export class GetJobsStartDate implements PipeTransform {
  transform(job: JobWrapper): Date {
    return getJobsFirstPickupDate(job);
  }
}

@Pipe({
  name: 'jobsEndDate'
})
export class GetJobsEndDate implements PipeTransform {
  transform(job: JobWrapper): Date {
    return getJobsLastDeliveryDate(job);
  }
}

@Pipe({
  name: 'sortJobsByStartDate'
})
export class SortJobsByStartDate implements PipeTransform {
  transform(jobs: JobWrapper[], ascending: boolean = true): JobWrapper[] {
    return ascending ? sortJobsByFirstPickupDateAsc(jobs) : jobs;
  }
}

@Pipe({
  name: 'jobsFirstPickUp'
})
export class GetJobsFirstPickUpPipe implements PipeTransform {
  transform(job: JobWrapper): JobDestination {
    return getFirstPickUp(job);
  }
}

@Pipe({
  name: 'jobsLastDelivery'
})
export class GetJobsLastDeliveryPipe implements PipeTransform {
  transform(job: JobWrapper): JobDestination {
    return getLastDestination(job);
  }
}

@Pipe({
  name: 'filterForStatus'
})
export class FilterJobsForStatusPipe implements PipeTransform {
  transform(jobs: JobWrapper[], status: number, status2?: number): JobWrapper[] {
    return filterJobsForStatus(jobs, status, status2);
  }
}

@Pipe({
  name: 'filterForNotStatus'
})
export class FilterJobsForNotStatusPipe implements PipeTransform {
  transform(jobs: JobWrapper[], status: number, status2?: number): JobWrapper[] {
    return filterJobsForNotStatus(jobs, status, status2);
  }
}