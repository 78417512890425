import { Injectable } from "@angular/core";
import BackgroundGeolocation, {
  LocationAuthorizationAlert,
  Notification,
  PermissionRationale,
  AuthorizationStatus
} from "@transistorsoft/capacitor-background-geolocation";
import { TranslateService } from "@ngx-translate/core";
import { AppDeviceService } from "../../app-device.service";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { BackgroundGeolocationFirebase } from "@transistorsoft/capacitor-background-geolocation-firebase";
import { createDefaultBackgroundTrackingConfiguration } from "./BackgroundGeoConfiguration";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GpsConfigurationService {

  constructor(private translate: TranslateService,
              private deviceService: AppDeviceService) {
  }

  /**
   * Initial Setup for Background-Tracking, only one time at start
   * @param userProfile
   */
  async initAndConfigureBackgroundTracking(userProfile: FbStoreUserProfile) {
    console.debug(`initAndConfigureBackgroundTracking, configuring BackgroundGeolocationFirebase & BackgroundGeolocation`);
    await BackgroundGeolocationFirebase.configure({
      locationsCollection: `/companyData/${userProfile.company}/drivers/${userProfile.uid}/positions`,
      geofencesCollection: `/companyData/${userProfile.company}/drivers/${userProfile.uid}/geofences`,
    });
    await BackgroundGeolocation.ready(
      createDefaultBackgroundTrackingConfiguration(
        environment.production,
        this.createBackgroundGPSPermissionRationale(),
        this.createBackgroundGPSNotificationText(),
        this.createBackgroundGPSLocationAuthorizationAlertForIOs()
      )
    );
    console.debug(`initAndConfigureBackgroundTracking, plugin is now ready`);
    return true;
  }

  async getCurrentPluginAuthState(): Promise<AuthorizationStatus> {
    return (await BackgroundGeolocation.getProviderState()).status;
  }

  async isAlwaysTrackingEnabled(): Promise<boolean> {
    return (await this.getCurrentPluginAuthState()) === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS;
  }

  public createBackgroundGPSPermissionRationale(): PermissionRationale {
    return {
      title: this.deviceService.isIos
        ? this.translate.instant("GPS_BACKGROUND_AUTHORIZATION_REQUEST_HEADER_IOS")
        : this.translate.instant("GPS_BACKGROUND_AUTHORIZATION_REQUEST_HEADER_ANDROID"),
      message: this.deviceService.isIos
        ? this.translate.instant("GPS_BACKGROUND_AUTHORIZATION_REQUEST_MESSAGE_IOS")
        : this.translate.instant("GPS_BACKGROUND_AUTHORIZATION_REQUEST_MESSAGE_ANDROID"),
      positiveAction: this.translate.instant("TO_SETTINGS"),
      negativeAction: this.translate.instant("CANCEL"),
    };
  }

  public createBackgroundGPSLocationAuthorizationAlertForIOs(): LocationAuthorizationAlert {
    const backgroundGPSPermissionRationale = this.createBackgroundGPSPermissionRationale();
    return {
      titleWhenNotEnabled: backgroundGPSPermissionRationale.title,
      titleWhenOff: backgroundGPSPermissionRationale.title,
      instructions: backgroundGPSPermissionRationale.message,
      cancelButton: backgroundGPSPermissionRationale.negativeAction,
      settingsButton: backgroundGPSPermissionRationale.positiveAction
    };
  }

  public createBackgroundGPSNotificationText(): Notification {
    // TODO: add translations and replace with this.translate.instant("FLUTARO_GPS_ACTIVATED") etc
    return {
      title: "Flutaro GPS activated",
      text: "Flutaro GPS background tracking is now activated",
    };
  }
}