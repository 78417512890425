"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapPlanningRole = exports.VehicleOnlyCompanyRole = exports.AppActivatedCompanyRole = exports.ApiActivatedRole = exports.AutomationActivatedRole = exports.NewsEditorRule = exports.DisableDeleteCreateAllRole = exports.DisableDeleteCreateJobsRole = exports.ReadOnlyUserRole = exports.WebAppCompanyAdminRole = exports.WebAppUserRole = exports.AppUserRole = exports.UserType = exports.ApiKeyUser = exports.FlutaroUserProfile = exports.UserProfileUpdate = exports.FbStoreUserProfile = exports.FbAuthProfile = exports.CLAIMS = exports.ROLES = void 0;
const AppClasses_1 = require("./AppClasses");
const UserProfile_1 = require("./UserProfile");
const UserProfileFunctions_1 = require("../functions/db/UserProfileFunctions");
var ROLES;
(function (ROLES) {
    ROLES["APP_USER"] = "APP_USER";
    ROLES["READONLY_USER"] = "READONLY_USER";
    ROLES["WEBAPP_USER"] = "WEBAPP_USER";
    ROLES["WEBAPP_USER_ADMIN"] = "WEBAPP_USER_ADMIN";
    ROLES["COMPANY_ADMIN"] = "COMPANY_ADMIN";
})(ROLES = exports.ROLES || (exports.ROLES = {}));
var CLAIMS;
(function (CLAIMS) {
    CLAIMS["WEBAPP_USER"] = "webapp_user";
    CLAIMS["COMPANY"] = "company";
})(CLAIMS = exports.CLAIMS || (exports.CLAIMS = {}));
class FbAuthProfile {
    constructor() {
        this.email = '';
        this.photoURL = UserProfile_1.NoUserBase64;
        this.displayName = '';
    }
}
exports.FbAuthProfile = FbAuthProfile;
class FbStoreUserProfile extends FbAuthProfile {
    constructor(uid, email, displayName, tenant) {
        super();
        this.roles = [];
        this.fcmTokens = {};
        this.company = '';
        this.chatChannels = [];
        this.appLanguage = AppClasses_1.AppLanguages.GERMAN;
        this.languages = [];
        this.nickname = ''; // TODO: delete me in regards of existing displayName attribute
        this.keywords = [];
        this.vehicleInformation = new UserProfile_1.TruckersVehicleInformation();
        Object.assign(this, { uid, email, displayName });
        this.nickname = displayName;
        this.keywords = UserProfileFunctions_1.generateKeywordsForName(displayName);
        this.uid = uid; // I hate TS sometimes ...
        if (tenant)
            this.tenant = tenant;
    }
}
exports.FbStoreUserProfile = FbStoreUserProfile;
class UserProfileUpdate {
}
exports.UserProfileUpdate = UserProfileUpdate;
class FlutaroUserProfile extends FbStoreUserProfile {
    constructor() {
        super(...arguments);
        // TODO: delete me ....
        this.hasWebAppUserAdminRole = false;
        this.hasReadOnlyRole = false;
        this.hasFilteredViewOnlyRole = false;
        this.hasDisableJobsCreateDeleteRole = false;
        this.hasDisableAllCreateDeleteRole = false;
        this.hasEditNewsRole = false;
        this.hasAutomationRole = false;
        this.hasApiRole = false;
        this.hasAppActivatedRole = false;
        this.isVehicleOnlyCompany = false;
        this.isMapPlanningCompany = true;
        this.isCompanyAdmin = false;
    }
}
exports.FlutaroUserProfile = FlutaroUserProfile;
class ApiKeyUser {
    constructor(apiKey, company) {
        this.apiKey = apiKey;
        this.company = company;
    }
}
exports.ApiKeyUser = ApiKeyUser;
var UserType;
(function (UserType) {
    UserType["APP"] = "APP";
    UserType["WEBAPP"] = "WEBAPP";
})(UserType = exports.UserType || (exports.UserType = {}));
exports.AppUserRole = 'APP_USER'; // Role for app (driverApp)= communicator.flutaro.de
exports.WebAppUserRole = 'WEBAPP_USER'; // ROLE for webapp (flutaroPlanning)= app.flutaro.de
exports.WebAppCompanyAdminRole = 'WEBAPP_USER_ADMIN'; // ROLE for webapp (flutaroPlanning)= app.flutaro.de
exports.ReadOnlyUserRole = 'READONLY_USER'; // ROLE for readOnly-accounts
exports.DisableDeleteCreateJobsRole = 'DISABLE_DELETE_CREATE_JOBS'; // ROLE for users who are allowed to delete/create JOBS
exports.DisableDeleteCreateAllRole = 'DISABLE_DELETE_CREATE_ALL'; // ROLE for users who are allowed to delete/create ALL objects
exports.NewsEditorRule = 'NEWS_EDITOR'; // ROLE for users who are allowed to create news at dashboard
exports.AutomationActivatedRole = 'AUTOMATION_ACTIVATED_COMPANY'; // Role for users who could use our automation-algorithm
exports.ApiActivatedRole = 'API_ACTIVATED_COMPANY'; // Role for users who use ExternalJob-Interface
exports.AppActivatedCompanyRole = 'APP_ACTIVATED_COMPANY'; // ROLE for users who could communicate with driverApp (send jobs and chat)
exports.VehicleOnlyCompanyRole = 'VEHICLE_ONLY_COMPANY'; // ROLE for users who could communicate with driverApp (send jobs and chat)
exports.MapPlanningRole = 'MAP_PLANNING'; // ROLE for users who could communicate with driverApp (send jobs and chat)
