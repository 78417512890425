import { Pipe, PipeTransform } from "@angular/core";
import { Vehicle } from "@flutaro/package/lib/model/Vehicle";
import { Driver } from "@flutaro/package/lib/model/Driver";
import { getVehiclesCurrentDriver } from "@flutaro/package/lib/functions/VehicleFunctions";

@Pipe({
  name: 'getVehiclesDriverName',
})
export class GetVehiclesDriverNamePipe implements PipeTransform {
  transform(vehicle: Vehicle, drivers: Driver[]): string {
    const vehiclesDriver = getVehiclesCurrentDriver(vehicle, drivers);
    return vehiclesDriver ? vehiclesDriver.toString() : '';
  }
}
