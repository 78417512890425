import { Injectable } from "@angular/core";
import { AlertController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(public toastCtrl: ToastController,
              private alertCtrl: AlertController,
              private translate: TranslateService) {
  }

  public async showSuccessMessage(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: "top",
      color: "success",
    });
    toast.present();
  }

  public async showWarningToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: "top",
      color: "warning",
    });
    toast.present();
  }

  public async showErrorToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 10000,
      position: "top",
      color: "danger",
    });
    toast.present();
  }

  public async showPushNotification(message: string) {
    // TODO: @Gerrit: We can also have here different Icons/Themes for Create, Update, Delete
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: "top",
    });
    toast.present();
  }

  public async showInfoAlertWithOkAction(title_key: string, message_key: string): Promise<boolean> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant(title_key),
      message: this.translate.instant(message_key),
      buttons: ['OK'],
      cssClass: "flutaro-alert-content",
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    return true;
  }
}
