import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  filterForCurrentWeekJobs,
  filterForNextWeekJobs,
  filterForTodaysJobs,
  filterForTomorrowJobs,
} from "../../jobs/JobFunctions";
import { BehaviorSubject, Subscription } from "rxjs";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { AppAuthService } from "../../auth/app-auth.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { JobStatuses, JobWrapper } from "@flutaro/package/lib/model/Job";
import { environment } from "../../../environments/environment";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { TranslateService } from "@ngx-translate/core";
import { JobDivision } from "../home/HomeClasses";
import { parseJobs } from "@flutaro/package/lib/functions/JobDataFunctions";
import {
  getEarliestJobOfJobs,
  getLatestJobOfJobs,
  filterJobsForNotStatus
} from "@flutaro/package/lib/functions/FlutaroJobFunctions";
import { to } from "@flutaro/package/lib/functions/AppJsHelperFunctions";

@Injectable({
  providedIn: "root",
})
export class JobService {
  $allData: BehaviorSubject<JobWrapper[]> = new BehaviorSubject([]);
  $data: BehaviorSubject<JobWrapper[]> = new BehaviorSubject([]);
  $isInRequestState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  jobsHotListenerSubscription: Subscription | null; //Active Subscription or not set
  jobDivision: JobDivision = new JobDivision();
  currentStartedJob: BehaviorSubject<JobWrapper> = new BehaviorSubject<JobWrapper>(null);

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private auth: AppAuthService,
    private fireStore: AngularFirestore,
    private translate: TranslateService) {
  }

  activateJobsHotListener(userProfile: FbStoreUserProfile) {
    if (this.jobsHotListenerSubscription) {
      console.log(`activateJobsHotListener, already activated. Skipping`);
      return;
    }
    const userJobsCollection = this.fireStore
      .collection("companyData")
      .doc(userProfile.company)
      .collection("drivers")
      .doc(userProfile.uid)
      .collection<JobWrapper>("jobs");
    this.jobsHotListenerSubscription = userJobsCollection
      .valueChanges()
      .subscribe((jobsData) => {
        console.log("Received Jobs Collection valueChanges update.");
        const jobs = parseJobs(jobsData);
        this.checkForStatusSentJobsAndSetAsReceived(jobs);
        this.updateData(jobs);
        this.checkAndPerformUpdateOfCurrentStartedJob(jobs);
      });
  }

  deactivateJobsHotListener() {
    if (this.jobsHotListenerSubscription) {
      this.jobsHotListenerSubscription.unsubscribe();
      this.jobsHotListenerSubscription = null;
    }
  }

  async updateJobsStatusses(jobs: JobWrapper[], isReceiveStatusUpdate?: boolean) {
    if (!isReceiveStatusUpdate) this.$isInRequestState.next(true);
    console.log(`updateJobsStatusses, called for ${jobs.length} jobs`);
    const updateIds = jobs.map((job) => {
      return {
        job: job.backendId,
        status: job.job.status,
        declineReason: job.appSettings.declineReason,
      };
    });
    const [updateError, updateSuccess] = await to(this.http.post(environment.apiUrl + "/updateJobStatus", updateIds).toPromise());
    if (!isReceiveStatusUpdate) this.$isInRequestState.next(false);
    if (updateError) {
      console.error(updateError);
      return;
    }
    if (!isReceiveStatusUpdate) this.notifications.showSuccessMessage(this.translate.instant("JOB_STATUS_UPDATE_SUCCESS"));
    console.log(`updateJobsStatusses, ${jobs.length} statuses successfully updated`);
  }

  updateJobStatus(job: JobWrapper) {
    this.updateJobsStatusses([job]);
  }

  setCurrentStartedJob(job: JobWrapper) {
    console.debug(`setCurrentStartedJob, starting job ${job.toString()}`);
    this.currentStartedJob.next(job);
  }

  endCurrentStartedJob() {
    console.debug(`endCurrentStartedJob, ending job ${this.currentStartedJob.getValue().toString()}`);
    this.currentStartedJob.next(null);
  }

  checkForStatusSentJobsAndSetAsReceived(jobs: JobWrapper[]) {
    let sentJobs = jobs.filter((job) => job.job.status === JobStatuses.SENT);
    if (!sentJobs.length) return;
    sentJobs.forEach((job) => (job.job.status = JobStatuses.RECEIVED));
    this.updateJobsStatusses(sentJobs, true);
  }

  calculateJobDivisions() {
    this.jobDivision = new JobDivision();
    let jobs: JobWrapper[] = this.$data.getValue();
    // Today
    this.jobDivision.today.jobs = filterForTodaysJobs(jobs);
    this.jobDivision.today.firstJob = getEarliestJobOfJobs(this.jobDivision.today.jobs);
    this.jobDivision.today.lastJob = getLatestJobOfJobs(this.jobDivision.today.jobs);
    // Tomorrow
    this.jobDivision.tomorrow.jobs = filterForTomorrowJobs(jobs);
    this.jobDivision.tomorrow.firstJob = getEarliestJobOfJobs(this.jobDivision.tomorrow.jobs);
    this.jobDivision.tomorrow.lastJob = getLatestJobOfJobs(this.jobDivision.tomorrow.jobs);
    // Current Week
    this.jobDivision.currentWeek.jobs = filterForCurrentWeekJobs(jobs);
    this.jobDivision.currentWeek.firstJob = getEarliestJobOfJobs(this.jobDivision.currentWeek.jobs);
    this.jobDivision.currentWeek.lastJob = getLatestJobOfJobs(this.jobDivision.currentWeek.jobs);
    // Next Week
    this.jobDivision.nextWeek.jobs = filterForNextWeekJobs(jobs);
    this.jobDivision.nextWeek.firstJob = getEarliestJobOfJobs(this.jobDivision.nextWeek.jobs);
    this.jobDivision.nextWeek.lastJob = getLatestJobOfJobs(this.jobDivision.nextWeek.jobs);
  }

  private updateData(jobs: JobWrapper[]) {
    this.$allData.next(jobs);
    this.$data.next(filterJobsForNotStatus(jobs, JobStatuses.DONE, JobStatuses.DECLINED));
    this.calculateJobDivisions();
  }

  private checkAndPerformUpdateOfCurrentStartedJob(jobs: JobWrapper[]) {
    const currentStartedJob = this.currentStartedJob.getValue();
    const startedJob: JobWrapper = jobs.find(job => job.job.status === JobStatuses.STARTED);
    const endedJobs: JobWrapper[] = jobs.filter(job => job.job.status === JobStatuses.DONE);
    if (!currentStartedJob && !startedJob) return;
    // Check for new done Job
    if (currentStartedJob && endedJobs.length) {
      const startedEndedJob = endedJobs.find(endedJob => endedJob.backendId === currentStartedJob.backendId);
      if (startedEndedJob) this.endCurrentStartedJob();
    }
    // Check for new started Job
    if (!currentStartedJob && startedJob) {
      this.setCurrentStartedJob(startedJob);
    }
  }
}
