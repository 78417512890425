"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TruckersVehicleInformation = exports.UserProfilePublic = exports.NoUserBase64 = void 0;
const UserProfileFunctions_1 = require("../functions/db/UserProfileFunctions");
exports.NoUserBase64 = 'https://firebasestorage.googleapis.com/v0/b/firebase-app-221116.appspot.com/o/user-5-line.svg?alt=media&token=1a24e196-a450-4e3c-9e6b-c1f90de9f05e';
class UserProfilePublic {
    constructor(uid, displayName, photoURL) {
        this.keywords = []; // Keywords to enable Firestore native User-Search
        this.vehicleInformation = new TruckersVehicleInformation();
        this.uid = uid;
        this.nickname = displayName;
        if (photoURL)
            this.photoURL = photoURL;
        this.keywords = UserProfileFunctions_1.generateKeywordsForName(displayName);
    }
}
exports.UserProfilePublic = UserProfilePublic;
class TruckersVehicleInformation {
}
exports.TruckersVehicleInformation = TruckersVehicleInformation;
