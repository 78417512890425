import { Injectable, Injector } from "@angular/core";
import { NotificationsService } from "./notifications/notifications.service";
import { UserProfileProvider } from "../user/user.profile.provider";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FLUT_PositionData } from "@flutaro/package/lib/model/Positiondata";

@Injectable({
  providedIn: "root",
})
export class GeoDataFirestoreService {
  constructor(
    private fireStore: AngularFirestore,
    private notification: NotificationsService,
    public injector: Injector,
    private userProvider: UserProfileProvider
  ) {
  }

  storeUsersGeoData(position: FLUT_PositionData) {
    if (!position || !position.latitude) {
      console.log("Providers are missing in GeoDataFirestoreProvider");
      return;
    }
    const userProfile = this.userProvider.$userProfile.getValue();
    this.fireStore
      .collection("companyData")
      .doc(userProfile.company)
      .collection("drivers")
      .doc(userProfile.uid)
      .collection("positions")
      .add({
        latitude: position.latitude,
        longitude: position.longitude,
        customerId: position.customerId,
        jobId: position.jobId,
        jobCustomerId: position.jobCustomerId,
        vehicleId: position.vehicleId,
        licensePlate: position.licensePlate,
        date: position.date,
        driverUID: userProfile.uid,
        company: userProfile.company,
        pairing: position.pairing,
        sentToCustomerAPI: false,
        customerPickupLocationId: position.customerPickupLocationId,
        customerDeliveryLocationId: position.customerDeliveryLocationId,
        jobsDeliveryPlannedDate: position.jobsDeliveryPlannedDate,
      })
      .then(
        (docRef) => {
          //this.notification.showSuccessMessage(`Stored ${ref.id} in Firestore`);
        },
        (error) => {
          //this.notification.showSuccessMessage(`Error: ${error}`);
        }
      );
  }
}
