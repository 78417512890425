import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { AppAuthService } from "./app-auth.service";
import { FlutaroUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { isBefore, subMinutes } from "date-fns";

@Component({
  selector: "page-login-error",
  templateUrl: "./auth-error.page.html",
  styleUrls: ["./auth-error.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginErrorPage implements OnInit {
  appUserRole = FlutaroUserProfile;
  isEmailVerificationResentEnabled: boolean = false;
  isEmailVerificationResent: boolean = false;

  constructor(public auth: AppAuthService) {
  }

  ngOnInit() {
    const user = this.auth.$user.getValue();
    const creationDate = new Date(user.metadata.creationTime);
    // Dont show resend verification email if user account was just created
    this.isEmailVerificationResentEnabled = isBefore(creationDate, subMinutes(new Date(), 2)) && !user.emailVerified;
  }

  resendEmailVerificationEmail() {
    const user = this.auth.$user.getValue();
    if (!user || user.emailVerified) {
      return;
    }
    this.isEmailVerificationResent = true;
    user.sendEmailVerification().then((success) => {
      console.debug("resendEmailVerificationEmail, Email verification successfully sent.");
    });
  }
}
