import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FlutaroPost } from "../post";
import { FlutaroUserProfile } from "@flutaro/package/lib/model/AuthClasses";

@Component({
  selector: "post-feed",
  templateUrl: "./post-feed.component.html",
  styleUrls: ["./post-feed.component.scss"],
})
export class PostFeedComponent implements OnInit {
  @Input() post: FlutaroPost;
  @Input() user: FlutaroUserProfile;
  @Output() postChange: EventEmitter<FlutaroPost> =
    new EventEmitter<FlutaroPost>();
  @Output() send: EventEmitter<FlutaroPost> = new EventEmitter<FlutaroPost>();
  showPhotoPicker: boolean = false;
  showWebPagePreviewEdit: boolean = false;
  showMapPageEdit: boolean = false;
  showTagFriends: boolean = false;

  constructor() {}

  ngOnInit() {}

  activatePhotoPicker() {
    this.showPhotoPicker = !this.showPhotoPicker;
  }

  editPagePreviewLink() {
    this.showWebPagePreviewEdit = !this.showWebPagePreviewEdit;
  }

  showMapLocationSearch() {
    this.showMapPageEdit = !this.showMapPageEdit;
  }

  tagFriends() {
    this.showTagFriends = !this.showTagFriends;
  }

  sendComment() {
    this.postChange.emit(this.post);
    this.send.emit(this.post);
  }
}
