"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vehicle = void 0;
const IElement_1 = require("./IElement");
const VehicleConstants_1 = require("./VehicleConstants");
const PlanningEntitySettings_1 = require("./PlanningEntitySettings");
class Vehicle extends IElement_1.PlanningEntity {
    constructor(vehicleProfile) {
        super();
        this.name = '';
        this.licensePlate = ''; // TODO: this needs to be unique for excel Import to identify the corresponding vehicle
        this.timetableSettings = new PlanningEntitySettings_1.PlanningEntitySettings();
        this.driver = '';
        this.driverName = '';
        // Vehicle properties
        this.specialAttributes = [];
        // TODO: User-Settings: let User decide default value for carType
        this.carType = VehicleConstants_1.VEHICLE_TYPE.TRUCK;
        this.type = VehicleConstants_1.VEHICLE_TYPE.TRUCK;
        this.carmanufacturer = '';
        this.vehiclePhone = '';
        this.vehicleEmail = ''; // TODO: refactor/delete me for just email
        this.vehicleProfile = VehicleConstants_1.VEHICLE_SPEED_PROFILE.EIGHTY;
        this.trailerCount = 0;
        // App Settings
        this.chooseEmailBasedOnVehicle = true; // use vehicle.vehicleEmail as identifier for App User-Account
        this.emissionCategory = VehicleConstants_1.VEHICLE_EMISSION_TYPES.EURO_V; // I, II, III, IV, V, VI, EURO EEV, Electric
        this.axisNumber = 0; // for Toll Calculation
        // Cost Attributes
        this.kmRate = 0; // Costs in Euro per empty-Km
        this.fixedDailyCosts = 0; // Fixed vehicle Costs
        this.isSpot = false; // Spot-Vehicles have no Driver assigned, are always calculated by their depotAddress and  have no statistics regarding drivingTime
        // Schedules
        this.driverSchedules = [];
        this.trailerSchedules = [];
        this.toString = () => `${this.licensePlate}`;
        if (vehicleProfile)
            this.vehicleProfile = vehicleProfile;
    }
}
exports.Vehicle = Vehicle;
