import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { JobService } from '../jobs/job.service';
import { ChatService } from '../../services/chat.service';
import { JobsFilterService } from '../jobs/jobs-filter.service';
import { HomeShiftType } from './HomeClasses';
import { GeodataBackgroundService } from '../../services/geodata/geodata-background.service';
import { AppDeviceService } from '../../app-device.service';
import { UserProfileProvider } from "../../user/user.profile.provider";

@Component({
  selector: 'page-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit {
  shiftTypes = HomeShiftType;

  constructor(
    public jobProvider: JobService,
    public chatProvider: ChatService,
    public jobFilter: JobsFilterService,
    public geobackProvider: GeodataBackgroundService,
    public deviceProvider: AppDeviceService,
    public userProvider: UserProfileProvider
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.jobProvider.calculateJobDivisions();
  }
}
