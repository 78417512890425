import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { timelineRef } from "../model/FirestoreModel";
import { ActivityFeedEntry } from "../activities/ActivitiesFeed";
import { map } from "rxjs/operators";
import { basicPostFirestoreHandler } from "../post/PostFunctions";
import { cloneFeedObject } from "../../AppFunctions";
import { FlutaroPost } from "../post/post";
import { BehaviorSubject } from "rxjs";
import { FirestoreCollection } from "@flutaro/package/lib/model/db/FirestoreClasses";

@Injectable({
  providedIn: "root",
})
export class TimelineService {
  commonTimelineRef = `${FirestoreCollection.SOCIAL}/commonTimeline/${timelineRef}`;
  $timeline: BehaviorSubject<ActivityFeedEntry[]> = new BehaviorSubject([]);
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  latestSetting = {};

  constructor(private fbStore: AngularFirestore) {
  }

  getTimeline(userUid: string, isFlutaroCommonOnDefault: boolean) {
    this.$timeline.next([]);
    this.latestSetting["userUid"] = userUid;
    this.latestSetting["isFlutaroCommonOnDefault"] = isFlutaroCommonOnDefault;
    const userTimelineRef = `${FirestoreCollection.SOCIAL}/${userUid}/${timelineRef}`;
    const timelineRequestRef = isFlutaroCommonOnDefault
      ? this.commonTimelineRef
      : userTimelineRef;
    console.log(`Timeline Request Ref: ${timelineRequestRef}`);
    this.isLoading.next(true);
    this.fbStore
      .collection(timelineRequestRef, (ref) =>
        ref.orderBy("timestamp", "desc").limit(10)
      )
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map(
            (docRef) => <ActivityFeedEntry>basicPostFirestoreHandler(docRef)
          )
        )
      )
      .toPromise()
      .then((timelineData) => {
        this.$timeline.next(timelineData);
        this.isLoading.next(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Reloads Timeline on new Post or Rating. Call is delayed to give Cloud Function some time to run
   */
  reloadTimeline() {
    this.getTimeline(
      this.latestSetting["userUid"],
      this.latestSetting["isFlutaroCommonOnDefault"]
    );
  }

  addFeedInternally(post: FlutaroPost) {
    console.log(
      `Adding Post Timeline Entry: ${post.type} ${post.content} with ID: ${post.id} from Author ${post.authorName}`
    );
    const dataBeforePost = this.$timeline.getValue().slice();
    dataBeforePost.push(<any>post);
    this.$timeline.next(dataBeforePost);
  }

  updateFeedInternally(post: FlutaroPost) {
    console.log(
      `Updating Timeline Entry: ${post.type} ${post.content} with ID: ${post.id} from Author ${post.authorName}`
    );
    const newRefPost: FlutaroPost = <FlutaroPost>cloneFeedObject(post);
    let oldFeeds = this.$timeline.getValue().slice();
    oldFeeds = oldFeeds.filter((feed) => feed.id !== newRefPost.id);
    oldFeeds.push(<any>newRefPost);
    this.$timeline.next(oldFeeds);
  }

  deleteFeedInternally(post: FlutaroPost) {
    console.log(
      `Deleting Timeline Entry: ${post.type} ${post.content} with ID: ${post.id} from Author ${post.authorName}`
    );
    let oldFeeds = this.$timeline.getValue().slice();
    oldFeeds = oldFeeds.filter((feed) => feed.id !== post.id);
    this.$timeline.next(oldFeeds);
  }
}
