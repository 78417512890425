import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppAuthService } from "../../auth/app-auth.service";
import { PushMessagesService } from "../../services/push/push-messages.service";
import { AppDeviceService } from "../../app-device.service";
import { Router } from "@angular/router";

@Component({
  selector: "page-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPage implements OnInit {
  loginForm: FormGroup;
  loginError: string;
  pwWasResettedSuccessfully: boolean = false;

  validationMessages = {
    email: [
      { type: "required", message: "Email is required." },
      { type: "email", message: "It should be valid email address" },
    ],
    password: [
      { type: "required", message: "Password is required." },
      {
        type: "minlength",
        message: "Password length should be minimum of 6 digits.",
      },
    ],
  };

  constructor(
    private auth: AppAuthService,
    public pushProvider: PushMessagesService,
    public deviceProvider: AppDeviceService,
    private fb: FormBuilder,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.compose([Validators.required, Validators.minLength(6)])],
    });
    console.log(`Login Form Init.`);
    this.pwWasResettedSuccessfully = false;
  }

  login() {
    let data = this.loginForm.value;
    if (!data.email) {
      return;
    }

    let credentials = {
      email: data.email,
      password: data.password,
    };
    this.auth.signInWithEmail(credentials).then(
      () => {
      },
      (error) => (this.loginError = error.message)
    );
  }

  signup() {
    this.router.navigate(["./as-page-signup"]);
  }

  forgotPW() {
    const email = this.loginForm.value.email;
    this.auth.resetPassword(email).then((pwResetted) => {
      this.pwWasResettedSuccessfully = true;
    });
  }
}
