"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVehiclesCurrentDriverId = exports.getVehiclesCurrentDriver = exports.isVehicleActivated = exports.parseIntoVehicleTypeFromTranslation = exports.vehicleTypesTranslatedToString = exports.translateVehicleType = exports.vehicleTypesToString = void 0;
const VehicleConstants_1 = require("../model/VehicleConstants");
const DriverDataFunctions_1 = require("./driver/DriverDataFunctions");
function vehicleTypesToString() {
    return Object.values(VehicleConstants_1.VEHICLE_TYPE);
}
exports.vehicleTypesToString = vehicleTypesToString;
/**
 * TODO: Refactor me into json Files and ngx-translate
 * @param vehicleType
 */
function translateVehicleType(vehicleType) {
    switch (vehicleType) {
        case VehicleConstants_1.VEHICLE_TYPE.BUS:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.BUS;
        case VehicleConstants_1.VEHICLE_TYPE.CAR:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.CAR;
        case VehicleConstants_1.VEHICLE_TYPE.MINIBUS:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.MINIBUS;
        case VehicleConstants_1.VEHICLE_TYPE.TRANSPORTER:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.TRANSPORTER;
        case VehicleConstants_1.VEHICLE_TYPE.TRUCK:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.TRUCK;
        case VehicleConstants_1.VEHICLE_TYPE.MEGATRUCK:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.MEGATRUCK;
        case VehicleConstants_1.VEHICLE_TYPE.JUMBO:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.JUMBO;
        case VehicleConstants_1.VEHICLE_TYPE.LORRY:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.LORRY;
        case VehicleConstants_1.VEHICLE_TYPE.ROAD_TRAIN:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.ROAD_TRAIN;
        case VehicleConstants_1.VEHICLE_TYPE.CURTAINSIDER:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.CURTAINSIDER;
        case VehicleConstants_1.VEHICLE_TYPE.TRAILER:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.TRAILER;
        case VehicleConstants_1.VEHICLE_TYPE.MEGA_TRAILER:
            return VehicleConstants_1.VEHICLE_TYPE_TRANSLATED.MEGA_TRAILER;
        default:
            return undefined;
    }
}
exports.translateVehicleType = translateVehicleType;
function vehicleTypesTranslatedToString() {
    return Object.values(VehicleConstants_1.VEHICLE_TYPE).map(type => translateVehicleType(type));
}
exports.vehicleTypesTranslatedToString = vehicleTypesTranslatedToString;
function parseIntoVehicleTypeFromTranslation(vehicleType) {
    switch (vehicleType) {
        case 'Bus':
            return VehicleConstants_1.VEHICLE_TYPE.BUS;
        case 'Tautliner':
            return VehicleConstants_1.VEHICLE_TYPE.CURTAINSIDER;
        case 'Mega-Sattelzugmaschine':
            return VehicleConstants_1.VEHICLE_TYPE.MEGATRUCK;
        case 'Jumbo':
            return VehicleConstants_1.VEHICLE_TYPE.JUMBO;
        case 'Sattelzugmaschine':
            return VehicleConstants_1.VEHICLE_TYPE.TRUCK;
        case 'LKW':
            return VehicleConstants_1.VEHICLE_TYPE.LORRY;
        case 'Lastzug':
            return VehicleConstants_1.VEHICLE_TYPE.ROAD_TRAIN;
        case 'Minibus':
            return VehicleConstants_1.VEHICLE_TYPE.MINIBUS;
        case 'Auto':
            return VehicleConstants_1.VEHICLE_TYPE.CAR;
        case 'Kleintransporter':
            return VehicleConstants_1.VEHICLE_TYPE.TRANSPORTER;
        default:
            console.error(`parseIntoVehicleTypeFromTranslation, no valid VEHICLE_TYPE`);
            return VehicleConstants_1.VEHICLE_TYPE.CURTAINSIDER;
    }
}
exports.parseIntoVehicleTypeFromTranslation = parseIntoVehicleTypeFromTranslation;
function isVehicleActivated(vehicle) {
    return !!(vehicle === null || vehicle === void 0 ? void 0 : vehicle.driver);
    // TODO: vehicle Modus use return vehicle.isActivated || vehicle.driver; (can only be either, set in companySettings)
}
exports.isVehicleActivated = isVehicleActivated;
function getVehiclesCurrentDriver(vehicle, drivers) {
    if (!(vehicle === null || vehicle === void 0 ? void 0 : vehicle.driver) || !(drivers === null || drivers === void 0 ? void 0 : drivers.length))
        return undefined;
    return DriverDataFunctions_1.getDriverById(vehicle.driver, drivers);
}
exports.getVehiclesCurrentDriver = getVehiclesCurrentDriver;
function getVehiclesCurrentDriverId(vehicle) {
    return vehicle === null || vehicle === void 0 ? void 0 : vehicle.driver;
}
exports.getVehiclesCurrentDriverId = getVehiclesCurrentDriverId;
