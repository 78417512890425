import { Pipe, PipeTransform } from '@angular/core';
import { HomeShiftType } from '../pages/home/HomeClasses';

@Pipe({
  name: 'translateFilter'
})
export class TranslateFilterPipes implements PipeTransform {
  transform(filterType: string, ...args) {
    switch (filterType) {
      case HomeShiftType.today:
		  return 'TODAY';
      case HomeShiftType.tomorrow:
		  return 'TOMORROW';
      case HomeShiftType.currentWeek:
		  return 'CURRENT_WEEK';
      case HomeShiftType.nextWeek:
		  return 'NEXT_WEEK';
      default:
		  return 'Unknown';
    }
  }
}

@Pipe({
  name: 'translateJobsLength'
})
export class TranslateJobsLengthPipes implements PipeTransform {
  transform(length: number, ...args) {
    if (length === 1) {
		return 'JOB';
    } else {
		return 'JOBS';
    }
  }
}
