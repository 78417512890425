import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, } from "@angular/core";
import { FlutaroLike, FlutaroPost, PostChange, PostComment } from "./post";
import { isPostLiked } from "./PostFunctions";
import { ActionSheetController, AlertController, ModalController, } from "@ionic/angular";
import { PostProvider } from "./post.provider";
import { LikeService } from "../like/like.service";
import { CommentService } from "../comment/comment.service";
import { FlutaroLocationRating } from "../location/Location";
import { FlutaroGroup } from "../groups/FlutaroGroup";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";

@Component({
  selector: "app-post",
  templateUrl: "./post.page.html",
  styleUrls: ["./post.page.scss"],
})
export class PostPage implements OnChanges, OnInit {
  @Input() isComment: boolean;
  @Input() post: FlutaroPost | PostComment | FlutaroLocationRating;
  @Input() group: FlutaroGroup;
  @Output() postDeleted: EventEmitter<FlutaroPost | PostComment> =
    new EventEmitter<FlutaroPost | PostComment>();
  @Output() postEdited: EventEmitter<FlutaroPost | PostComment> =
    new EventEmitter<FlutaroPost | PostComment>();
  @Input() parentPost: FlutaroPost | PostComment;
  @Input() commentDepth: number;
  @Input() user: FbStoreUserProfile;
  @Input() readonlyUid: string;
  @Input() isModal: boolean;
  @Output() lvl3CommentChange: EventEmitter<PostChange> =
    new EventEmitter<PostChange>();
  isLiked: boolean;
  isCommentsOpened: boolean;
  newComment: PostComment;
  isReadOnly: boolean;
  actionSheet: any;
  comments: PostComment[] = [];

  constructor(
    public actionSheetController: ActionSheetController,
    public feedProvider: PostProvider,
    public alertController: AlertController,
    public modalController: ModalController,
    private likeService: LikeService,
    private commentService: CommentService,
    private changeRef: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes) {
    if ((!changes["post"] && !this.post) || (!changes["user"]! && !this.user))
      return;
    if (!this.post.id) {
      console.error(`No Post-Id in Post-Page. Aborting.`);
      return;
    }

    let changeOfCommentLvl3 = <any>this.post;
    if (changeOfCommentLvl3.added) this.post = changeOfCommentLvl3.comment;
    this.isLiked = isPostLiked(this.post, this.user.uid);
    this.isReadOnly = this.readonlyUid !== this.user.uid;
  }

  ngOnInit() {
    if (this.post && this.user) {
      this.isLiked = isPostLiked(this.post, this.user.uid);
    }
  }

  openCloseComments() {
    if (!this.isCommentsOpened) {
      this.openComments();
    } else {
      this.isCommentsOpened = false;
      this.changeRef.detectChanges();
    }
  }

  async openComments() {
    this.comments =
      this.post.commentsAmount > 0
        ? await this.commentService.getCommentsForPost(
          this.post.id,
          this.post.groupId
        )
        : [];
    this.createNewEmptyComment();
    this.isCommentsOpened = true;
    this.changeRef.detectChanges();
  }

  createNewEmptyComment() {
    this.newComment = new PostComment(
      this.post.id,
      this.user.uid,
      this.user.nickname,
      this.user.photoURL,
      this.post.type
    );
  }

  async addComment(change: PostChange) {
    this.createNewEmptyComment();
    if (this.commentDepth === 3) {
      this.lvl3CommentChange.emit(change);
      return;
    }
    // New Comment - edit/delete handled in editPostComment
    if (!change.before && change.after) {
      ++this.post.commentsAmount;
      this.comments.push(<PostComment>change.after);
    }
    this.changeRef.detectChanges();
  }

  deleteComment(comment: PostComment) {
    this.postDeleted.emit(comment);
    this.changeRef.detectChanges();
  }

  emitGroupPostEdit(post) {
    this.postEdited.emit(post);
  }

  emitGroupPostDelete(post) {
    this.postDeleted.emit(post);
  }

  editPostComment(commentChange: PostChange) {
    if (!commentChange.after) {
      --this.post.commentsAmount;
      this.comments = this.comments.filter(
        (comm) => comm.id !== commentChange.before.id
      );
      console.log(`Comment deleted in editPostComment`);
      return;
    }
    const comment = <PostComment>commentChange.after;
    this.commentService.editComment(comment);
    this.newComment = new PostComment(
      this.post.id,
      this.user.uid,
      this.user.nickname,
      this.user.photoURL,
      this.post.type
    );
    this.comments = this.comments.filter((comm) => comm.id !== comment.id);
    this.comments.push(comment);
    this.changeRef.detectChanges();
  }

  toggleHeart() {
    const userLike = new FlutaroLike(
      this.user.uid,
      this.user.nickname,
      this.user.photoURL
    );
    if (isPostLiked(this.post, this.user.uid)) {
      this.post.likes = this.post.likes.filter(
        (like) => like.uid !== userLike.uid
      );
    } else {
      this.post.likes.push(userLike);
    }
    this.isLiked = !this.isLiked;
    this.likeService.updateLiked(this.post, this.isLiked, userLike);
  }

  showLikes() {
    this.likeService.showLikes(this.post.likes, this.user, "LIKES_VIEW");
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss(null);
  }
}
