import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutModule } from "./about/about.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule, AboutModule
  ],
  exports: [AboutModule]
})
export class FlutaroPagesModule {
}
