"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressPosition = exports.Address = exports.Position = void 0;
const IElement_1 = require("./IElement");
class Position extends IElement_1.IElement {
    constructor() {
        super(...arguments);
        this.latitude = 0;
        this.longitude = 0;
    }
}
exports.Position = Position;
class Address extends Position {
    constructor() {
        super(...arguments);
        this.zip = '';
        this.city = '';
        this.country = '';
    }
}
exports.Address = Address;
class AddressPosition {
    constructor() {
        this.latitude = 0;
        this.longitude = 0;
        this.zip = '';
        this.city = '';
        this.country = '';
    }
}
exports.AddressPosition = AddressPosition;
