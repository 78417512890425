import { JobWrapper } from "@flutaro/package/lib/model/Job";

export const HomeShiftType = {
  today: "today",
  tomorrow: "tomorrow",
  currentWeek: "currentWeek",
  nextWeek: "nextWeek",
};

export class JobDivision {
	today: JobDivisionCategory = new JobDivisionCategory();
	tomorrow: JobDivisionCategory = new JobDivisionCategory();
	currentWeek: JobDivisionCategory = new JobDivisionCategory();
	nextWeek: JobDivisionCategory = new JobDivisionCategory();
}

export class JobDivisionCategory {
	jobs: JobWrapper[] = [];
	firstJob: JobWrapper;
	lastJob: JobWrapper;
}