import { NgModule } from '@angular/core';
import { LikeService } from "./like.service";
import { LikesModalComponent } from './likes-modal/likes-modal.component';
import { ShareModule } from "../../share.module";


@NgModule({
	declarations: [LikesModalComponent],
	imports: [
		ShareModule
	],
	providers: [LikeService]
})
export class LikeModule {
}
