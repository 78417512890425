<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">{{profile.nickname}}</ion-title>
        <ion-button class="userPageMoreButton" slot="primary">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button> <!-- TODO: Open Menu for block/report/...-->
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-item class="card" [color]="'colorTransparent'" lines="none">
        <div class="header">
            <div class="avatar">
                <ion-img [src]="profile.photoURL"></ion-img>
            </div>
        </div>
        <div class="floatWrapper">
            <div class="following">
                <p class="value">{{userFollowing}}</p>
                <p class="label" translate>FOLLOWING</p>
            </div>
            <div class="following">
                <p class="value">{{userFollowers}}</p>
                <p class="label" translate>FOLLOWERS</p>
            </div>
            <div class="following">
                <p class="value">{{userPostsCount}}</p>
                <p class="label" translate>POSTS</p>
            </div>
        </div>
    </ion-item>

    <ion-item class="card" [color]="'colorTransparent'" lines="none">
        <p class="authorStatus">{{profile.status}}</p>
    </ion-item>

    <ion-item class="card" [color]="'colorTransparent'" lines="none">
        <ion-button (click)="changeFollowing()" class="userPageFollowUnfollowButton" color="flutaroMain" expand="full">
            {{(isFollower ? 'BUTTON_UNFOLLOW' : 'BUTTON_FOLLOW') | translate}}
        </ion-button>
        <ion-button class="userPageButton" expand="full" color="dark" translate>MESSAGE</ion-button>
    </ion-item>

    <div class="card">
        <app-user-vehicle [vehicleInformation]="profile.vehicleInformation"
                          [userUid]="user.uid"
                          [readonly]="true">
        </app-user-vehicle>
    </div>

    <ion-row class="listHeaderRowUserPage">
        <div style="width: 100%; text-align: center;">
            <p class="listHeaderText">
                <ion-icon class="ionIconListHeader" name="create"></ion-icon>
                {{'LATEST_ACTIVITIES' | translate}}
            </p>
        </div>
    </ion-row>

    <div *ngFor="let post of latestPosts"
         [ngClass]="{
         'postFeed': post.type === 'POST',
         'locationRatingFeed': post.type === 'RATING',
         'webPagePreview': (post.type === 'POST' || post.type === 'COMMENT') && post.pagePreview
         }">
        <app-post [post]="post"
                  [user]="user"
                  [commentDepth]="1"
                  [readonlyUid]="post.authorId">
        </app-post>
    </div>
</ion-content>
