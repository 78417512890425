"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areLocationsTheSame = exports.prepareAddressLineForGeocoding = void 0;
function prepareAddressLineForGeocoding(addressLine) {
    return addressLine.toLowerCase().replace(/,/g, ' ').replace(/\s\s+/g, ' ').trim();
}
exports.prepareAddressLineForGeocoding = prepareAddressLineForGeocoding;
function areLocationsTheSame(location1, location2) {
    return location1.latitude === location2.latitude && location1.longitude === location2.longitude;
}
exports.areLocationsTheSame = areLocationsTheSame;
