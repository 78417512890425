"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationType = exports.Relation = void 0;
const IElement_1 = require("./IElement");
class Relation extends IElement_1.IdentifiableElement {
    constructor() {
        super(...arguments);
        this.zips = [];
        this.description = '';
        this.color = '';
        // Used to determine that DELETE should be used in Http-Provider for Delete-Operation (will be soon in Data-Services like that)
        this.__type = exports.RelationType;
        this.mode = 'PickUp'; // PickUp, Delivery(not yet implemented), PickUpAndDelivery - for capacity component and newJob-Sidenav
        // TODO: add timetable coloring mode: pickup or delivery
        this.darkTextColor = false;
        this.pickUpCountry = 'de';
        this.pickUpZips = [];
        this.deliveryCountry = 'de';
        this.deliveryZips = [];
        this.assignedVehicleIDs = []; // If including vehicleIds activates manual mode
    }
}
exports.Relation = Relation;
exports.RelationType = 'Relation';
