import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPageComponent } from "./user-page/user-page.component";
import { IonicModule } from "@ionic/angular";
import { PostPageModule } from "../post/post.module";
import { UserVehicleComponent } from "./user-vehicle/user-vehicle.component";
import { FormsModule } from "@angular/forms";
import { ComponentsModule } from "../../components/components.module";
import { AvatarModule } from "../avatar/avatar.module";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
	declarations: [UserPageComponent, UserVehicleComponent],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		PostPageModule,
		ComponentsModule,
		AvatarModule,
		TranslateModule
	],
	exports: [UserPageComponent, UserVehicleComponent],
	providers: []
})
export class ProfileModule {
}
