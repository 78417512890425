import { FlutaroPost, PostBasics } from "./post";
import { transformFeedForApp } from "../../services/FirestoreFunctions";
import firebase from "firebase/compat/app";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { DocumentData } from "@angular/fire/compat/firestore";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export function isPostLiked(post: PostBasics, uid: string) {
  return post.likes.filter((uidObj) => uidObj.uid === uid).length === 1;
}

export function basicPostFirestoreHandler(
  postRef: DocumentSnapshot<DocumentData>
) {
  return transformFeedForApp(postRef);
}

export function setPostsUserAttributes(
  post: FlutaroPost,
  user: FbStoreUserProfile
) {
  post.authorId = user.uid;
  post.authorName = user.nickname;
  post.authorPhoto = user.photoURL;
}
