<ion-item color="colorFeedTextarea" lines="none" class="input-has-focus"
          class="ionIteamNoPaddingShadow">
    <ion-textarea class="input-has-focus"
                  [placeholder]="(post.type === 'POST' ? 'CREATE_POST' : 'CREATE_COMMENT') | translate"
                  [(ngModel)]="post.content" [rows]="8"
                  [autoGrow]="false"
                  [spellcheck]="true"
                  [ngClass]="post.type !== 'POST' ? 'textareaDark' : ''">
    </ion-textarea>
</ion-item>

<app-picture [(photo)]="post.photo" [pictureOnlyMode]="true" [(showPhotoPicker)]="showPhotoPicker"
             [pictureType]="'POST-IMAGE'"
             [uid]="post.authorId">
</app-picture>

<app-webpage-preview [(webPagePreview)]="post.pagePreview"
                     [(showWebPagePreviewEdit)]="showWebPagePreviewEdit">
</app-webpage-preview>

<post-location [isRating]="false" [readOnly]="false" [(location)]="post.location"
               *ngIf="showMapPageEdit">
</post-location>
<post-location [isRating]="false" [readOnly]="true" [location]="post.location" *ngIf="!showMapPageEdit"></post-location>

<app-tag-friends *ngIf="showTagFriends" [(taggedFriends)]="post.taggedFriends" [isEdit]="true"
                 [user]="user"></app-tag-friends>
<app-tag-friends *ngIf="!showTagFriends" [(taggedFriends)]="post.taggedFriends" [isEdit]="false"
                 [user]="user"></app-tag-friends>

<ion-toolbar>
    <ion-buttons slot="secondary">
        <ion-button (click)="activatePhotoPicker()">
            <ion-icon slot="icon-only" name="camera-outline"></ion-icon>
        </ion-button>
    </ion-buttons>

    <ion-buttons slot="secondary">
        <ion-button (click)="editPagePreviewLink()">
            <ion-icon slot="icon-only" name="link-outline"></ion-icon>
        </ion-button>
    </ion-buttons>

    <ion-buttons slot="secondary">
        <ion-button (click)="showMapLocationSearch()">
            <ion-icon slot="icon-only" name="map-outline"></ion-icon>
        </ion-button>
    </ion-buttons>

    <ion-buttons slot="secondary">
        <ion-button (click)="tagFriends()">
            <ion-icon slot="icon-only" name="person-add-outline"></ion-icon>
        </ion-button>
    </ion-buttons>

    <!--TODO: Add functionality and button -->
    <ion-buttons slot="primary" *ngIf="false">
        <ion-button>
            <ion-icon slot="icon-only" name="ellipsis-horizontal"></ion-icon>
        </ion-button>
    </ion-buttons>

    <ion-buttons slot="end" *ngIf="post.type === 'COMMENT'">
        <ion-button class="flutaroSendComment" color="light" (click)="sendComment()">
            <ion-icon [color]="'colorfff'" name="send"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>
