import { WebPagePreview } from "../../model/WebPagePreview";

export const PostTypes = {
  POST: "POST",
  INVITE: "INVITE",
  RATING: "RATING",
  COMMENT: "COMMENT",
  GROUP_COMMENT: "GROUP_COMMENT",
  GROUP_POST: "GROUP",
};

export class SocialElementBasics {
  id: string;
  timestamp: Date;
  lastModified: Date;
  authorId: string;
  authorPhoto: string;
  authorName: string;
  content: string = "";
}

export class FlutaroLike {
  uid: string;
  nickname: string;
  photoURL: any;

  constructor(userUid, nickname, userPhoto) {
    this.uid = userUid;
    this.nickname = nickname;
    this.photoURL = userPhoto;
  }
}

export class PostBasics extends SocialElementBasics {
  type: string;
  commentsAmount: number = 0;
  location: FlutaroLocation;
  likes: FlutaroLike[] = [];
  photo: string;
  groupId: string;
}

export class FlutaroPost extends PostBasics implements BasicPostingInterface {
  taggedFriends: TaggedFriend[] = [];
  isPrivate: boolean = false;
  pagePreview: WebPagePreview;
  type: string = PostTypes.POST;
}

export class FlutaroGroupPost extends FlutaroPost {
  groupId: string;
  type: string = PostTypes.GROUP_POST;
}

export class FlutaroLocation {
  description: string;
  lat: number;
  lon: number;
  place_id;
}

export interface BasicPostingInterface {
  id: string;
  content: string;
  authorId: string;
  authorPhoto: string;
  authorName: string;
  likes: FlutaroLike[];
  timestamp: Date;
  photo: string;
  lastModified: Date;
  isPrivate: boolean;
  pagePreview: WebPagePreview;
  type: string;
  commentsAmount: number;
  location: FlutaroLocation;
}

export class PostComment extends PostBasics {
  postId: string; // Id of Post or Comment for which the Comment is meant for. Use to identify correct Post or Comment for filtering comments (add/edit)
  postType: string; // Comment, POST, Rating

  constructor(
    postId: string,
    authorUid: string,
    authorName: string,
    avatar: string,
    postType: string
  ) {
    super();
    this.postId = postId;
    this.authorId = authorUid;
    this.authorName = authorName;
    this.authorPhoto = avatar;
    this.type = PostTypes.COMMENT;
    this.postType = postType;
    this.timestamp = new Date();
    delete this.location;
  }
}

export class TaggedFriend extends FlutaroLike {}

export class PostChange {
  before: PostComment | FlutaroPost;
  after: PostComment | FlutaroPost;
}
