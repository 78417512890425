import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, } from "@angular/core";
import { ProfilePublicService } from "../../profile/profile.public.service";
import { TaggedFriend } from "../post";
import { GroupMember } from "../../groups/FlutaroGroup";
import { FbStoreUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { UserProfilePublic } from "@flutaro/package/lib/model/UserProfile";

@Component({
  selector: "app-tag-friends",
  templateUrl: "./tag-friends.component.html",
  styleUrls: ["./tag-friends.component.scss"],
})
export class TagFriendsComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() dividerTitle: string;
  @Input() taggedFriends: TaggedFriend[] | GroupMember[];
  @Output() taggedFriendsChange: EventEmitter<TaggedFriend[] | GroupMember[]> =
    new EventEmitter<TaggedFriend[] | GroupMember[]>();
  @Input() user: FbStoreUserProfile;
  @Input() newTaggedFriends: TaggedFriend[] | GroupMember[];
  @Output() newTaggedFriendsChange: EventEmitter<TaggedFriend[] | GroupMember[]> = new EventEmitter<TaggedFriend[] | GroupMember[]>();
  searchQuery = "";
  userSearchList = [];

  constructor(
    private profileProvider: ProfilePublicService,
    private changeDetection: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  addTaggedFriend(profile: UserProfilePublic) {
    if (this.newTaggedFriends) {
      this.newTaggedFriends.push(
        new TaggedFriend(profile.uid, profile.nickname, profile.photoURL)
      );
      this.newTaggedFriendsChange.emit(this.newTaggedFriends);
    } else {
      this.taggedFriends.push(
        new TaggedFriend(profile.uid, profile.nickname, profile.photoURL)
      );
      this.taggedFriendsChange.emit(this.taggedFriends);
    }
    this.clearSearch();
  }

  deleteTaggedFriend(taggedFriend: TaggedFriend) {
    this.taggedFriends = this.taggedFriends.filter(
      (friend) => friend.uid !== taggedFriend.uid
    );
    this.taggedFriendsChange.emit(this.taggedFriends);
    if (this.newTaggedFriends) {
      this.newTaggedFriends = this.newTaggedFriends.filter(
        (friend) => friend.uid !== taggedFriend.uid
      );
      this.newTaggedFriendsChange.emit(this.newTaggedFriends);
    }
  }

  clearSearch() {
    this.userSearchList = [];
    this.searchQuery = "";
    this.changeDetection.detectChanges();
  }

  async searchUsers($event) {
    let keyword = this.searchQuery;
    if ($event.data) {
      keyword += $event.data;
    } else {
      keyword = keyword.substring(0, keyword.length - 1);
    }
    if (!keyword) {
      return;
    }
    this.searchQuery = keyword;
    this.userSearchList = await this.profileProvider.searchUsers(keyword);
    this.userSearchList = this.userSearchList.filter(
      (user) =>
        this.taggedFriends.filter((friend) => friend.uid === user.uid)
          .length === 0
    );
    this.userSearchList = this.userSearchList.filter(
      (user) => this.user.uid !== user.uid
    );
    if (this.newTaggedFriends)
      this.userSearchList = this.userSearchList.filter(
        (user) =>
          this.newTaggedFriends.filter((friend) => friend.uid === user.uid)
            .length === 0
      );
    this.changeDetection.detectChanges();
  }
}
