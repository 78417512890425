import { Injectable } from "@angular/core";
import { JobService } from "./job.service";
import { filterJobsForDivisionType, filterJobsForFilterType, } from "../../jobs/JobFunctions";
import { JobWrapper } from "@flutaro/package/lib/model/Job";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JobsFilterService {
  $filteredJobs: BehaviorSubject<JobWrapper[]> = new BehaviorSubject<JobWrapper[]>([]);
  activeDateFilter: string;
  lastFilter: JobsFilterSettings = new JobsFilterSettings();
  jobPagePushViewIsOpen: boolean = false;

  constructor(private jobProvider: JobService) {
    this.synchronizeFilteredJobsWithProviderData();
  }

  filterData(filterModus?: JobsFilterSettings) {
    if (!filterModus && !this.lastFilter) {
      this.synchronizeFilteredJobsWithProviderData();
      return;
    }
    if (filterModus) {
      this.lastFilter = filterModus;
    }
    if (!filterModus) {
      filterModus = this.lastFilter;
    }
    let filteredJobs = filterJobsForFilterType(this.jobProvider.$data.getValue(), filterModus.filterType);
    if (filterModus.divisionType) {
      filteredJobs = filterJobsForDivisionType(
        filteredJobs,
        filterModus.divisionType
      );
    }
    this.$filteredJobs.next(filteredJobs);
  }

  resetFilter() {
    this.synchronizeFilteredJobsWithProviderData();
    this.activeDateFilter = null;
    this.lastFilter = new JobsFilterSettings();
    if (this.jobPagePushViewIsOpen) {
      // this.app.getActiveNav().pop();
      this.jobPagePushViewIsOpen = false;
    }
  }

  showJobsPageWithFilterActive(filter: string, divisionType) {
    let filterModus = new JobsFilterSettings();
    filterModus.filterType = filter;
    filterModus.divisionType = divisionType;
    this.activeDateFilter = divisionType;
    this.filterData(filterModus);
    this.jobPagePushViewIsOpen = true;
  }

  synchronizeFilteredJobsWithProviderData() {
    this.$filteredJobs.next(this.jobProvider.$data.getValue());
  }
}

class JobsFilterSettings {
  filterType: string = '';
  divisionType: string = '';
}
