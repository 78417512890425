"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirestoreCollection = exports.PostTypes = void 0;
var PostTypes;
(function (PostTypes) {
    PostTypes["POST"] = "POST";
    PostTypes["INVITE"] = "INVITE";
    PostTypes["RATING"] = "RATING";
    PostTypes["COMMENT"] = "COMMENT";
    PostTypes["GROUP_COMMENT"] = "GROUP_COMMENT";
    PostTypes["GROUP_POST"] = "GROUP";
})(PostTypes = exports.PostTypes || (exports.PostTypes = {}));
var FirestoreCollection;
(function (FirestoreCollection) {
    FirestoreCollection["USERS"] = "users";
    FirestoreCollection["PROFILES"] = "profiles";
    FirestoreCollection["COMMENTS"] = "comments";
    FirestoreCollection["SOCIAL"] = "social";
})(FirestoreCollection = exports.FirestoreCollection || (exports.FirestoreCollection = {}));
