import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SignupPage } from './signup.page';
import { TranslateModule } from "@ngx-translate/core";

const routes: Routes = [
	{
		path: 'as-page-signup',
		component: SignupPage
	}
];

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, IonicModule, RouterModule.forChild(routes), TranslateModule],
	declarations: [SignupPage],
	exports: [SignupPage]
})
export class SignupPageModule {
}
