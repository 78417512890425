"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstPickUp = exports.getLastDestination = exports.jobsLastDelivery = exports.getJobsLastDeliveryDate = exports.jobsFirstPickup = exports.getJobsFirstPickupDate = void 0;
function getJobsFirstPickupDate(job) {
    return getFirstPickUp(job).plannedDate;
}
exports.getJobsFirstPickupDate = getJobsFirstPickupDate;
function jobsFirstPickup(job) {
    return getFirstPickUp(job);
}
exports.jobsFirstPickup = jobsFirstPickup;
function getJobsLastDeliveryDate(job) {
    return getLastDestination(job).plannedDate;
}
exports.getJobsLastDeliveryDate = getJobsLastDeliveryDate;
function jobsLastDelivery(job) {
    return getLastDestination(job);
}
exports.jobsLastDelivery = jobsLastDelivery;
function getLastDestination(job) {
    return job.destinations[job.destinations.length - 1];
}
exports.getLastDestination = getLastDestination;
function getFirstPickUp(job) {
    return job.destinations[0];
}
exports.getFirstPickUp = getFirstPickUp;
