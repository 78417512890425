import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsPageComponent } from './events-page/events-page.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { ShowEventComponent } from './show-event/show-event.component';
import { EventsModalComponent } from './events-modal/events-modal.component';


@NgModule({
  declarations: [EventsPageComponent, CreateEventComponent, ShowEventComponent, EventsModalComponent],
  imports: [
    CommonModule
  ]
})
export class EventsModule {
}
