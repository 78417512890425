"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobPairing = exports.FLUT_PositionData = exports.FQTPositiondata = exports.GeneralPositionData = void 0;
const FLUT_Position_1 = require("./FLUT_Position");
class GeneralPositionData extends FLUT_Position_1.FLUT_Position {
    constructor() {
        super(...arguments);
        this.latitude = 0;
        this.longitude = 0;
    }
}
exports.GeneralPositionData = GeneralPositionData;
class FQTPositiondata extends GeneralPositionData {
    constructor() {
        super(...arguments);
        this.licensePlate = ''; // assigned Vehicle
        this.customerId = ''; // transmitted by ExternalJobImport - shipmentId
        this.jobId = ''; // job.backendId
        this.etaDate = ""; // UTC full-time See: RFC 3339 Date and Time on the Internet: Timestamps - either from Pickup or Delivery (planned Dates)
        this.pickupLocationId = ""; // BU-Number/description for BLG
        this.deliveryLocationId = ""; // BU-Number/description for BLG
    }
}
exports.FQTPositiondata = FQTPositiondata;
class FLUT_PositionData extends FQTPositiondata {
    constructor() {
        super(...arguments);
        this.sentToCustomerAPI = false;
        this.driverUID = ''; // Flutaro internal ID for Firebase Identification
        this.vehicleId = ''; // vehicle.backendId of assigned Driver for Job
        this.company = ''; // WebApp User / Customer
        this.jobCustomerId = ''; // Id of job, that customer has choosen (Different from job.backendId)
        // ETA-Attributes
        this.customerPickupLocationId = ""; // BU-Number/description for BLG
        this.customerDeliveryLocationId = ""; // BU-Number/description for BLG
        this.jobsDeliveryPlannedDate = "";
        this.extras = {
            pairing: ''
        };
    }
}
exports.FLUT_PositionData = FLUT_PositionData;
var JobPairing;
(function (JobPairing) {
    JobPairing["ON"] = "ON";
    JobPairing["OFF"] = "OFF";
})(JobPairing = exports.JobPairing || (exports.JobPairing = {}));
