"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areJobCostsDifferentAfterRecalculation = exports.filterRecalculatedJobsForJobsWithCostChangesOnly = exports.resetJobCosts = exports.getClosestJobAfterDateByLastDestinationsPlannedDate = exports.getClosestJobBeforeDateByLastDestinationsPlannedDate = exports.getContractorsRateForJobsDate = void 0;
const date_fns_1 = require("date-fns");
const DestinationFunctions_1 = require("../DestinationFunctions");
const DataDateFunctions_1 = require("../DataDateFunctions");
const ContractorFunctions_1 = require("../ContractorFunctions");
const Costs_1 = require("../../model/costs/Costs");
const LocationFunctions_1 = require("../LocationFunctions");
/**
 * Get the Contractors rate based by rates date based by jobs plannedStartDate to detect correct costs settings.
 * @param {JobWrapper} job
 * @param {Contractor} contractor
 */
function getContractorsRateForJobsDate(job, contractor) {
    var _a;
    if (!contractor || !((_a = contractor.rates) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    else {
        return ContractorFunctions_1.getContractorRateForDate(DestinationFunctions_1.getJobsFirstPickupDate(job), contractor);
    }
}
exports.getContractorsRateForJobsDate = getContractorsRateForJobsDate;
function getClosestJobBeforeDateByLastDestinationsPlannedDate(jobs, date) {
    const jobsBeforeDate = jobs.filter((job) => DataDateFunctions_1.isBeforeOrSame(DestinationFunctions_1.getJobsLastDeliveryDate(job), date));
    if (jobsBeforeDate.length === 0) {
        return null;
    }
    const dateClosest = date_fns_1.closestTo(date, jobsBeforeDate.map((job) => DestinationFunctions_1.getJobsLastDeliveryDate(job)));
    if (!dateClosest)
        return null;
    return jobsBeforeDate.filter((job) => date_fns_1.isEqual(DestinationFunctions_1.getJobsLastDeliveryDate(job), dateClosest))[0];
}
exports.getClosestJobBeforeDateByLastDestinationsPlannedDate = getClosestJobBeforeDateByLastDestinationsPlannedDate;
function getClosestJobAfterDateByLastDestinationsPlannedDate(jobs, date) {
    const jobsAfterDate = jobs.filter((job) => date_fns_1.isAfter(DestinationFunctions_1.getJobsFirstPickupDate(job), date));
    if (!jobsAfterDate.length) {
        return null;
    }
    const dateClosest = date_fns_1.closestTo(date, jobsAfterDate.map((job) => DestinationFunctions_1.getJobsFirstPickupDate(job)));
    if (!dateClosest)
        return null;
    return jobsAfterDate.filter((job) => date_fns_1.isEqual(DestinationFunctions_1.getJobsFirstPickupDate(job), dateClosest))[0];
}
exports.getClosestJobAfterDateByLastDestinationsPlannedDate = getClosestJobAfterDateByLastDestinationsPlannedDate;
function resetJobCosts(job) {
    job.costCalculation = new Costs_1.JobCosts(job.costCalculation.revenue);
}
exports.resetJobCosts = resetJobCosts;
function filterRecalculatedJobsForJobsWithCostChangesOnly(costData) {
    costData.recalculatedJobs = costData.recalculatedJobs.filter(recalculatedJob => {
        const originalJob = costData.driversJobs_OriginalBackup.find(originalJob => originalJob.backendId === recalculatedJob.backendId);
        if (!originalJob)
            return true;
        return areJobCostsDifferentAfterRecalculation(recalculatedJob, originalJob);
    });
}
exports.filterRecalculatedJobsForJobsWithCostChangesOnly = filterRecalculatedJobsForJobsWithCostChangesOnly;
function areJobCostsDifferentAfterRecalculation(recalculatedJob, originalJob) {
    var _a, _b, _c, _d;
    if (!((_b = (_a = originalJob === null || originalJob === void 0 ? void 0 : originalJob.costCalculation) === null || _a === void 0 ? void 0 : _a.emptyKmAddress) === null || _b === void 0 ? void 0 : _b.latitude))
        return true;
    const sameCosts = originalJob.costCalculation.totalCosts === recalculatedJob.costCalculation.totalCosts;
    const sameProfit = originalJob.costCalculation.profit === recalculatedJob.costCalculation.profit;
    const sameMinusCorrections = ((_c = originalJob.costCalculation.minusPositionCorrections) === null || _c === void 0 ? void 0 : _c.length) === ((_d = recalculatedJob.costCalculation.minusPositionCorrections) === null || _d === void 0 ? void 0 : _d.length);
    const sameEmptyKmAddress = LocationFunctions_1.areLocationsTheSame(originalJob.costCalculation.emptyKmAddress, recalculatedJob.costCalculation.emptyKmAddress);
    return !sameCosts || !sameMinusCorrections || !sameProfit || !sameEmptyKmAddress;
}
exports.areJobCostsDifferentAfterRecalculation = areJobCostsDifferentAfterRecalculation;
