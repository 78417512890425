import { Component, OnInit } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AppAuthService } from "../auth/app-auth.service";
import { FlutaroUserProfile } from "@flutaro/package/lib/model/AuthClasses";
import { UserProfileProvider } from "../user/user.profile.provider";
import { ProfilePublicService } from "../social/profile/profile.public.service";
import { TranslateService } from "@ngx-translate/core";
import { AppInternationalizationService } from "../services/app.internationalization.service";
import { flutaroCloneObject } from "../AppFunctions";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AppLanguages } from "@flutaro/package/lib/model/AppClasses";
import { generateKeywordsForName } from "@flutaro/package/lib/functions/db/UserProfileFunctions";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.page.html",
  styleUrls: ["./edit-profile.page.scss"],
})
export class EditProfilePage implements OnInit {
  userProfile: FlutaroUserProfile;
  password: string;
  newPassword: string;
  changePassword: boolean;
  busy: boolean = false;

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private router: Router,
    private alertController: AlertController,
    private auth: AppAuthService,
    private userProfileProvider: UserProfileProvider,
    private profile: ProfilePublicService,
    private translate: TranslateService,
    private languageService: AppInternationalizationService
  ) {
    this.userProfile = flutaroCloneObject(this.userProfileProvider.$userProfile.getValue());
  }

  ngOnInit() {
  }

  logout() {
  }

  storePasswordChange() {
  }

  changeAppLanguage(languageCode: AppLanguages) {
    console.log(`Changing App-Language to languageCode: ${languageCode}`);
    this.languageService.changeAppLanguage(<AppLanguages>languageCode);
  }

  async presentAlert(title: string, content: string) {
    const alert = await this.alertController.create({
      header: title,
      message: content,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async updateDetails() {
    this.busy = true;

    if (
      this.userProfile.nickname !==
      this.userProfileProvider.$userProfile.getValue().nickname
    ) {
      this.userProfile.keywords = generateKeywordsForName(this.userProfile.nickname);
    }

    await this.userProfileProvider.editUserInFBDB(this.userProfile);
    this.profile.updatePublicProfile(this.userProfile);

    this.password = "";
    this.newPassword = "";
    this.busy = false;

    await this.presentAlert(
      this.translate.instant("PROFILE_UPDATE_SAVED"),
      this.translate.instant("PROFILE_UPDATED_SUCCESS_MESSAGE")
    );
  }
}
