import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { Camera, CameraResultType } from "@capacitor/camera";
import { ImageOptions, Photo } from "@capacitor/camera/dist/esm/definitions";
import { AngularFireStorage } from "@angular/fire/compat/storage";

@Component({
  selector: "app-picture",
  templateUrl: "./picture.component.html",
  styleUrls: ["./picture.component.scss"],
})
export class PictureComponent implements OnChanges {
  @ViewChild("profilePicture") profilePicture: ElementRef;
  @Input() photo: string; // URL
  @Input() uid: string; // UID of User - for Photo
  @Output() photoChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() title: string;
  @Input() pictureOnlyMode: boolean; // Picturemode = ON means that the Component will be used to display the picture only.
  @Input() showPhotoPicker: boolean;
  @Input() pictureType: string;
  @Input() isNativeApp: boolean;
  @Output() showPhotoPickerChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() itemColor: string;

  constructor(private afStorage: AngularFireStorage) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["showPhotoPicker"] && this.showPhotoPicker) {
      this.openPhotoPicker();
    }
  }

  openPhotoPicker() {
    this.pickImage();
  }

  openPWAPhotoPicker() {
    if (this.profilePicture == null) {
      return;
    }
    this.profilePicture.nativeElement.click();
  }

  async pickImage() {
    const options: ImageOptions = {
      quality: 100,
      resultType: CameraResultType.Uri,
      allowEditing: true,
      saveToGallery: true,
      webUseInput: true,
    };
    const photo: Photo = await Camera.getPhoto(options);
    console.log(`Received Picture from Camera-GetPicture`);
    let blob = await fetch(photo.webPath).then((r) => r.blob());
    //const pictureFile: File = await this.http.get<File>(photo.webPath).toPromise();
    this.manipulateImageAndUpload(<any>blob);
  }

  manipulateImageAndUpload(imageFile: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = (event) => {
      const img = new Image();
      img.src = <string>event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        elem.width = 1200; // 800px width as current maximum
        const scaleFactor = elem.width / img.width;
        elem.height = img.height * scaleFactor;
        console.log(`Scale factor ${scaleFactor}`);
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, elem.width, elem.height);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], new Date().toISOString(), {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            console.log(`Picture-File resized to ${elem.height}x${elem.width}`);
            this.uploadFile(file);
          },
          "image/jpeg",
          0.6
        );
      };
      reader.onerror = (error) => console.log(error);
    };
  }

  uploadPicture() {
    if (this.profilePicture == null || !this.uid) {
      console.log(`Cant upload picture: uid or picture missing.`);
      return;
    }

    const fileList: FileList = this.profilePicture.nativeElement.files;

    if (fileList && fileList.length > 0) {
      const imageData: File = fileList[0];
      this.manipulateImageAndUpload(imageData);
      return;
    }
  }

  private emitShowPhotoPickerChange() {
    if (this.showPhotoPicker) {
      this.showPhotoPicker = false;
      this.showPhotoPickerChange.emit(this.showPhotoPicker);
    }
  }

  uploadFile(image: File) {
    console.log(
      `Upload of File-Image with Size: ${
        image.size / 1024 / 1024
      } (MB), Date: ${image.lastModified}, Type: ${image.type}.`
    );
    const metadata = {
      customMetadata: {
        contentType: "image",
        cacheControl: "public, max-age=31536000",
      },
    };
    let filePath;
    switch (this.pictureType) {
      case "PROFILE": {
        filePath = `/profile`;
        break;
      }
      case "POST-IMAGE": {
        filePath = `/post-images`;
        break;
      }
      case "GROUP-IMAGE": {
        filePath = `/group-images`;
        break;
      }
      default: {
        console.error(`ERROR: Unknown Path. Image upload wont work.`);
        break;
      }
    }
    if (!filePath || !this.uid) {
      console.log(`Not enough parameters. Aborting.`);
      return;
    }
    filePath = `${filePath}/${this.uid}/${image.lastModified}`;
    const task = this.afStorage.upload(filePath, image, metadata);
    const fileRef = this.afStorage.ref(filePath);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef
            .getDownloadURL()
            .toPromise()
            .then((url) => {
              this.photoChange.emit(url);
              this.emitShowPhotoPickerChange();
            });
        })
      )
      .subscribe();
  }
}
