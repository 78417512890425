import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
	selector: 'flutaro-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnDestroy {
	isSearch: boolean = false;
	searchQuery: string = '';

	@Input() searchResultsList: any[];
	@Input() type: string;
	@Input() searchIdentifierAttribute: string;

	@Input() placeholder: string;
	@Input() userProfile;

	@Output() activateSearchQuery: EventEmitter<any> = new EventEmitter<any>();
	@Output() searchResultsListChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() resultChange: EventEmitter<any> = new EventEmitter<any>();

	constructor() {
	}

	ngOnDestroy() {
		this.isSearch = false;
	}

	activateSearch() {
		this.isSearch = true;
	}

	emitSearchSelection(resultEntry) {
		if (!this.searchIdentifierAttribute) {
			return;
		}
		this.searchQuery = resultEntry[this.searchIdentifierAttribute];
		this.resultChange.emit(resultEntry);
		this.cancelSearch();
	}

	searchUsers() {
		if (!this.searchQuery || this.searchQuery.length < 5) {
			this.searchResultsList = [];
			return;
		}
		this.activateSearchQuery.emit(this.searchQuery);
	}

	clearSearch() {
		this.searchResultsList = [];
		this.searchQuery = '';
	}

	cancelSearch() {
		this.isSearch = false;
		this.searchResultsList = [];
	}
}
