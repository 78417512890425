import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import User = firebase.User;
import UserCredential = firebase.auth.UserCredential;

@Injectable({ providedIn: "root" })
export class AppAuthService {
  $user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  $userIsEnterpriseUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $userIsSocialAppOnlyUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public afAuth: AngularFireAuth) {
    afAuth.user.subscribe(this.$user);
  }

  setUserAuthToEnterprise() {
    console.log(`ENTERPRISE App-User`);
    this.$userIsEnterpriseUser.next(true);
    this.$userIsSocialAppOnlyUser.next(false);
  }

  setUserAuthToSocialAppUserOnly() {
    console.log(`SOCIAL-APP-ONLY User`);
    this.$userIsEnterpriseUser.next(false);
    this.$userIsSocialAppOnlyUser.next(true);
  }

  async getIdToken(): Promise<string> {
    return this.$user.getValue().getIdToken();
  }

  signInWithEmail(credentials) {
    return this.afAuth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  async signUp(credentials): Promise<UserCredential> {
    return await this.afAuth.createUserWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  async signOutAndRedirectToHome() {
    await this.signOut();
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  private async signOut(): Promise<void> {
    this.$user.next(null);
    this.$userIsEnterpriseUser.next(false);
    return await this.afAuth.signOut();
  }
}
