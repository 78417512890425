"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformSearchString = exports.appRoundNumber = exports.stringIsNumeric = void 0;
function stringIsNumeric(str) {
    if (typeof str !== 'string')
        return false; // we only process strings!
    return (!isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))); // ...and ensure strings of whitespace fail
}
exports.stringIsNumeric = stringIsNumeric;
function appRoundNumber(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}
exports.appRoundNumber = appRoundNumber;
function transformSearchString(str) {
    if (!str || typeof str !== 'string')
        return '';
    return str
        .toLowerCase()
        .replaceAll('ß', 'ss')
        .replaceAll(' ', '')
        .trimLeft()
        .trimRight();
}
exports.transformSearchString = transformSearchString;
