import { Injectable } from '@angular/core';
import { mergeMap, retryWhen } from "rxjs/operators";
import { Observable, throwError, timer } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

export interface RetryParams {
	maxAttempts?: number;
	scalingDuration?: number;
	shouldRetry?: ({ status: number }) => boolean;
}

const defaultParams: RetryParams = {
	maxAttempts: 3,
	scalingDuration: 1000,
	shouldRetry: ({ status }) => (status >= 400 && !404) || status === 0
};

export const genericRetryStrategy = (params: RetryParams = {}) => (attempts: Observable<any>) => attempts.pipe(
	mergeMap((error, i) => {
		const retryAttempt = i + 1;
		// if maximum number of retries have been met
		// or response is a status code we don't wish to retry, throw error
		if (retryAttempt > defaultParams.maxAttempts || !defaultParams.shouldRetry(error)) {
			return throwError(error);
		}
		console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * defaultParams.scalingDuration}ms`);
		// retry after 1s, 2s, etc...
		return timer(retryAttempt * defaultParams.scalingDuration);
	})
);


@Injectable()
export class AppServerErrorsInterceptor implements HttpInterceptor {
	constructor() {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// If the call fails, retry until 5 times before throwing an error
		return next.handle(request)
			.pipe(retryWhen(genericRetryStrategy()));
	}
}
