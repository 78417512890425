"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUserProfileIntoWebProfile = exports.isAuthorizedAppUser = exports.isAuthorizedReadonlyAphroditeUser = exports.isCompanyAdminWebAppUser = exports.isWebAppAdminUser = exports.isAuthorizedAphroditeUser = exports.hasAuthorizedWebAppAccess = void 0;
const AuthClasses_1 = require("../../model/AuthClasses");
function hasAuthorizedWebAppAccess(userProfile) {
    var _a;
    return !!((_a = userProfile.roles) === null || _a === void 0 ? void 0 : _a.length) && !!userProfile.company;
}
exports.hasAuthorizedWebAppAccess = hasAuthorizedWebAppAccess;
function isAuthorizedAphroditeUser(userProfile) {
    return userProfile.roles.includes(AuthClasses_1.ROLES.WEBAPP_USER) && !!userProfile.company;
}
exports.isAuthorizedAphroditeUser = isAuthorizedAphroditeUser;
function isWebAppAdminUser(userProfile) {
    return isAuthorizedAphroditeUser(userProfile) && userProfile.roles.indexOf(AuthClasses_1.ROLES.WEBAPP_USER_ADMIN) !== -1;
}
exports.isWebAppAdminUser = isWebAppAdminUser;
function isCompanyAdminWebAppUser(userProfile) {
    return isAuthorizedAphroditeUser(userProfile) && userProfile.roles.indexOf(AuthClasses_1.ROLES.COMPANY_ADMIN) !== -1;
}
exports.isCompanyAdminWebAppUser = isCompanyAdminWebAppUser;
function isAuthorizedReadonlyAphroditeUser(userProfile) {
    return userProfile.roles.indexOf(AuthClasses_1.ROLES.READONLY_USER) !== -1 && !!userProfile.company;
}
exports.isAuthorizedReadonlyAphroditeUser = isAuthorizedReadonlyAphroditeUser;
function isAuthorizedAppUser(userProfile) {
    var _a;
    return ((_a = userProfile.roles) === null || _a === void 0 ? void 0 : _a.indexOf(AuthClasses_1.ROLES.APP_USER)) !== -1 && !!userProfile.company;
}
exports.isAuthorizedAppUser = isAuthorizedAppUser;
function parseUserProfileIntoWebProfile(userProfile) {
    const webUserProfile = new AuthClasses_1.FlutaroUserProfile(userProfile.uid, userProfile.email, userProfile.displayName);
    Object.assign(webUserProfile, userProfile);
    if (!webUserProfile.chatChannels)
        webUserProfile.chatChannels = [];
    return webUserProfile;
}
exports.parseUserProfileIntoWebProfile = parseUserProfileIntoWebProfile;
