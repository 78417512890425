"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppLanguages = exports.JobAppStatusHistory = exports.JobAppSettings = void 0;
class JobAppSettings {
    constructor() {
        this.inSyncWithApp = false;
        this.history = [];
    }
}
exports.JobAppSettings = JobAppSettings;
class JobAppStatusHistory {
    constructor(status, lastModifiedBy) {
        this.date = new Date();
        this.lastModifiedBy = '';
        if (status && lastModifiedBy)
            Object.assign(this, { status, lastModifiedBy });
    }
}
exports.JobAppStatusHistory = JobAppStatusHistory;
var AppLanguages;
(function (AppLanguages) {
    AppLanguages["GERMAN"] = "ger";
    AppLanguages["ENGLISH"] = "en";
    AppLanguages["POLISH"] = "pol";
    AppLanguages["RUSSIAN"] = "rus";
    AppLanguages["ROMANIAN"] = "rom";
})(AppLanguages = exports.AppLanguages || (exports.AppLanguages = {}));
