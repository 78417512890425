import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { JobWrapper } from "@flutaro/package/lib/model/Job";

@Component({
  selector: "app-job",
  templateUrl: "./job.page.html",
  styleUrls: ["./job.page.scss", "../../jobs/job.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobPage implements OnInit {
  jobWrapper: JobWrapper;

  constructor(
    public platform: Platform,
    public params: NavParams,
    public viewCtrl: ModalController
  ) {
    this.jobWrapper = this.params.get("jobWrapper");
  }

  ngOnInit() {}

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
