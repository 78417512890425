"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlutaroLatLng = exports.FLUT_Position = void 0;
class FLUT_Position {
}
exports.FLUT_Position = FLUT_Position;
class FlutaroLatLng {
    constructor(lat, lng) {
        Object.assign(this, { lat, lng });
    }
}
exports.FlutaroLatLng = FlutaroLatLng;
