import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { PostPage } from './post.page';
import { PostAddModal } from "./post/add/post.add.modal";
import { PostFeedComponent } from "./feed/post-feed.component";
import { PostEventComponent } from "./event/post-event.component";
import { WebPagePreviewComponent } from "./webpage-preview/web-page-preview.component";
import { PostCommentComponent } from "../comment/post-comment.component";
import { LocationPage } from "../location/location.page";
import { ShareModule } from "../../share.module";
import { LocationModule } from "../location/location.module";
import { ComponentsModule } from "../../components/components.module";
import { FeedEntryHeaderComponent } from "../feed/feed-entry-header/feed-entry-header.component";
import { AvatarModule } from "../avatar/avatar.module";
import { TagFriendsComponent } from './tag-friends/tag-friends.component';
import { TaggedFriendsPipe } from "./PostPipes";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ShareModule,
		LocationModule,
		ComponentsModule,
		AvatarModule,
		TranslateModule
	],
	exports: [PostPage, PostAddModal, PostFeedComponent, PostEventComponent, WebPagePreviewComponent, PostCommentComponent, LocationPage,
		FeedEntryHeaderComponent, TagFriendsComponent],
	declarations: [PostPage, PostAddModal, PostFeedComponent, PostEventComponent, WebPagePreviewComponent, PostCommentComponent, LocationPage,
		FeedEntryHeaderComponent, TagFriendsComponent, TaggedFriendsPipe]
})
export class PostPageModule {
}
