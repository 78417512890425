import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HomePage } from "./pages/home/home.page";
import { ContactPage } from "./pages/contact/contact.page";
import { ChatPage } from "./pages/chat/chat.page";
import { EditProfilePage } from "./edit-profile/edit-profile.page";
import { JobComponent } from "./jobs/job.component";
import { LocationPage } from "./social/location/location.page";
import { FeedPage } from "./social/feed/feed.page";
import { LoginPage } from "./pages/login/login.page";
import { LoginErrorPage } from "./auth/auth-error.page";
import { ActivitiesListComponent } from "./social/activities/activities-list/activities-list.component";
import { AboutPage } from "./pages/about/about.component";
import { GroupsPageComponent } from "./social/groups/groups-page/groups-page.component";
import { EventsPageComponent } from "./social/events/events-page/events-page.component";

const routes: Routes = [
	{ path: 'login', component: LoginPage },
	{ path: 'login-error', component: LoginErrorPage },
	{
		path: '',
		component: HomePage
	},
	{
		path: 'home',
		component: HomePage
	},
	{
		path: 'activities',
		component: ActivitiesListComponent
	},
	{
		path: 'page-job',
		component: JobComponent
	},
	{
		path: 'contact',
		children: [
			{
				path: '', component: ContactPage
			},
			{
				path: 'page-chat',
				component: ChatPage
			}
		]
	},
	{ path: 'feed', component: FeedPage },
	{
		path: 'ratings',
		component: LocationPage
	},
	{ path: 'profile', component: EditProfilePage },
	{ path: 'about', component: AboutPage },
	{ path: 'logout', component: LoginPage },
	{ path: 'groups', component: GroupsPageComponent },
	{ path: 'events', component: EventsPageComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
	providers: [
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
	]
})
export class AppRoutingModule {
}
