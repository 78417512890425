"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanningEntitySettings = void 0;
/**
 * Settings for a plannable entity.
 * Entity can be driver or vehicle, depending on vehiclePlanningMode Settings.
 */
class PlanningEntitySettings {
    constructor() {
        this.statuses = [];
    }
}
exports.PlanningEntitySettings = PlanningEntitySettings;
