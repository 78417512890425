import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineService } from "./timeline.service";


@NgModule({
	providers: [TimelineService],
	imports: [
		CommonModule
	]
})
export class TimelineModule {
}
