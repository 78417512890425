"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDateStartOfISOWeekBerlinTimezone = exports.onlyMondayDateFilter = exports.isValidDate = exports.isMinimumDayBefore = exports.isSameDayOrAfter = exports.isAfterOrSame = exports.isAfterDay = exports.isValidDateInterval = exports.isDateInInterval = exports.isDateAfterOrInInterval = exports.isBeforeOrSame = exports.createTimeIntervalFromDestinationsEarliestToLatestDates = exports.DateInterval = void 0;
const date_fns_1 = require("date-fns");
class DateInterval {
    constructor(startDate, endDate) {
        this.start = new Date();
        this.end = new Date();
        if (startDate)
            this.start = startDate;
        if (endDate)
            this.end = endDate;
    }
}
exports.DateInterval = DateInterval;
function createTimeIntervalFromDestinationsEarliestToLatestDates(destination) {
    return new DateInterval(destination.earliestDate, destination.latestDate);
}
exports.createTimeIntervalFromDestinationsEarliestToLatestDates = createTimeIntervalFromDestinationsEarliestToLatestDates;
function isBeforeOrSame(date, compareDate) {
    return date_fns_1.isBefore(date, compareDate) || date_fns_1.differenceInMilliseconds(date, compareDate) === 0;
}
exports.isBeforeOrSame = isBeforeOrSame;
function isDateAfterOrInInterval(date, intervalStart, intervalEnd) {
    return intervalEnd ? isDateInInterval(date, intervalStart, intervalEnd) : true;
}
exports.isDateAfterOrInInterval = isDateAfterOrInInterval;
function isDateInInterval(date, intervalStart, intervalEnd) {
    if (!isValidDate(date)) {
        console.error(`isDateInInterval - date ${date} is not a valid date`);
        throw new Error(`Date ${date} is not a valid date`);
    }
    if (!isValidDateInterval(intervalStart, intervalEnd)) {
        console.error(`isDateInInterval - dateInterval ${intervalStart} - ${intervalEnd} is not a valid date-interval`);
        throw new Error(`dateInterval ${intervalStart} - ${intervalEnd} is not a valid date-interval`);
    }
    if (date.getTime() === intervalStart.getTime() || date.getTime() === intervalEnd.getTime())
        return true;
    const interval = new DateInterval(intervalStart, intervalEnd);
    return date_fns_1.isWithinInterval(date, interval);
}
exports.isDateInInterval = isDateInInterval;
function isValidDateInterval(intervalStart, intervalEnd) {
    return isValidDate(intervalStart) && isValidDate(intervalEnd) && isAfterOrSame(intervalEnd, intervalStart);
}
exports.isValidDateInterval = isValidDateInterval;
function isAfterDay(date, compareDate) {
    return date_fns_1.isAfter(date, compareDate) && !date_fns_1.isSameDay(date, compareDate);
}
exports.isAfterDay = isAfterDay;
function isAfterOrSame(date, compareDate) {
    return date_fns_1.isAfter(date, compareDate) || date_fns_1.differenceInMilliseconds(date, compareDate) === 0;
}
exports.isAfterOrSame = isAfterOrSame;
function isSameDayOrAfter(date, compareDate) {
    return date_fns_1.isAfter(date, compareDate) || date_fns_1.isSameDay(date, compareDate);
}
exports.isSameDayOrAfter = isSameDayOrAfter;
function isMinimumDayBefore(date, compareDate) {
    return date_fns_1.isBefore(date, compareDate) && !date_fns_1.isSameDay(date, compareDate);
}
exports.isMinimumDayBefore = isMinimumDayBefore;
function isValidDate(date) {
    if (!date || !(date instanceof Date))
        return false;
    const dateIsNaN = isNaN(date);
    const dateTime = date.getTime();
    return !dateIsNaN && !!dateTime;
}
exports.isValidDate = isValidDate;
function onlyMondayDateFilter(date) {
    return date_fns_1.isMonday(date);
}
exports.onlyMondayDateFilter = onlyMondayDateFilter;
function isDateStartOfISOWeekBerlinTimezone(date) {
    return isValidDate(date) && date_fns_1.isMonday(date) && !date_fns_1.differenceInWeeks(new Date(), date);
}
exports.isDateStartOfISOWeekBerlinTimezone = isDateStartOfISOWeekBerlinTimezone;
