import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowerModule } from "./follower/follower.module";
import { ProfileModule } from "./profile/profile.module";
import { PostPageModule } from "./post/post.module";
import { FeedPageModule } from "./feed/feed.module";
import { TimelineModule } from "./timeline/timeline.module";
import { LikeModule } from "./like/like.module";
import { ActivitiesModule } from "./activities/activities.module";
import { CommentModule } from "./comment/comment.module";
import { EventsModule } from "./events/events.module";
import { GroupsModule } from "./groups/groups.module";


@NgModule({
	declarations: [],
	imports: [
		CommonModule, FollowerModule, ProfileModule, PostPageModule, FeedPageModule, TimelineModule,
		LikeModule, ActivitiesModule, CommentModule, EventsModule, GroupsModule
	],
	exports: [FollowerModule, ProfileModule, PostPageModule, FeedPageModule, TimelineModule,
		LikeModule, ActivitiesModule, CommentModule, EventsModule, GroupsModule]
})
export class SocialModule {
}
