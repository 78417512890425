<ion-toolbar class="jobPageToolbar" color="color596163">
  <ion-buttons slot="start">
    <ion-back-button (click)="dismiss()" defaultHref="page-job"></ion-back-button>
  </ion-buttons>
  <ion-title class="ion-text-center">
    {{jobWrapper.toString()}}
  </ion-title>
</ion-toolbar>

<ion-content>
  <ion-list class="jobModalList">
    <ion-row class="jobModalHeaderRow">
      <ion-col col-12>
        <p class="jobModalHeaderText" translate>
          <i class="material-icons jobModalMainIcon">assignment</i>JOB_PAGE_GENERALLY
        </p>
      </ion-col>
    </ion-row>

    <job-status [job]="jobWrapper"></job-status>

    <ion-item class="modalListItem">
      <span>Shipment-ID</span>
      <i class="material-icons" item-start>fingerprint</i>
      <ion-note item-end>{{jobWrapper.attributes?.shipmentId}}
      </ion-note>
    </ion-item>

    <ion-item class="modalListItem">
      <span translate>DRIVING_TIME</span>
      <i class="material-icons" item-start>access_time</i>
      <ion-note item-end>{{jobWrapper.totalTimeInMinutes | minutesToDrivingTime}}
      </ion-note>
    </ion-item>
    <ion-item class="modalListItem">
      <span translate>DISTANCE</span>
      <i class="material-icons" item-start>settings_ethernet</i>
      <ion-note item-end>{{jobWrapper.totalDistance | distanceAsKm}}
      </ion-note>
    </ion-item>

    <ion-item class="modalListItem">
      <span translate>CLIENT</span>
      <i class="material-icons" item-start>business</i>
      <ion-note item-end>{{jobWrapper.job.clientsName}}</ion-note>
    </ion-item>
    <ion-item class="modalListItem" lines="none">
      <span translate>JOB_PAGE_REQUIREMENTS</span>
      <i class="material-icons" item-start>assignment_turned_in</i>
      <ion-note item-end>
      </ion-note>
    </ion-item>
  </ion-list>

  <ion-list *ngFor="let dest of jobWrapper.destinations"
            [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListPickup' : 'jobModalListDelivery'">
    <ion-row class="jobModalHeaderRowPickUpOrDelivery">
      <ion-col col-12>
        <p class="jobModalHeaderText">
          <span [ngClass]="dest.locationType === 'PICKUP' ? 'pickupImage' : 'deliveryImage' " class="material-icons">
          </span>
          {{(dest.locationType === 'PICKUP' ? 'PICKUP' : 'DELIVERY') | translate}}
        </p>
      </ion-col>
    </ion-row>
    <ion-item
      [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
      <span translate>REFERENCES</span>
      <i class="material-icons" item-start>link</i>
      <ion-label>
        <h2 *ngIf="dest.ingoingReferences?.length" class="textRight"
            translate>{{'JOB_CARGO_REFERENCES_INCOMING' | translate}}: {{dest.ingoingReferences}}</h2>
        <h2 *ngIf="dest.outgoingReferences?.length" class="textRight"
            translate>{{'JOB_CARGO_REFERENCES_OUTGOING' | translate}}: {{dest.outgoingReferences}}</h2>
      </ion-label>
    </ion-item>
    <ion-item
      [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
      <div>
        <div><i class="material-icons jobModalAddressIconjobModalDestIcon" item-start>home</i>
          <div class="destDiv">
            <div class="jobModalH3 jobModalH3Cargo">{{dest.position.toString()}}</div>
          </div>
        </div>
        <div *ngIf="dest.name || dest.description">
          <div class="destDiv">
            <div class="jobModalH3 jobModalH3Cargo">{{dest.name}}
              - {{dest.description}}
            </div>
          </div>
        </div>
      </div>
    </ion-item>

    <ion-item
      [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
      <span translate>JOB_PAGE_PLANNEDDATE</span>
      <i class="material-icons" item-start>history_toggle_off</i>
      <ion-note item-end>{{dest.plannedDate | appFormatDate:'datetime' }}
      </ion-note>
    </ion-item>

    <ion-item
      [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
      <span translate>EARLIESTDATE</span>
      <i class="material-icons" item-start>access_time</i>
      <ion-note item-end>{{dest.earliestDate | appFormatDate:'datetime'}}
      </ion-note>
    </ion-item>
    <ion-item
      [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
      <span translate>LATESTDATE</span>
      <i class="material-icons" item-start>alarm</i>
      <ion-note item-end>{{dest.latestDate | appFormatDate:'datetime' }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="dest.position.regulatingTimeInMinutes"
              [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
      <span translate>REGULATINGTIMEINMINUTES</span>
      <i class="material-icons" item-start>timer</i>
      <ion-note item-end>{{dest.position.regulatingTimeInMinutes}} min</ion-note>
    </ion-item>
    <ion-item *ngIf="dest.position.additionalInfo"
              [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'"
              class="jobNotesItem"
              lines="none">
      <i class="material-icons" item-start>comment</i> <span
      *ngIf="!dest.position.additionalInfo" translate>NOTES</span>
      <ion-note class="jobNote" slot="end">{{dest.position.additionalInfo}}</ion-note>
    </ion-item>
    <app-cargo [cargos]="dest.cargo"></app-cargo>
  </ion-list>
</ion-content>
