import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobComponent } from './job.component';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { JobItemComponent } from './job-item/job-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from "../share.module";

@NgModule({
  declarations: [JobComponent, JobItemComponent],
  exports: [JobComponent, JobItemComponent],
  imports: [CommonModule, FormsModule, IonicModule, PipesModule, TranslateModule, ShareModule]
})
export class JobComponentModule {
}
