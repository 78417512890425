"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VEHICLE_SPEED_PROFILE = exports.VEHICLE_WEIGHT_TYPES = exports.VEHICLE_EMISSION_TYPES = exports.VEHICLE_TRAILER_TYPES = exports.VEHICLE_TRUCK_TYPES = exports.VEHICLE_CAR_TYPES = exports.VEHICLE_BASIC_TYPES = exports.VEHICLE_TYPE_TRANSLATED = exports.VEHICLE_TYPE = void 0;
// TODO: rename me to carType to match with vehicle.carType and distinguish between Lastzug, Sattelzugmaschine, Mega-Sattelzugmaschine
var VEHICLE_TYPE;
(function (VEHICLE_TYPE) {
    VEHICLE_TYPE["CAR"] = "Car";
    VEHICLE_TYPE["MINIBUS"] = "Minibus";
    VEHICLE_TYPE["BUS"] = "Bus";
    VEHICLE_TYPE["TRANSPORTER"] = "Transporter";
    VEHICLE_TYPE["TRUCK"] = "Truck";
    VEHICLE_TYPE["MEGATRUCK"] = "MegaTruck";
    VEHICLE_TYPE["JUMBO"] = "Jumbo";
    VEHICLE_TYPE["LORRY"] = "Lorry";
    VEHICLE_TYPE["ROAD_TRAIN"] = "RoadTrain";
    VEHICLE_TYPE["CURTAINSIDER"] = "Curtainsider";
    VEHICLE_TYPE["TRAILER"] = "Trailer";
    VEHICLE_TYPE["BOX_TRAILER"] = "BoxTrailer";
    VEHICLE_TYPE["MEGA_TRAILER"] = "MegaTrailer";
    VEHICLE_TYPE["REFRIGERATED_TRAILER"] = "RefrigeratedTrailer";
    VEHICLE_TYPE["TILT_TRAILER"] = "TiltTrailer";
    VEHICLE_TYPE["COLI"] = "Coli";
})(VEHICLE_TYPE = exports.VEHICLE_TYPE || (exports.VEHICLE_TYPE = {}));
// TODO: delete me by using translation files instead for multi-language-support
var VEHICLE_TYPE_TRANSLATED;
(function (VEHICLE_TYPE_TRANSLATED) {
    VEHICLE_TYPE_TRANSLATED["CAR"] = "Auto";
    VEHICLE_TYPE_TRANSLATED["MINIBUS"] = "Minibus";
    VEHICLE_TYPE_TRANSLATED["BUS"] = "Bus";
    VEHICLE_TYPE_TRANSLATED["TRANSPORTER"] = "Kleintransporter";
    VEHICLE_TYPE_TRANSLATED["JUMBO"] = "Jumbo";
    VEHICLE_TYPE_TRANSLATED["LORRY"] = "LKW";
    VEHICLE_TYPE_TRANSLATED["ROAD_TRAIN"] = "Lastzug";
    VEHICLE_TYPE_TRANSLATED["CURTAINSIDER"] = "Tautliner";
    VEHICLE_TYPE_TRANSLATED["TRAILER"] = "Trailer";
    VEHICLE_TYPE_TRANSLATED["MEGA_TRAILER"] = "Mega-Trailer";
    VEHICLE_TYPE_TRANSLATED["TRUCK"] = "Sattelzugmaschine";
    VEHICLE_TYPE_TRANSLATED["MEGATRUCK"] = "Mega-Sattelzugmaschine";
    VEHICLE_TYPE_TRANSLATED["TILT_TRAILER"] = "Planenauflieger";
    VEHICLE_TYPE_TRANSLATED["BOX_TRAILER"] = "Box-Trailer";
    VEHICLE_TYPE_TRANSLATED["REFRIGERATED_TRAILER"] = "Refrigerated-Trailer";
    VEHICLE_TYPE_TRANSLATED["COLI"] = "Coli";
})(VEHICLE_TYPE_TRANSLATED = exports.VEHICLE_TYPE_TRANSLATED || (exports.VEHICLE_TYPE_TRANSLATED = {}));
exports.VEHICLE_BASIC_TYPES = [VEHICLE_TYPE.CAR, VEHICLE_TYPE.TRUCK, VEHICLE_TYPE.TRAILER];
exports.VEHICLE_CAR_TYPES = [VEHICLE_TYPE.CAR, VEHICLE_TYPE.MINIBUS, VEHICLE_TYPE.BUS, VEHICLE_TYPE.TRANSPORTER];
exports.VEHICLE_TRUCK_TYPES = [VEHICLE_TYPE.TRUCK, VEHICLE_TYPE.MEGATRUCK, VEHICLE_TYPE.LORRY];
exports.VEHICLE_TRAILER_TYPES = [VEHICLE_TYPE.TILT_TRAILER, VEHICLE_TYPE.BOX_TRAILER, VEHICLE_TYPE.MEGA_TRAILER, VEHICLE_TYPE.CURTAINSIDER, VEHICLE_TYPE.COLI, VEHICLE_TYPE.JUMBO, VEHICLE_TYPE.REFRIGERATED_TRAILER]; // check here for additional capacity information: https://www.condor.eu.com/news/trailer-types/
var VEHICLE_EMISSION_TYPES;
(function (VEHICLE_EMISSION_TYPES) {
    VEHICLE_EMISSION_TYPES["EURO_I"] = "1";
    VEHICLE_EMISSION_TYPES["EURO_II"] = "2";
    VEHICLE_EMISSION_TYPES["EURO_III"] = "3";
    VEHICLE_EMISSION_TYPES["EURO_IV"] = "4";
    VEHICLE_EMISSION_TYPES["EURO_V"] = "5";
    VEHICLE_EMISSION_TYPES["EURO_VI"] = "6";
    VEHICLE_EMISSION_TYPES["EURO_EEV"] = "7";
    VEHICLE_EMISSION_TYPES["Electrical"] = "8";
})(VEHICLE_EMISSION_TYPES = exports.VEHICLE_EMISSION_TYPES || (exports.VEHICLE_EMISSION_TYPES = {}));
var VEHICLE_WEIGHT_TYPES;
(function (VEHICLE_WEIGHT_TYPES) {
    VEHICLE_WEIGHT_TYPES["7,5T"] = "7500kg";
    VEHICLE_WEIGHT_TYPES["18T"] = "18000kg";
    VEHICLE_WEIGHT_TYPES["25T"] = "25000kg";
    VEHICLE_WEIGHT_TYPES["26T"] = "26000kg";
    VEHICLE_WEIGHT_TYPES["36T"] = "36000kg";
    VEHICLE_WEIGHT_TYPES["40T"] = "40000kg";
    VEHICLE_WEIGHT_TYPES["44T"] = "44000kg";
})(VEHICLE_WEIGHT_TYPES = exports.VEHICLE_WEIGHT_TYPES || (exports.VEHICLE_WEIGHT_TYPES = {}));
exports.VEHICLE_SPEED_PROFILE = {
    THIRTY: 'Thirty',
    EIGHTY: 'Eighty',
    NINETY: 'Ninety',
    ONEHUNDRED: 'OneHundred',
    ONEHUNDREDTEN: 'OneHundredTen',
    ONEHUNDREDTWENTY: 'OneHundredTwenty',
    ONEHUNDREDTHIRTY: 'OneHundredThirty',
};
