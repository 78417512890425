import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { EmojiPickerModule } from "../../components/emoji-picker/emoji-picker.module";
import { IonicModule } from "@ionic/angular";
import { PipesModule } from "../../pipes/pipes.module";
import { ChatPage } from "./chat.page";
import { ContactPageModule } from "../contact/contact.module";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { TranslateModule } from "@ngx-translate/core";
import { ShareModule } from "../../share.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EmojiPickerModule,
    PipesModule,
    ContactPageModule,
    TranslateModule,
    ShareModule,
  ],
  declarations: [ChatPage, LoadingSpinnerComponent],
  exports: [ChatPage, LoadingSpinnerComponent],
})
export class ChatPageModule {}
